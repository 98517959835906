import {
  UPDATE_ADULT_KNEE_DATA,
  UPDATE_ADULT_KNEE_PAIN_DATA,
  UPDATE_ADULT_KNEE_PAIN_FIELD_DATA,
  UPDATE_ADULT_KNEE_SYMPTOMS_DATA_SINGLE,
  UPDATE_ADULT_KNEE_SYMPTOMS_DATA,
  UPDATE_ADULT_KNEE_FUNCTION_FIELD_DATA,
  UPDATE_ADULT_KNEE_FUNCTION_DATA,
  UPDATE_ADULT_KNEE_QOL_FIELD_DATA,
  UPDATE_ADULT_KNEE_QOL_DATA,
  UPDATE_ADULT_KNEE_SATISFACTION_FIELD_DATA,
  UPDATE_ADULT_KNEE_SATISFACTION_DATA,
  UPDATE_ADULT_KNEE_TOTAL_SCORES,
  UPDATE_ADULT_KNEE_NORWICHINSTA_FIELD_DATA,
  UPDATE_ADULT_KNEE_NORWICHINSTA_DATA,
  UPDATE_ADULT_KNEE_FEEDBACK,
  UPDATE_FOOT_FEEDBACK_FIELD_DATA,
} from "../actions/actionTypes";

const initialState = {
  painScore: [
    {
      describePainR: "4",
      describePainL: "4",
      painTodayRatingR: "0",
      painTodayRatingL: "0",
      bestStatmentForPainR: "5",
      bestStatmentForPainL: "5",
      howSevereR: "0",
      howSevereL: "0",
      kneeInterferedR: "0",
      kneeInterferedL: "0",
      twistingR: "0",
      twistingL: "0",
      straighteningR: "0",
      straighteningL: "0",
      bendingR: "0",
      bendingL: "0",
      walkingSurfaceR: "0",
      walkingSurfaceL: "0",
      howLongWalkR: "4",
      howLongWalkL: "4",
      useStepsR: "0",
      useStepsL: "0",
      whileBedR: "0",
      whileBedL: "0",
      painAtNightR: "0",
      painAtNightL: "0",
      sittingR: "0",
      sittingL: "0",
      standingR: "0",
      standingL: "0",
      standingFromChairR: "0",
      standingFromChairL: "0",
    },
  ],
  symptomsScore: [
    {
      swellingR: "0",
      swellingL: "0",
      grindingR: "0",
      grindingL: "0",
      catchingR: "0",
      catchingL: "0",
      limpingR: "0",
      limpingL: "0",
      significantSwellingR: "4",
      significantSwellingL: "4",
      awakeningR: "0",
      awakeningL: "0",
      restingLaterR: "0",
      restingLaterL: "0",
      instabilityR: "5",
      instabilityL: "5",
      supportR: "0",
      supportL: "0",
      kneecapR: "0",
      kneecapL: "0",
      swollenR: "0",
      swollenL: "0"
    },
  ],
  functionScore: [
    {
      goUpStairsR: "0",
      goUpStairsL: "0",
      goDownStairsR: "0",
      goDownStairsL: "0",
      kneelFrontR: "0",
      kneelFrontL: "0",
      straighteningR: "0",
      straighteningL: "0",
      bendingKneeR: "0",
      bendingKneeL: "0",
      squatR: "0",
      squatL: "0",
      sitKneeBentR: "0",
      sitKneeBentL: "0",
      riseFromChairR: "0",
      riseFromChairL: "0",
      riseFromBedR: "0",
      riseFromBedL: "0",
      lyingInBedR: "0",
      lyingInBedL: "0",
      bendingToFloorR: "0",
      bendingToFloorL: "0",
      puttingOnSocksR: "0",
      puttingOnSocksL: "0",
      takingOffSocksR: "0",
      takingOffSocksL: "0",
      standingR: "0",
      standingL: "0",
      walkingR: "0",
      walkingL: "0",
      runStraightAheadR: "0",
      runStraightAheadL: "0",
      jumpAndLandR: "0",
      jumpAndLandL: "0",
      stopAndStartR: "0",
      stopAndStartL: "0",
      twistingR: "0",
      twistingL: "0",
      publicTransportR: "0",
      publicTransportL: "0",
      gettingBathR: "0",
      gettingBathL: "0",
      gettingToiletR: "0",
      gettingToiletL: "0",
      washingR: "0",
      washingL: "0",
      shoppingR: "0",
      shoppingL: "0",
      heavyDutiesR: "0",
      heavyDutiesL: "0",
      lightDutiesR: "0",
      lightDutiesL: "0",
      rateKneeFunctionR: "10",
      rateKneeFunctionL: "10",
      activityLevelR: "4",
      activityLevelL: "4",
      highestActivity: "",
      highestActivityL: "",
      patellarInstability: "0",
      patellarInstabilityL: "0",
      sports: "",
      currsports: "",

    },
  ],
  qolScore: [
    {
      howOftenR: "0",
      howOftenL: "0",
      lifestyleR: "0",
      lifestyleL: "0",
      confidenceR: "0",
      confidenceL: "0",
      difficultyR: "0",
      difficultyL: "0",
      anxious: "0",
      howGood: "100",
      howGoodL: "100"
    },
  ],
  satisfactionScore: [
    {
      overallR: "",
      overallL: "",
      improvingPainR: "",
      improvingPainL: "",
      improvingHomeR: "",
      improvingHomeL: "",
      improvingActivitiesR: "",
      improvingActivitiesL: "",
      applicable: ""
    },
  ],
  norwichInstability: [
    {
      twistingR: "0",
      twistingL: "0",
      changeDirectionR: "0",
      changeDirectionL: "0",
      runningStraightlineR: "0",
      runningStraightlineL: "0",
      walkingSlipperyR: "0",
      walkingSlipperyL: "0",
      runningSidewaysR: "0",
      runningSidewaysL: "0",
      hoppingR: "0",
      hoppingL: "0",
      jumpingR: "0",
      jumpingL: "0",
      runningOnEvenSurfaceR: "0",
      runningOnEvenSurfaceL: "0",
      goingDownStairsR: "0",
      goingDownStairsL: "0",
      squattingR: "0",
      squattingL: "0",
      kneelingR: "0",
      kneelingL: "0",
      walkingUnevenSurfacesR: "0",
      walkingUnevenSurfacesL: "0",
      climbingStairsR: "0",
      climbingStairsL: "0",
      steppingOverHighR: "0",
      steppingOverHighL: "0",
      crossingWhenSittingR: "0",
      crossingWhenSittingL: "0",
      walkingEvenSurfaceR: "0",
      walkingEvenSurfaceL: "0",
      gettingIntoCarR: "0",
      gettingIntoCarL: "0",
      supermarketAisleR: "0",
      supermarketAisleL: "0",
      turningYourShoulderR: "0",
      turningYourShoulderL: "0",
      twcheck: false,
      directioncheck: false,
      straightcheck: false,
      slipperycheck: false,
      sidewayscheck: false,
      Hoppingcheck: false,
      Jumpingcheck: false,
      scaleLabelcheck: false,
      downcheck: false,
      Squattingcheck: false,
      Kneelingcheck: false,
      Surfacescheck: false,
      Climbingcheck: false,
      Steppingcheck: false,
      Crossingcheck: false,
      walkingEvencheck: false,
      IntoCarcheck: false,
      heavycheck: false,
      turningcheck: false,
    },
  ],

  TotalScores: [
    {
      ikdcScoreR: 0,
      ikdcScoreL: 0,
      koosPainScoreR: 0,
      koosPainScoreL: 0,
      koosSymptomsScoreR: 0,
      koosSymptomsScoreL: 0,
      koosAdlScoreR: 0,
      koosAdlScoreL: 0,
      koosSportsScoreR: 0,
      koosSportsScoreL: 0,
      qolScoreR: 0,
      qolScoreL: 0,
      ckrsScoreR: 0,
      ckrsScoreL: 0,
      lysholmScoreR: 0,
      lysholmScoreL: 0,
      oksPainScoreR: 0,
      oksPainScoreL: 0,
      oksFuncScoreR: 0,
      oksFuncScoreL: 0,
      oksOverallR: 0,
      oksOverallL: 0,
      womacPainScoreR: 0,
      womacPainScoreL: 0,
      womacStiffScoreR: 0,
      womacStiffScoreL: 0,
      womacFuncScoreR: 0,
      womacFuncScoreL: 0,
      kssScoreR: 0,
      kssScoreL: 0,
      VasScoreR: 0,
      VasScoreL: 0,
      EQDISCOMFORTR: 0,
      EQDISCOMFORTL: 0,
      EQMOBILITYR: 0,
      EQMOBILITYL: 0,
      EQCARER: 0,
      EQCAREL: 0,
      EQACTIVITIESR: 0,
      EQACTIVITIESL: 0,
      EQANXIETYR: 0,
      EQ_VAS: 0,
      SAPS_OVERALLR: 0,
      SAPS_OVERALLL: 0,
      SAPS_PAINR: 0,
      SAPS_PAINL: 0,
      SAPS_WORKR: 0,
      SAPS_WORKL: 0,
      SAPS_RECREATIONR: 0,
      SAPS_RECREATIONL: 0,
      SANE_R: 0,
      SANE_L: 0,
      NorwichR : 0,
      NorwichL : 0,
      TignerRight : 0,
      TignerLeft : 0
    },
  ],

  doctorFeedback: [
    {
      gaitR: "0",
      gaitL: "0",
      anatomicR: null,
      anatomicL: null,
      anat: null,
      anatt: null,
      angleLr: null,
      angleLrr: null,
      langR: null,
      langL: null,
      fixeddR: null,
      fixeddL: null,
      LfixeddR: null,
      LfixedlL: null,
      lagsR: null,
      lagR: null,
      lagLR: null,
      lagLL: null,
      anteriorR: "",
      anteriorL: "",
      anterdropR: "",
      anterdropL: "",
      lachmanR: "",
      lachmanL: "",
      lachmandropR: "",
      lachmandropL: "",
      pivotR: "",
      pivotL: "",
      pivotdropR: "",
      pivotdropL: "",
      sagR: "",
      sagL: "",
      drawerR: "",
      drawerL: "",
      drawdropR: "",
      drawdropL: "",
      activeR: "",
      activeL: "",
      reverseR: "",
      reverseL: "",
      recurvatumR: "",
      recurvatumL: "",
      postR: "",
      postL: "",
      rotatR: "",
      rotatL: "",
      drawersR: "",
      drawersL: "",
      stressR: "",
      stressL: "",
      stressdropR: "",
      stressdropL: "",
      stressflR: "",
      stressflL: "",
      stressdropflR: "",
      stressdropflL: "",
      valgusR: "",
      valgusL: "",
      valgusdropR: "",
      valgusdropL: "",
      valgusflR: "",
      valgusflL: "",
      valgusdropflR: "",
      valgusdropflL: "",
      murraryR: "",
      murraryL: "",
      egeR: "",
      egeL: "",
      jointR: "",
      tendernessR: "",
      tendernessL: "",
      jointL: "",
      apleyR: "",
      apleyL: "",
      apprR: "",
      apprL: "",
      patellarR: "",
      patellarL: "",
      patellaR: "",
      patellaL: "",
      signR: "",
      signL: "",
      legR: "",
      legL: "",
      strengthR: null,
      strengthL: null,
      KTR: "",
      KTL: "",
      dialR: "",
      dialL: "",
      text1: "",
      text2: "",
      text3: "",
      textL1: "",
      textL2: "",
      textL3: "",
      text30R: "",
      text90R: "",
      text30L: "",
      text90L: "",
      gaitLText: "",
      gaitRText: "",
      radiovalueR: "",
      radiovalueL: "",
      radioAngleR: "",
      radioAngleL: "",
      DegreeFixedR: "",
      DegreeFixedL: "",
      ExtensionR: "",
      ExtensionL: "",
      lFlexionR: null,
      lFlexionL: null,
      rFlexionR: null,
      rFlexionL: null,
      lExtensionL: null,
      lExtensionR: null,
      rExtensionL: null,
      rExtensionR: null,
      murraryRdrop: "1",
      murraryLdrop: "1",
      egeRdrop: "1",
      egeLdrop: "1",
      apleyRdrop: "1",
      apleyLdrop: "1",
      apprRdrop: "1",
      apprLdrop: "1",
      ThessalyRdrop: "1",
      ThessalyLdrop: "1",
      ThessalyR: "",
      ThessalyL: "",
      GaitRadioR: "",
      GaitRadioL: "",
      DeformityRText: "",
      DeformityLText: "",
    },
  ],

  evlAdultKnee: "1",

};

const adultKneeScoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ADULT_KNEE_DATA:
      return { ...state, evlAdultKnee: action.data };

    case UPDATE_ADULT_KNEE_PAIN_FIELD_DATA:
      const painFieldVal = action.data.value;
      const painFieldKey = action.data.key;
      const painNewArr = state.painScore.map((object) => {
        return { ...object, [painFieldKey]: painFieldVal };
      });
      return { ...state, painScore: painNewArr };

    case UPDATE_ADULT_KNEE_PAIN_DATA:
      return { ...state, painScore: action.data };

    case UPDATE_ADULT_KNEE_SYMPTOMS_DATA_SINGLE:
      const fieldVal = action.data.value;
      const fieldKey = action.data.key;
      const newArr = state.symptomsScore.map((object) => {
        return { ...object, [fieldKey]: fieldVal };
      });
      return { ...state, symptomsScore: newArr };

    case UPDATE_ADULT_KNEE_SYMPTOMS_DATA:
      return { ...state, symptomsScore: action.data };

    case UPDATE_ADULT_KNEE_FUNCTION_FIELD_DATA:
      const fieldValF = action.data.value;
      const fieldKeyF = action.data.key;
      const newArrF = state.functionScore.map((object) => {
        return { ...object, [fieldKeyF]: fieldValF };
      });
      return { ...state, functionScore: newArrF };

    case UPDATE_ADULT_KNEE_FUNCTION_DATA:
      return { ...state, functionScore: action.data };

    case UPDATE_ADULT_KNEE_QOL_FIELD_DATA:
      const qolVal = action.data.value;
      const qolKey = action.data.key;
      const qolNewArr = state.qolScore.map((object) => {
        return { ...object, [qolKey]: qolVal };
      });
      return { ...state, qolScore: qolNewArr };

    case UPDATE_ADULT_KNEE_QOL_DATA:
      return { ...state, qolScore: action.data };

    case UPDATE_ADULT_KNEE_SATISFACTION_FIELD_DATA:
      const satVal = action.data.value;
      const satKey = action.data.key;
      const satNewArr = state.satisfactionScore.map((object) => {
        return { ...object, [satKey]: satVal };
      });
      return { ...state, satisfactionScore: satNewArr };

    case UPDATE_ADULT_KNEE_SATISFACTION_DATA:
      return { ...state, satisfactionScore: action.data };

    case UPDATE_ADULT_KNEE_NORWICHINSTA_FIELD_DATA:
      const satValInst = action.data.value;
      const satKeyInst = action.data.key;
      const satNewArrInst = state.norwichInstability.map((object) => {
        return { ...object, [satKeyInst]: satValInst };
      });
      return { ...state, norwichInstability: satNewArrInst };

    case UPDATE_ADULT_KNEE_NORWICHINSTA_DATA:
      return { ...state, norwichInstability: action.data };

    case UPDATE_ADULT_KNEE_TOTAL_SCORES:
      return { ...state, TotalScores: action.data };

    case UPDATE_FOOT_FEEDBACK_FIELD_DATA:
      const satf = action.data.value;
      const satK = action.data.key;
      const satNewarr = state.doctorFeedback.map((object) => {
        return { ...object, [satK]: satf };
      });
      return { ...state, doctorFeedback: satNewarr };
      case UPDATE_ADULT_KNEE_FEEDBACK:
        return { ...state, doctorFeedback: action.data };
    default:
      return state;
  }
};

export default adultKneeScoreReducer;
