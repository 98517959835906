import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import InputField from "../custom/inputfield/InputField";
import Dropdown from "../custom/dropdown/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import useAxios from "../../axiosinstance";
import Message from "../custom/toster/Message";
import CreatableSelect from "react-select/creatable";
import plusIcon from "../../images/plus.png";
import addOpStyles from "./../operative-notes/add-operative-notes/AddOperativeNotes.module.css";
import { convertToDays } from "../../utilities/convertToDays";
import { noteRefresh } from '../../redux/actions';

export default function PromesSchedule({ open, handlereff }) {
  const axiosInstance = useAxios();
  const dispatch = useDispatch()
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const [showModal, setshowModal] = useState(false);
  const [repeatInterval, setRepeatInterval] = useState("");
  const [NonsurgicalInput, setNonsurgicalInput] = useState();
  const [messageType, setMessageType] = useState();
  const [message, setMessage] = useState("");

  // New all changes
  const [SelectOrg, setSelectOrg] = useState([]);
  const [Selectspecialty, setSelectspecialty] = useState();
  const [Selecttreatment, setSelecttreatment] = useState("");
  const [SelectAlert, setSelectAlert] = useState([{ value: 0, label: "No alert" }]);
  const [SelectAlertOver, setSelectAlertOver] = useState([{ value: 1, label: "1 day after" }]);
  const [SelectReferdate, setSelectReferdate] = useState();
  const [orgdata, setOrgdata] = useState([]);
  const [typeData, setTypeData] = useState("");
  const [primary, setPrimary] = useState("");
  const [err, setErr] = useState(false);
  const [setSheduleerr, setScheduleErr] = useState(false);
  const [isApiPending, setIsApiPending] = useState(false);

  const handleprimary = (e) => {
    setPrimary(e);
  };
  const handleNonsurgicalInput = (e) => {
    setNonsurgicalInput(e);
  };
  const HandleSelectReferdate = (e) => {
    setSelectReferdate(e);
  };

  const [alertOptions, setalertOptions] = useState([
    { value: 0, label: "No alert" },
    { value: 1, label: "Same day" },
    { value: 2, label: "1 day before" },
    { value: 3, label: "2 days before" },
    { value: 4, label: "1 week before" },
  ]);

  const [alertOverdueOptions, setalertOverdueOptions] = useState([
    { value: 0, label: "No alert" },
    { value: 1, label: "1 day after" },
    { value: 2, label: "2 days after" },
    { value: 3, label: "1 week after" },
  ]);

  const HandleSelectAlert = (selectedOptions) => {
    setSelectAlert(selectedOptions);
  };
  const isNoAlertSelected = SelectAlert?.some((option) => option.value === 0);
  useEffect(() => {
    if (isNoAlertSelected) {
      setalertOptions([{ value: 0, label: "No alert" }]);
      setSelectAlert([{ value: 0, label: "No alert" }]);
    } else {
      setalertOptions([
        { value: 0, label: "No alert" },
        { value: 1, label: "Same day" },
        { value: 2, label: "1 day before" },
        { value: 3, label: "2 days before" },
        { value: 4, label: "1 week before" },
      ]);
    }
  }, [isNoAlertSelected]);

  const HandleSelectAlertOver = (selectedOptionsOverdue) => {
    setSelectAlertOver(selectedOptionsOverdue);
  };
  const isNoAlertSelectedoverdue = SelectAlertOver?.some(
    (option) => option.value === 0
  );
  useEffect(() => {
    if (isNoAlertSelectedoverdue) {
      setalertOverdueOptions([{ value: 0, label: "No alert" }]);
      setSelectAlertOver([{ value: 0, label: "No alert" }]);
    } else {
      setalertOverdueOptions([
        { value: 0, label: "No alert" },
        { value: 1, label: "1 day after" },
        { value: 2, label: "2 days after" },
        { value: 3, label: "1 week after" },
      ]);
    }
  }, [isNoAlertSelectedoverdue]);

  const HandleSelecttreatment = (e) => {
    setSelecttreatment(e);
  };
  const HandleSelectspecialty = (e) => {
    setSelectspecialty(e);
  };
  const HandleSelectOrg = (e) => {
    setSelectOrg(e);
  };
  const HandleSchedularopen = () => {
    setshowModal(true);
  };
  const handleRepeatIntervalChange = (e) => {
    setRepeatInterval(e);
  };

  const [formData, setFormData] = useState({
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
  });

  const [dropdownValues, setDropdownValues] = useState({
    dropdown1: "",
    dropdown2: "",
    dropdown3: "",
    dropdown4: "",
    dropdown5: "",
  });

  let alerts = SelectAlert?.map((item) => item.value);
  let alertsover = SelectAlertOver?.map((item) => item.value);
  let Daysconvertschedule = [
    {
      value: Number(formData.field1),
      varient: dropdownValues.dropdown1,
    },
    {
      value: Number(formData.field2),
      varient: dropdownValues.dropdown2,
    },
    {
      value: Number(formData.field3),
      varient: dropdownValues.dropdown3,
    },
    {
      value: Number(formData.field4),
      varient: dropdownValues.dropdown4,
    },
    {
      value: Number(formData.field5),
      varient: dropdownValues.dropdown5,
    },
  ];
  // const totalDays = convertToDays(Daysconvertschedule);

  const Schedulerjson = {
    ak_id: login.ing_ak_id,
    title: repeatInterval,
    organization: SelectOrg,
    speciality: Selectspecialty,
    treatment_category: Selecttreatment,

    primary_diagnosis: {
      primary: primary,
      non_surgicalinput: NonsurgicalInput,
    },
    alert: alerts,
    overdue: alertsover,
    reference_date: [SelectReferdate],
    schedules: [
      {
        value: formData.field1,
        varient: dropdownValues.dropdown1,
        guid: convertToDays(formData.field1, dropdownValues.dropdown1),
      },
      {
        value: formData.field2,
        varient: dropdownValues.dropdown2,
        guid: convertToDays(formData.field2, dropdownValues.dropdown2),
      },
      {
        value: formData.field3,
        varient: dropdownValues.dropdown3,
        guid: convertToDays(formData.field3, dropdownValues.dropdown3),
      },
      {
        value: formData.field4,
        varient: dropdownValues.dropdown4,
        guid: convertToDays(formData.field4, dropdownValues.dropdown4),
      },
      {
        value: formData.field5,
        varient: dropdownValues.dropdown5,
        guid: convertToDays(formData.field5, dropdownValues.dropdown5),
      },
    ],
  };

  const handleClose = () => {
    setshowModal(false);
    setIsApiPending(false)
    setRepeatInterval("");
    setSelectOrg([]);
    setSelectspecialty("");
    setSelecttreatment("");
    setNonsurgicalInput("");
    setPrimary("");
    setSelectAlert([{ value: 0, label: "No alert" }]);
    setSelectAlertOver([{ value: 1, label: "1 day after" }]);
    setSelectReferdate("");
    setFormData({
      field1: "",
      field2: "",
      field3: "",
      field4: "",
      field5: "",
    });
    setDropdownValues({
      dropdown1: "",
      dropdown2: "",
      dropdown3: "",
      dropdown4: "",
      dropdown5: "",
    });
    setErr(false);
    if (visibleInput) {
      setVisibleInput(true);
    }
    setScheduleErr(false)
  };

  const resetFormState = () => {
    setRepeatInterval("");
    setSelectOrg([]);
    setSelectspecialty("");
    setSelecttreatment("");
    setNonsurgicalInput("");
    setPrimary("");
    setSelectAlert([{ value: 0, label: "No alert" }]);
    setSelectAlertOver([{ value: 1, label: "1 day after" }]);
    setSelectReferdate("");
    setFormData({
      field1: "",
      field2: "",
      field3: "",
      field4: "",
      field5: "",
    });
    setDropdownValues({
      dropdown1: "",
      dropdown2: "",
      dropdown3: "",
      dropdown4: "",
      dropdown5: "",
    });
    setErr(false);
    setScheduleErr(false)
  };

  const validateForm = () => {
    let hasError = false;
    // Validate dropdown fields
    for (let i = 1; i <= visibleInput; i++) {
      if (!dropdownValues[`dropdown${i}`]) {
        hasError = true;
        break;
      }
    }
    // Validate input fields
    for (let i = 1; i <= visibleInput; i++) {
      if (!formData[`field${i}`]) {
        hasError = true;
        break;
      }
    }
    return hasError;
  };

  const handleSave = async () => {
    if (repeatInterval === "") {
      setErr(true)
      setshowModal(true)
      return
    }
    if (SelectOrg.length === 0) {
      setErr(true)
      setshowModal(true)
      return
    }
    if (Selectspecialty === "") {
      setErr(true)
      setshowModal(true)
      return
    }
    if (Selecttreatment === "") {
      setErr(true)
      setshowModal(true)
      return
    }
    // if (((Selecttreatment === "Surgical") && (Selectspecialty !== "33")) || ((Selecttreatment === "Research") && (Selectspecialty !== "33"))) {
    //   if (primary === "") {
    //     setshowModal(true)
    //     setErr(true);
    //     return;
    //   }
    // }
    // if ((Selecttreatment === "Non-Surgical") || (((Selecttreatment === "Surgical") && (Selectspecialty === "33")) || ((Selecttreatment === "Research") && (Selectspecialty === "33")))) {
    //   if (NonsurgicalInput === "") {
    //     setErr(true)
    //     setshowModal(true)
    //     return
    //   }
    // }
    if (SelectReferdate === "") {
      setErr(true)
      setshowModal(true)
      return
    }
    if (Schedulerjson.schedules) {
      let filteredData = Schedulerjson.schedules.filter(item => !(item.guid === 0 && item.value === ""));
      console.log("filteredData", filteredData)

      const hasConsecutiveSameGuidAndValue = filteredData.slice(0, -1).some(
        (item, index) =>
          item.guid === filteredData[index + 1].guid &&
          item.value === filteredData[index + 1].value
      );
      const hasMatchingGuidAndValue = filteredData.some(
        (item, index) =>
          filteredData.slice(index + 1).some(
            otherItem => item.guid === otherItem.guid && item.value === otherItem.value
          )
      );

      if (hasMatchingGuidAndValue) {
        setScheduleErr(hasMatchingGuidAndValue)
        return
      } else {
        setScheduleErr(false)
      }
    }
    // if (dropdownValues === "") {
    //   setErr(true)
    //   setshowModal(true)
    //   return
    // }
    // if (formData === "") {
    //   setErr(true)
    //   setshowModal(true)
    //   return
    // }
    const formHasError = validateForm();
    if (formHasError) {
      setErr(true);
      setshowModal(true);
      return;
    }
    else {
      setIsApiPending(true);
      const response = await axiosInstance.current.post(
        `extapp/doctors/proms_clinicians_schedulerInsert?ak_dc=${login.ing_ak_id}`,
        Schedulerjson,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        handlereff(new Date().getMilliseconds());
        setMessageType("success");
        setMessage("Scheduler Added Successfully");
        setScheduleErr(false)
        setshowModal(false);
        dispatch(noteRefresh(!dataRefe))

        handleClose();
        resetFormState();
        setIsApiPending(false);
      }
      setTimeout(() => {
        setMessage(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (open) {
      setshowModal(true);
    }
  }, [open]);

  const [options, setOption] = useState([]);
  useEffect(() => {
    const getSpeciality = async () => {
      const response = await axiosInstance.current.get(
        `/extapp/doctors/getOpNoteSpecialities/${login.ing_ak_id}`
      );
      console.log("API Response:", response.data);
      const data = response.data.data.findIndex(
        (item) => item.label === "Knee"
      );
      if (data !== -1) {
        response.data.data[data].label = "Knee";
      }
      // let data=response.data.data.filter((item)=>item.label==="Adolescent Knee")
      // let data1=response.data.data.map((item)=>{
      //   return{
      //     label:item.label,value:item.value
      //   }
      // }).push({label:"Knee",value:5})

      setOption(response.data.data);
    };
    getSpeciality();
  }, [login]);

  useEffect(() => {
    const getOrgData = async () => {
      const response = await axiosInstance.current.get(
        `extapp/doctors/getAllUserOrganizations?doctor_id=${login.ing_ak_id}&useFor=doctor`
      );
      setOrgdata(response.data.data);
    };

    getOrgData();
  }, [login, axiosInstance]);

  const [orgdrop, setOrgdrop] = useState("");
  useEffect(() => {
    if (orgdata?.length === 1) {
      setOrgdrop(orgdata[0]?.label);
    }
  }, [orgdata]);

  const [visibleInput, setVisibleInput] = useState(1);
  const handleInputChange = (fieldName, value, name) => {
    let newValue;
    if (value === '0') {
      newValue = '';
    }else{
      // if(dropdownValues.dropdown1==="" ) return formData.field1=""
      // else if(dropdownValues.dropdown2==="" ) return formData.field2=""
      if (typeData === "Days") {
        newValue = value.replace(/[^0-9]/g, "");
        newValue = Math.min(parseInt(newValue, 10) || 0, 365).toString();
      } else if (typeData === "Weeks") {
        newValue = value.replace(/[^0-9]/g, "");
        newValue = Math.min(parseInt(newValue, 10) || 0, 52).toString();
      } else if (typeData === "Months") {
        newValue = value.replace(/[^0-9]/g, "");
        newValue = Math.min(parseInt(newValue, 10) || 0, 12).toString();
      } else if (typeData === "Years") {
        newValue = value.replace(/[^0-9]/g, "");
        newValue = Math.min(parseInt(newValue, 10) || 0, 5).toString();
      }
    }
    if (value === '') {
      newValue = '';
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleDropdownChange = (dropdownName, value) => {
    setTypeData(value);
    setDropdownValues((prevValues) => ({
      ...prevValues,
      [dropdownName]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const HandleplusIcon = () => {
    if (visibleInput < 5) {
      setVisibleInput((prevInput) => prevInput + 1);
    }
  };

  console.log("SelectOrg", SelectOrg)
  return (
    <>
      {message && <Message message={message} type={messageType} />}
      <button
        className="btn_outline"
        onClick={() => HandleSchedularopen()}
        style={{
          borderRadius: 20,
          minHeight: 40,
          width: 250,
          fontSize: 14,
        }}
      >
        {"+ Add PROMs Schedule"}
      </button>

      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        // dialogClassName="modal-xl modal-dialog-centered"
        dialogClassName="modal-dialog-centered max_width_model"
        className="settings_ppup_main "
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            {"Set PROMs Scheduler khan"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <div className="">
                    <InputField
                      label="PROMs Schedule Title*"
                      value={repeatInterval}
                      type="text"
                      placeholder="Specify"
                      onChange={handleRepeatIntervalChange}
                      styleClass="mw-100 rounded-pill"
                    />
                    {!repeatInterval && (
                      <>
                        {err && (
                          <span className="text-danger">
                            This field is required
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <div className="row">
                  <div className="col-6">
                    <label className="form-label">Organization*</label>
                    <CreatableSelect
                      isClearable
                      isMulti
                      value={SelectOrg}
                      options={orgdata}
                      onChange={HandleSelectOrg}
                      className="multi_select_drp1"
                      isSearchable={false}
                      overrideStrings={{
                        selectSomeItems: "Select",
                        allItemsAreSelected:
                          orgdata.length > 1
                            ? "All Organization are selected."
                            : orgdrop,
                        selectAll: "Select All",
                        search: "Search",
                      }}
                    />
                    {SelectOrg.length === 0 && (
                      <>
                        {err && (
                          <span className="text-danger">
                            This field is required
                          </span>
                        )}
                      </>
                    )}
                  </div>

                  <div className="col-6">
                    <Dropdown
                      data={options}
                      label="Specialty*"
                      value={Selectspecialty}
                      onChange={(e) => HandleSelectspecialty(e)}
                      styleClass="br_10 mxw_100 mb_20 "
                      placeholder="Select "
                      className="br_20"
                    />
                    {!Selectspecialty && (
                      <>
                        {err && (
                          <span className="text-danger">
                            This field is required
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <Dropdown
                      data={[
                        { value: "Surgical", label: "Surgical" },
                        { value: "Non-Surgical", label: "Non-Surgical" },
                        { value: "Research", label: "Research" },
                      ]}
                      label="Treatment category*"
                      styleClass=""
                      value={Selecttreatment}
                      onChange={HandleSelecttreatment}
                      placeholder="Select"
                    />
                    {!Selecttreatment && (
                      <>
                        {err && (
                          <span className="text-danger">
                            This field is required
                          </span>
                        )}
                      </>
                    )}
                  </div>

                  {Selecttreatment === "Non-Surgical" ||
                    (Selecttreatment === "Surgical" &&
                      Selectspecialty === "33") ||
                    (Selecttreatment === "Research" &&
                      Selectspecialty === "33") ? (
                    <div className="col-6">
                      <div className="">
                        <InputField
                          label="Primary Diagnosis"
                          value={NonsurgicalInput}
                          type="text"
                          placeholder="Specify"
                          onChange={handleNonsurgicalInput}
                          styleClass="mw-100 rounded-pill"
                        />
                        {/* {!NonsurgicalInput && (
                          <>
                            {err && (
                              <span className="text-danger">
                                This field is required
                              </span>
                            )}
                          </>
                        )} */}
                      </div>
                    </div>
                  ) : null}

                  {(Selecttreatment === "Surgical" ||
                    Selecttreatment === "Research") &&
                    (Selectspecialty === "4" ||
                      Selectspecialty === "6" ||
                      Selectspecialty === "5" ||
                      Selectspecialty === "22" ||
                      Selectspecialty === "21" ||
                      Selectspecialty === "23") && (
                      <>
                        <div className="col-6">
                          {Selectspecialty === "4" && (
                            <>
                              <label
                                className={`form-label ${addOpStyles.form_label}`}
                              >
                                Primary Diagnosis
                              </label>
                              <Dropdown
                                data={[
                                  {
                                    value: "Rotator Cuff Repair",
                                    label: "Rotator Cuff Repair",
                                  },
                                  {
                                    value: "Instability",
                                    label: "Instability",
                                  },
                                  {
                                    value: "Scapula Fracture",
                                    label: "Scapula Fracture",
                                  },
                                  {
                                    value: "Proximal humerus fracture",
                                    label: "Proximal humerus fracture",
                                  },
                                  {
                                    value: "Clavicle fracture",
                                    label: "Clavicle fracture",
                                  },
                                  {
                                    value: "Arthroplasty",
                                    label: "Arthroplasty",
                                  },
                                  {
                                    value: "ACJ",
                                    label: "ACJ",
                                  },
                                  {
                                    value: "Biceps tendon disorder",
                                    label: "Biceps tendon disorder",
                                  },
                                  {
                                    value: "Other",
                                    label: "Other",
                                  },
                                ]}
                                label={""}
                                styleClass=""
                                value={primary}
                                onChange={handleprimary}
                                placeholder={" Please select"}
                              />

                              {/* {!primary && (
                                <>
                                  {err && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              )} */}
                            </>
                          )}
                          {Selectspecialty === "6" && (
                            <>
                              <label
                                className={`form-label ${addOpStyles.form_label}`}
                              >
                                Primary Diagnosis
                              </label>
                              <Dropdown
                                data={[
                                  {
                                    value: "Arthroscopy",
                                    label: "Arthroscopy",
                                  },
                                  {
                                    value: "Arthroplasty",
                                    label: "Arthroplasty",
                                  },
                                  {
                                    value: "Fracture Surgery",
                                    label: "Fracture Surgery",
                                  },
                                  {
                                    value: "Other Hip Preservation Surgery",
                                    label: "Other Hip Preservation Surgery",
                                  },
                                  {
                                    value: "Others",
                                    label: "Others",
                                  },
                                ]}
                                label={""}
                                styleClass=""
                                value={primary}
                                onChange={handleprimary}
                                placeholder={" Please select"}
                              />
                              {/* {!primary && (
                                <>
                                  {err && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              )} */}
                            </>
                          )}
                          {Selectspecialty === "5" && (
                            <>
                              <label
                                className={`form-label ${addOpStyles.form_label}`}
                              >
                                Primary Diagnosis
                              </label>
                              <Dropdown
                                data={[
                                  {
                                    value: "Arthritis",
                                    label: "Arthritis",
                                  },
                                  {
                                    value: "Instability",
                                    label: "Instability",
                                  },
                                  {
                                    value: "Patellar instability",
                                    label: "Patellar instability",
                                  },
                                  {
                                    value: "Meniscal injury",
                                    label: "Meniscal injury",
                                  },
                                  {
                                    value: "Fracture",
                                    label: "Fracture",
                                  },
                                  {
                                    value: "Septic knee",
                                    label: "Septic knee",
                                  },
                                  {
                                    value: "Osteonecrosis",
                                    label: "Osteonecrosis",
                                  },
                                  {
                                    value: "Revision arthroplasty",
                                    label: "Revision arthroplasty",
                                  },
                                  {
                                    value: "Chondral injury",
                                    label: "Chondral injury",
                                  },
                                  {
                                    value: "Others",
                                    label: "Others",
                                  },
                                ]}
                                label={""}
                                styleClass=""
                                value={primary}
                                onChange={handleprimary}
                                placeholder={" Please select"}
                              />
                              {/* {!primary && (
                                <>
                                  {err && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              )} */}
                            </>
                          )}
                          {Selectspecialty === "22" && (
                            <>
                              <label
                                className={`form-label ${addOpStyles.form_label}`}
                              >
                                Primary Diagnosis
                              </label>
                              <Dropdown
                                data={[
                                  {
                                    value: "Fracture",
                                    label: "Fracture",
                                  },
                                  {
                                    value: "Instability",
                                    label: "Instability",
                                  },
                                  {
                                    value: "Arthritis",
                                    label: "Arthritis",
                                  },
                                  {
                                    value: "Failed arthroplasty",
                                    label: "Failed arthroplasty",
                                  },
                                  {
                                    value: "Ligament/Tendon injury",
                                    label: "Ligament/Tendon injury",
                                  },
                                  {
                                    value: "Nerve entrapment",
                                    label: "Nerve entrapment",
                                  },
                                  {
                                    value: "Others",
                                    label: "Others",
                                  },
                                ]}
                                label={""}
                                styleClass=""
                                value={primary}
                                onChange={handleprimary}
                                placeholder={" Please select"}
                              />
                              {/* {!primary && (
                                <>
                                  {err && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              )} */}
                            </>
                          )}
                          {Selectspecialty === "21" && (
                            <>
                              <label
                                className={`form-label ${addOpStyles.form_label}`}
                              >
                                Primary Diagnosis
                              </label>
                              <Dropdown
                                data={[
                                  {
                                    value: "Fracture/Dislocation",
                                    label: "Fracture/Dislocation",
                                  },
                                  {
                                    value: "Arthritis",
                                    label: "Arthritis",
                                  },
                                  {
                                    value: "Avascular necrosis",
                                    label: "Avascular necrosis",
                                  },
                                  {
                                    value: "Charcot foot",
                                    label: "Charcot foot",
                                  },
                                  {
                                    value: "Amputation",
                                    label: "Amputation",
                                  },
                                  {
                                    value: "Arthroscopy",
                                    label: "Arthroscopy",
                                  },
                                  {
                                    value: "Instability",
                                    label: "Instability",
                                  },
                                  {
                                    value: "Deformity",
                                    label: "Deformity",
                                  },
                                  {
                                    value: "Achilles tendon pathology",
                                    label: "Achilles tendon pathology",
                                  },
                                  {
                                    value: "Others",
                                    label: "Others",
                                  },
                                ]}
                                label={""}
                                styleClass=""
                                value={primary}
                                onChange={handleprimary}
                                placeholder={" Please select"}
                              />
                              {/* {!primary && (
                                <>
                                  {err && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              )} */}
                            </>
                          )}
                          {Selectspecialty === "23" && (
                            <>
                              <label
                                className={`form-label ${addOpStyles.form_label}`}
                              >
                                Primary Diagnosis
                              </label>
                              <Dropdown
                                data={[
                                  {
                                    value: "Fracture/Dislocation",
                                    label: "Fracture/Dislocation",
                                  },
                                  {
                                    value: "Instability and ligamentous injury",
                                    label: "Instability and ligamentous injury",
                                  },
                                  {
                                    value: "Tendon injury",
                                    label: "Tendon injury",
                                  },
                                  {
                                    value: "Compression neuropathy",
                                    label: "Compression neuropathy",
                                  },
                                  {
                                    value: "Nerve injury",
                                    label: "Nerve injury",
                                  },
                                  {
                                    value: "Arthritis",
                                    label: "Arthritis",
                                  },
                                  {
                                    value: "Others",
                                    label: "Others",
                                  },
                                ]}
                                label={""}
                                styleClass=""
                                value={primary}
                                onChange={handleprimary}
                                placeholder={" Please select"}
                              />
                              {/* {!primary && (
                                <>
                                  {err && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              )} */}
                            </>
                          )}
                        </div>
                      </>
                    )}
                </div>

                <div className="row">
                  <div className="col-6">
                    <label className="form-label">Alert</label>
                    <CreatableSelect
                      isClearable
                      isMulti
                      value={SelectAlert}
                      onChange={HandleSelectAlert}
                      options={alertOptions}
                      className="multi_select_drp1"
                      isSearchable={false}
                    />
                  </div>

                  <div className="col-6">
                    <label className="form-label">
                      Alert For Overdue Forms
                    </label>
                    <CreatableSelect
                      isClearable
                      isMulti
                      value={SelectAlertOver}
                      options={alertOverdueOptions}
                      onChange={HandleSelectAlertOver}
                      className="multi_select_drp1"
                      isSearchable={false}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="row">
                <div className="col-1">
                  <div class="horizontal-line"></div>
                </div>
                <div className="col-10">
                  <div className="row">
                    <div className="col-6">
                      <Dropdown
                        data={[
                          { value: 1, label: "Treatment Date" },
                          { value: 2, label: "Date of initial PROMs" },
                        ]}
                        label="Reference Date*"
                        styleClass=""
                        value={SelectReferdate}
                        onChange={HandleSelectReferdate}
                        placeholder="Select"
                      />
                      {!SelectReferdate && (
                        <>
                          {err && (
                            <span className="text-danger">
                              This field is required
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <label>Set Schedule*</label>
                  <form onSubmit={handleSubmit}>
                    {[1, 2, 3, 4, 5].map(
                      (inputNumber) =>
                        inputNumber <= visibleInput && (
                          <div key={inputNumber} className="row">
                            <div className="col-6">
                              <Dropdown
                                data={[
                                  { value: "Days", label: "Days" },
                                  { value: "Weeks", label: "Weeks" },
                                  { value: "Months", label: "Months" },
                                  { value: "Years", label: "Years" },
                                ]}
                                // label="Anterior drawer test"
                                styleClass=""
                                value={dropdownValues[`dropdown${inputNumber}`]} // Pass the specific property
                                onChange={(value) =>
                                  handleDropdownChange(
                                    `dropdown${inputNumber}`,
                                    value
                                  )
                                }
                                placeholder="Select"
                              />
                              {!dropdownValues[`dropdown${inputNumber}`] && (
                                <>
                                  {err && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              )}
                            </div>
                            <div className="col-6">
                              <InputField
                                // label="Set Schedule*"
                                value={formData[`field${inputNumber}`]}
                                name={`field${inputNumber}`}
                                type="text"
                                placeholder="0"
                                onChange={(value) =>
                                  handleInputChange(
                                    `field${inputNumber}`,
                                    value,
                                    `field${inputNumber}`
                                  )
                                }
                                styleClass="mw-100 rounded-pill"
                              // disabled={visibleInput===4?true:false}
                              />
                              {!formData[`field${inputNumber}`] && (
                                <>
                                  {err && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              )}
                            </div>

                          </div>
                        )
                    )}
                    {visibleInput < 5 && (
                      <span
                        className="add_btn1"
                        onClick={(e) => HandleplusIcon(e)}
                      >
                        <img
                          src={plusIcon}
                          className="icon_style"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Add clinician exam"
                          alt="Add-clinical"
                        />
                      </span>
                    )}
                  </form>
                </div>
                {formData && (
                  <>
                    {setSheduleerr && (
                      <span className="text-danger text-center">
                        Consequtive Days , Weeks , Months or Years value should not same.
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="text-end">
              <button
                className="btn_outline"
                onClick={() => handleSave()}
                style={{
                  borderRadius: 20,
                  minHeight: 40,
                  width: 140,
                  fontSize: 14,
                }}
                disabled={isApiPending}
              >
                Save
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
