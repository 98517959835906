export  function returnStrings(arr) {
    let stringArray = [];
    for (let i = 0; i < arr.length; i++) {
      if (typeof arr[i] === "string" && arr[i].trim() !== "") {
        stringArray.push(arr[i]);
      }
    }
    return stringArray.join(", ");
  }
  export function atLeastOneTrue(arr){
    let ArrReturn=arr.some(val => val === true)
    return ArrReturn
  }
