import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import icon_img from "../../../images/arrow-bright.svg";
import { useSearchParams } from "react-router-dom";

export default function SmallProfile() {
  const { akId } = useParams();
  const { akid } = useParams();
  const ak_id = akId ? akId : akid;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let name = searchParams.get("name");
  let age = searchParams.get("age");                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
  const handleChange = () => {
    if (akid) {
      navigate(`/patient-demographic/${akid}`);
    } else {
      navigate(`/patient-demographic/${akId}`);
    }
  };
  return (
    <div className="float-end whiteboxSmallProfile c_pointer">
      <div className="d-flex " onClick={() => handleChange()}>
        <label className="user-letter-smProfile text-capitalize">
          {name.charAt(0)}
        </label>
        <div className="ms-3 text-start">
          <h6 className="text-capitalize">
            {name}({window.atob(ak_id)})
          </h6>
          <span className="">Age: {age}  {Number(age) <= 1 ?"Year" : "Years"}</span>
        </div>
        <img src={icon_img} alt="icon" class="float-end ms-5"></img>
      </div>
    </div>
  );
}
