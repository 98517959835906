import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import settingsStyle from "./Settings.module.css";
import Notifications from "./notifications/Notifications";
import Language from "./language/Language";
import Account from "./account/Account";
import MyClinics from "./my-clinics/MyClinics";
import bellGreyIcon from "../../images/bell_grey.svg";
import bellBlueIcon from "../../images/bell_blue.svg";
import regionGreyIcon from "../../images/region_grey.svg";
import regionBlueIcon from "../../images/region_blue.svg";
import accountGreyIcon from "../../images/account_grey.svg";
import accountBlueIcon from "../../images/account_blue.svg";
import billingGreyIcon from "../../images/billing_grey.svg";
import billingBlueIcon from "../../images/billing_blue.svg";
import edit from "../../images/single-edit-icon.svg";
import editGray from "../../images/single-edit-icon-gray.svg";
import useTranslation from "../customHooks/translations";
import template from '../../images/dasboardicon/Template.svg';
import templategray from '../../images/dasboardicon/Templategray.svg';
import Temp from "./template/Temp";
import Preference from "./preferences/Preference";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import Sign from "./sign/Sign";
import PromsSchedular from "./Proms/PromsSchedular";
import PromesSchedule from "../patients/PromesSchedule";
import DefaultTem from "./treatment/DefaultTem";
import Scheduler from "../../images/Scheduler.png"
import Schedulerblue from "../../images/Schedulerblue.png"
function Settings({ handleClose }) {
  const translation = useTranslation();
  // const navigate = useNavigate()
  // const login = useSelector((state) => state.mainReducer.loginData.arr);
  // const handleDocoment =()=>{
  //   handleClose()
  //   navigate(`template/${login.ing_ak_id}`)

  // }
  const [open, setOpen] = useState(false)
  const [data, setData] = useState([])
  const [tab, setTab] = useState(false)
  const Dataget = (data) => {
    setData(data)
  }
  const [SchedulertabOpen, setSchedulertabOpen] = useState(Number())
  const handleFirstOpen = (e) => {
    setSchedulertabOpen(e)
    console.log(data.length, e, "Preopneded", tab)
    if (e === 2) {
      if (data.length === 0 && tab) {
        // setTimeout(() => {
        setOpen(true)
        // }, 4000);

      }
    } else {
      setOpen(false)
    }
  }
  // useEffect(()=>{
  //   if(SchedulertabOpen===2){
  //   if(data.length===0 && tab){
  //       setOpen(true)
  //   }
  // }
  // },[tab,data.length])

  return (
    <>
      <Tab.Container id="settings_vert_tab" defaultActiveKey="notifications">
        <Row className={`settings_main`}>
          <Col md={3} sm={2}>
            <Nav
              variant="pills"
              className={`flex-column ${settingsStyle.left_tab_menu}`}
            >
              <Nav.Item className={`${settingsStyle.nav_item}`}>
                <Nav.Link
                  eventKey="notifications"
                  href="#"
                  className={`${settingsStyle.nav_link}`}
                  onClick={() => handleFirstOpen(8)}
                >
                  <img src={bellGreyIcon} alt="icon" className="grey_icon" />
                  <img src={bellBlueIcon} alt="icon" className="blue_icon" />
                  {translation.notifications}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={`${settingsStyle.nav_item}`}>
                <Nav.Link
                  eventKey="document"
                  // onClick={()=>handleDocoment()}
                  href="#"
                  className={`${settingsStyle.nav_link}`}
                  onClick={() => handleFirstOpen(7)}
                >
                  <img src={templategray} alt="icon" className="grey_icon" />
                  <img src={template} alt="icon" className="blue_icon" />
                  {translation.document_templates}


                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={`${settingsStyle.nav_item}`}>
                <Nav.Link
                  eventKey="language"
                  href="#"
                  className={`${settingsStyle.nav_link}`}
                  onClick={() => handleFirstOpen(6)}
                >
                  <img src={regionGreyIcon} alt="icon" className="grey_icon" />
                  <img src={regionBlueIcon} alt="icon" className="blue_icon" />
                  {translation.language}
                </Nav.Link>
              </Nav.Item>



              <Nav.Item className={`${settingsStyle.nav_item}`}>
                <Nav.Link
                  eventKey="myclinics"
                  href="#"
                  className={`${settingsStyle.nav_link}`}
                  onClick={() => handleFirstOpen(5)}
                >
                  <img src={billingGreyIcon} alt="icon" className="grey_icon" />
                  <img src={billingBlueIcon} alt="icon" className="blue_icon" />
                  {translation.myClinics}
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item className={`${settingsStyle.nav_item}`}>
                <Nav.Link
                  eventKey="account"
                  href="#"
                  className={`${settingsStyle.nav_link}`}
                >
                  <img src={accountGreyIcon} alt="icon" className="grey_icon" />
                  <img src={accountBlueIcon} alt="icon" className="blue_icon" />
                 
                  Visible Scores
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item className={`${settingsStyle.nav_item}`}>
                <Nav.Link
                  eventKey="sign"
                  href="#"
                  className={`${settingsStyle.nav_link}`}
                  onClick={() => handleFirstOpen(4)}
                >
                  <img src={editGray} alt="icon" className="grey_icon" />
                  <img src={edit} alt="icon" className="blue_icon" />
                  {/* {translation.account} */}
                  My Signature
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={`${settingsStyle.nav_item}`}>
                <Nav.Link
                  eventKey="Preferences"
                  href="#"
                  className={`${settingsStyle.nav_link}`}
                  onClick={() => handleFirstOpen(3)}
                >
                  <img src={accountGreyIcon} alt="icon" className="grey_icon" />
                  <img src={accountBlueIcon} alt="icon" className="blue_icon" />
                  {/* {translation.account} */}
                  Preferences
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={`${settingsStyle.nav_item}`}>
                <Nav.Link
                  eventKey="Scheduler"
                  href="#"
                  className={`${settingsStyle.nav_link}`}
                  onClick={() => handleFirstOpen(2)}
                >
                  <img
                    src={Scheduler}
                    alt="icon"
                    className="grey_icon"
                    style={{ filter: 'grayscale(100%)', /* Add any other styles you need */ }}
                  />
                  {/* <img src={Scheduler} alt="icon" className="grey_icon" /> */}
                  <img src={Schedulerblue} alt="icon" className="blue_icon" />

                  PROMs Scheduler
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col md={9} sm={10}>
            <Tab.Content className={`${settingsStyle.tab_content}`}>
              <Tab.Pane
                eventKey="notifications"
                className={`${settingsStyle.tab_pane}`}
              >
                <Notifications />
              </Tab.Pane>
              <Tab.Pane
                eventKey="document"
                className={`${settingsStyle.tab_pane}`}
              >
                <Temp handleClose={handleClose} />
              </Tab.Pane>
              <Tab.Pane
                eventKey="language"
                className={`${settingsStyle.tab_pane}`}
              >
                <Language />
              </Tab.Pane>

              <Tab.Pane
                eventKey="myclinics"
                className={`${settingsStyle.tab_pane}`}
              >
                <MyClinics />
              </Tab.Pane>
              <Tab.Pane
                eventKey="account"
                className={`${settingsStyle.tab_pane}`}
              >

              </Tab.Pane>
              <Tab.Pane
                eventKey="sign"
                className={`${settingsStyle.tab_pane}`}
              >
                <Sign />
              </Tab.Pane>
              <Tab.Pane
                eventKey="Preferences"
                className={`${settingsStyle.tab_pane}`}
              >
                <Preference />
                <hr />
                <Account />
                <DefaultTem />
              </Tab.Pane>
              <Tab.Pane
                eventKey="Scheduler"
                className={`${settingsStyle.tab_pane}`}
              >
                <PromsSchedular open={open} Dataget={Dataget} setTab={setTab} />

              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
}

export default Settings;
