/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import fileUploadStyle from "./FileUpload.module.css";
import uploadIcon from "../../../images/fileupload.svg";
import crossIcon from "../../../images/crossicon.svg";
import Fill from "../../../images/Fill.svg";
import useAxiosUpload from "../../../axiosinstanceUpload";
import { fileSizeBYkb } from "../../../utilities/FileSize";
import DeleteConfirmation from "../../custom/delete-confirmation/DeleteConfirmation";
import { useSelector } from "react-redux";
function FileUpload({ hideModel, auto_id, id, name, akID, initial }) {
  // const { akId } = useParams();

  const [files, setFiles] = useState([]);
  const [resfiles, setresFiles] = useState([]);
  const axiosInstanceUpload = useAxiosUpload();
  // const axiosInstance = useAxios();
  const [show, setShow] = useState(false);
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const [deleteId, setdeleteId] = useState("");
  const [deleteIdUpload, setdeleteIdUpload] = useState("");
  const [deleteResId, setdeleteResId] = useState("");
  const [, setprocTime] = useState(0);

  const chunkSize = 1024 * 1024 * 5; //its 5MB, increase the number measure in mbfunction App() {
  const [, setShowProgress] = useState(false);
  const [counter, setCounter] = useState(1);
  const [fileToBeUpload, setFileToBeUpload] = useState({});
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
  const [progress, setProgress] = useState(0);
  // const [fileGuid, setFileGuid] = useState("");
  const [fileSize, setFileSize] = useState(0);
  // const [uploadFiles, setUploadFiles] = useState(false);
  const [uploadNumber, setuploadNumber] = useState(0);
  const [chunkCount, setChunkCount] = useState(0);
  const [uploadid, setUploadid] = useState("");
  const [sessionId, setsessionId] = useState("");
  const [theArray, setTheArray] = useState([]);
  const [s3Key, sets3Key] = useState("");
  const [abc, setAbc] = useState(false);

  // const continueRedirect = (e) => {
  //   setShow(true);
  //   countTo100WithProgressBar();
  //   // console.log(files);
  //   if (files.length !== 0) {
  //     const formData = new FormData();
  //     for (const file of files) {
  //       formData.append("files", file);
  //     }
  //     axiosInstanceUpload.current
  //       .post(
  //         `ext_global/upload_proof?ak_dc=${login.ing_ak_id}&ak_ou=&id=${auto_id}&type=${id}`,
  //         formData
  //       )
  //       .then((response) => {
  //         if (response.status === 201) {
  //           setprocTime("100%");
  //           setresFiles(response.data.data);
  //           setShow(false);
  //           setFiles([]);
  //         }
  //       });
  //   }
  // };
  // console.log(resfiles);
  const [errorMsg, setErrorMsg] = useState("");
  const handleDrop = (event) => {
    event.preventDefault();
    // let droppedFiles = event.dataTransfer.files;

    const selectedFiles = event.dataTransfer.files;
    let errorMsg = "";

    for (const file of selectedFiles) {
      if (file.size > 15000000) {
        errorMsg = "File size exceeds 15MB limit";
        break;
      }
      if (
        ![
          "image/png",
          "image/jpeg",
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "image/jpg",
        ].includes(file.type)
      ) {
        errorMsg =
          "Invalid file type. Only PNG, JPEG, JPG, XLSX and PDF files are allowed.";
        break;
      }
    }

    if (errorMsg) {
      event.target.value = null; // clear the selected files
      setErrorMsg(errorMsg);
    } else {
      // setFiles(selectedFiles);
      setFiles([...files, ...selectedFiles]);
      setErrorMsg("");
    }

    // setFiles([...files, ...droppedFiles]);
    setprocTime("0%");
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    let errorMsg = "";

    for (const file of selectedFiles) {
      if (file.size > 5000000) {
        errorMsg = "File size exceeds 5MB limit";
        break;
      }
      if (
        ![
          "image/png",
          "image/jpeg",
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "image/jpg",
        ].includes(file.type)
      ) {
        errorMsg =
          "Invalid file type. Only PNG, JPEG, JPG, XLSX and PDF files are allowed.";
        break;
      }
    }

    if (errorMsg) {
      e.target.value = null; // clear the selected files
      setErrorMsg(errorMsg);
    } else {
      setFiles([...files, ...selectedFiles]);
      setErrorMsg("");
    }
    setprocTime("0%");
  };

  useEffect(() => {
    setShow(true);
    if (files) {
      let totalFile = files.length - 1;
      if (uploadNumber <= totalFile) {
 
        let _file = files[uploadNumber];
        const _totalCount =
          _file.size % chunkSize === 0
            ? _file.size / chunkSize
            : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
        setChunkCount(_totalCount);
        setFileToBeUpload(_file);
        setFileSize(_file.size);
        initUpload(_file.name);
      }
    }
  }, [files, uploadNumber]);

  useEffect(() => {
    if (fileSize > 0) {
      setShowProgress(true);
      fileUpload(counter);
    }
  }, [abc, progress]);

  const initUpload = async (q) => {
    setBeginingOfTheChunk(0);
    setEndOfTheChunk(chunkSize);
    setUploadid("");
    setsessionId("");
    sets3Key("");
    setCounter(1);
    setTheArray([]);
    try {
      // Call the Init API
      const response = await axiosInstanceUpload.current.post(
        `extglobal/init-upload_`,
        {
          ak_dc: initial ? akID : login.ing_ak_id,
          type: id,
          filename: q,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      setUploadid(response.data.data.uploadId);
      setsessionId(response.data.data.sessionId);
      sets3Key(response.data.data.s3Key);
      setAbc(!abc);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fileUpload = () => {
    setCounter(counter + 1);
    if (counter <= chunkCount) {
      let chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
      uploadChunk(chunk);
    }
  };
  const uploadChunk = async (chunk) => {
    const formData = new FormData();
    formData.append("sessionId", sessionId);
    formData.append("uploadId", uploadid);
    formData.append("key", s3Key);
    formData.append("partNum", counter);
    formData.append(`size`, chunk.size);
    formData.append(`chunk`, chunk);
    // console.log(chunk);
    try {
      // let body = {chunk};
      const response = await axiosInstanceUpload.current.post(
        // `http://localhost:5000/continue?fileName=${fileGuid}&uploadId=${uploadid}&chunkNumber=${counter}`,
        "extglobal/upload-chunk_",
        formData
      );
      let data = response.data.data;
      console.log(data.data);
      setTheArray([...theArray, data]);
      //let arr=dataPacks.push(data)
      // setDataPacks([data])

      if (data.PartNumber !== 0) {
        setBeginingOfTheChunk(endOfTheChunk);
        setEndOfTheChunk(endOfTheChunk + chunkSize);
        if (counter == chunkCount) {
          console.log(data);
          console.log("Process is complete, counter", counter);

          // await uploadCompleted();
        } else {
          var percentage = (counter / chunkCount) * 100;
          setProgress(percentage);
        }
      } else {
        console.log("Error Occurred:", data.errorMessage);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const saveData = async () => {
    // console.log("hhhhhhh");
    let per = {
      ak_dc: initial ? akID : login.ing_ak_id,
      ak_ou: "",
      type: id,
      filename: fileToBeUpload.name,
      key: s3Key,
      id: auto_id,
    };
    const response = await axiosInstanceUpload.current.post(
      `extglobal/saveFile_`,
      per
    );
    if (response) {
      setuploadNumber(uploadNumber + 1);
      setresFiles([...resfiles, response.data.data]);
      
    }
  };
  const uploadCompleted = async () => {
    const params = {
      Key: s3Key,
      UploadId: uploadid,
      MultipartUpload: {
        Parts: theArray,
      },
    };

    const response = await axiosInstanceUpload.current.post(
      `extglobal/end-upload_`,
      params
    );
    const data1 = await response.data;
    if (data1) {
      // data2.setVideoload(false);
      saveData();
      setProgress(100);

      // document.getElementById("vid_disable").disabled = true;
    }
  };

  useEffect(() => {
    if (chunkCount !== 0) {
      if (chunkCount === theArray.length) {
        // console.log("hhhhh");
        uploadCompleted();
      }
    }
  }, [theArray]);

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const deleteArr = (e, index, res_id) => {
    setdeleteId(e);
    setdeleteIdUpload(index);
    setdeleteResId(res_id);
    setDisplayConfirmationModalDelete(true);
  };

  const deleteForS3 = (e) => {
    axiosInstanceUpload.current
      .delete(`ext_global/delete_file?key=${e}&resource_id=${deleteResId}`)
      .then((res) => {
        console.log(res);
      });
  };
  // console.log(deleteIdUpload);
  const [displayConfirmationModalDelete, setDisplayConfirmationModalDelete] =
    useState(false);
  const submitDelete = (type, e) => {
    if (files.length !== 0) {
      let x = files.filter((el, i) => e !== i);
      setFiles(x);
    }

    if (resfiles.length !== 0) {
      deleteForS3(deleteId);
      let x = resfiles.filter((el, i) => deleteIdUpload !== i);
      setresFiles(x);
      setuploadNumber(x.length);

      if (files.length !== 0) {
        let x = files.filter((el, i) => deleteIdUpload !== i);
        setFiles(x);
        setuploadNumber(x.length);
      }
    }
    setDisplayConfirmationModalDelete(false);
  };
  const hideConfirmationModal = () => {
    setDisplayConfirmationModalDelete(false);
  };

  // function countTo100WithProgressBar() {
  //   let i = 1;
  //   let interval = setInterval(() => {
  //     let progress = (i / 100) * 100;
  //     setprocTime(progress.toString() + "%");
  //     i++;
  //     if (i > 100) {
  //       setprocTime("100%");

  //       clearInterval(interval);
  //     }
  //   }, 100);
  // }

  return (
    <>
      <div className={`file_upload`}>
        <h4 className="box-title f_22 mb-3">
          {name === "Upload proof" ? "Upload Proof Of Organization" : name}
        </h4>
        <div
          className={`${fileUploadStyle.drop_zone}`}
          id="dragandrophandler"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <div className={`${fileUploadStyle.d_flex}`}>
            <h4 className="mt-2">Drag and Drop files here</h4>
            <input
              type="file"
              onChange={(e) => handleFileChange(e)}
              name="ufile"
              multiple
              id={id}
              style={{ display: "none" }}
              accept=".png,.jpeg,.pdf,.jpg,.xlsx"
            />
            <label
              htmlFor={id}
              className={`${fileUploadStyle.browserfilebtn} ms-auto`}
            >
              Browse files
            </label>
          </div>
        </div>
        {errorMsg && <p className="text-danger">{errorMsg}</p>}
        <br />
        {files.length === 0 ? (
          <p></p>
        ) : (
          <>
            {files.map((file, indexs) => (
              <div
                className={`row upload_details ${
                  indexs + 1 <= resfiles.length ? "d-none" : ""
                }`}
                key={indexs}
              >
                <div className="col-md-8 col-sm-10">
                  <img className="file_icon" src={uploadIcon} alt="icon" />
                  <div className="file_name">
                    <span>{file.name}</span>
                  </div>
                </div>
                <div className="col-md-2 col-sm-2 text-end pt_6">
                  {show && indexs === uploadNumber && (
                    <div class="progress">
                      <div
                        class="progress-bar"
                        style={{ width: progress.toString() + "%" }}
                      ></div>
                    </div>
                  )}
                </div>
                <div className="col-md-2 col-sm-2 text-end pt_6">
                  <span>{fileSizeBYkb(file.size)} </span>
                  <img
                    className="ml_15 c_pointer"
                    onClick={() => deleteArr(indexs, indexs)}
                    src={crossIcon}
                    alt="icon"
                  />
                </div>
              </div>
            ))}
          </>
        )}
        {resfiles.length === 0 ? (
          <p></p>
        ) : (
          <>
            {resfiles.map((file, index) => (
              <div className="row upload_details">
                <div className="col-md-8 col-sm-8">
                  <img className="file_icon" src={uploadIcon} alt="icon" />
                  <div className="file_name">
                    <span>{file.file_name}</span>
                  </div>
                </div>
                <div className="col-md-2 col-sm-2 text-end pt_6">
                  <div class="">
                    <div class="progress_bar">
                      <img src={Fill} className="me-2" />
                      Upload complete !
                    </div>
                  </div>
                </div>
                <div className="col-md-2 col-sm-2 text-end pt_6">
                  <span>{fileSizeBYkb(file.size)} </span>
                  <img
                    className="ml_15 c_pointer"
                    onClick={() => deleteArr(file.key, index, file.resource_id)}
                    src={crossIcon}
                    alt="icon"
                  />
                </div>
              </div>
            ))}
          </>
        )}

        <DeleteConfirmation
          showModal={displayConfirmationModalDelete}
          confirmModal={submitDelete}
          hideModal={hideConfirmationModal}
          message={""}
          type="task_id"
          id={deleteId}
        />

        {/* <div className="p_25">
          <div className="row text-center">
            <div className="col-sm-12 col-md-12">
              <Button
                onClick={() => hideModel(false)}
                value="Cancel"
                buttonStyle=" btn_outline  mr_20"
              />
              {show ? (
                <button className="btn_fill">Uploading...</button>
              ) : (
                <Button
                  onClick={continueRedirect}
                  value="Upload"
                  buttonStyle="btn_fill"
                />
              )}
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default FileUpload;
