import React from "react";
import "../../../../css/ViewTreatmentForm.css";

function PostOperativeNotes({ item }) {

  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-header">
          <h2>Post Saved Notes</h2>
          <div className="treatment-header-right"></div>
        </div>

        <div className="treatment-content">
          <div className="treatment-row">
            <div className="">
              <label className="text-dark">Operative Notes:</label>
              <pre className="p_description1">{item?.operative_note}</pre>
            </div>
          </div>

          <div className="treatment-row">
            <div className="">
              <label className="text-dark">Implant Information:</label>
              <pre className="p_description1">{item?.prostheses}</pre>
            </div>
          </div>

          <div className="treatment-row">
            <div className="">
              <label className="text-dark">Post Operative Instruction:</label>

              <pre className="p_description1">{item?.instructions}</pre>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostOperativeNotes;
