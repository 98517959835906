import React, { useState, useEffect } from "react";
import {
    Page,
    Text,
    View,
    
    StyleSheet,
    Font,
  } from "@react-pdf/renderer";
import { DateFormate, UserDateFormat } from "../../utilities/DateFormate";
import { UserDateFormatwithTimeZone } from "../../utilities/DateFormate";
import ReactDOMServer from "react-dom/server";

import { returnStrings } from "../../utilities/ComaScript";
import Html from "react-pdf-html";
import { s3UrlLink } from "../../utilities/S3Url";
export default function ResearchDetailsPdf({ Personaldata, data ,login}) {
    // const login = useSelector((state) => state.mainReducer.loginData.arr);
    // const [Personaldata, setPersonaldata] = useState([]);
    const [datas, setData] = useState([]);
    const [sdatas, setsData] = useState([]);
    console.log(data, "indrani")
    useEffect(() => {
        if (Personaldata !== undefined) {
            setData(Personaldata);
        }
    }, [Personaldata]);
    useEffect(() => {
        if (data[0] !== undefined) {
            setsData(data[0]);
        }
    }, [data]);
    console.log(sdatas, "aaaa")

    const styles = StyleSheet.create({
        body: {
            padding: 10,
            backgroundColor: "#F3F5F7",
            fontSize: "1px",
            paddingTop: 10,
            paddingBottom: 150,
            paddingHorizontal: 35,
            fontWeight: 700,
            // fontFamily: "Roboto",
            flexDirection: "column",
        },
        footerFixed: {
            position: "absolute",
            fontSize: 12,

            bottom: 5,
            left: 0,
            right: 0,
            marginTop: 5,
            height: 90,
        },
        pageNumber: {
            position: "absolute",
            fontSize: 10,
            fontWeight: 700,
            // fontFamily: "Roboto",
            bottom: 10,
            left: 0,
            right: 20,
            textAlign: "right",
        },
        header: {
            fontSize: 12,
            marginBottom: 20,
            textAlign: "center",
            color: "grey",
        },
        row: {

            flexDirection: "row",
        },
        head_row: {
            flexDirection: "row",
            display: "flex",
        },
        image: {
            marginTop: 5,
            width: 100,
        },
        txt1: {
            // lineHeight:"10px",
        },
        title_header: {
            paddingTop: 10,
            fontSize: 12,
            fontWeight: 700,
        },
        titles: {
            textAlign: "center",
            fontSize: 16,
            margin: 0,
            paddingTop: 5,
            marginBottom: 5,
            marginTop: 5,
        },
        header_title: {
            textAlign: "right",
            fontSize: 12,
            fontWeight: 400,
            marginTop: 5,
            marginLeft: "auto",
            // fontFamily: "Roboto",
        },
        header_para: {
            textAlign: "right",
            fontSize: 9,
            fontWeight: 400,
            marginLeft: "auto",
            padding: 0,
            // fontFamily: "Roboto",
        },
        footer_para: {
            position: "absolute",
            left: 20,
            top: "18",
            fontSize: 11,
            fontWeight: 400,
            padding: 0,
            // fontFamily: "Roboto",
        },
        footer_para_mid: {
            textAlign: "center",
            fontSize: 11,
            fontWeight: 400,
            marginTop: 18,
            padding: 0,
            // fontFamily: "Roboto",
        },
        footer_para_last: {
            position: "absolute",
            right: "80",
            top: "18",
            fontSize: 11,
            fontWeight: 400,
            padding: 0,
            // fontFamily: "Roboto",
        },
        panelbox: {
            WebkitPrintColorAdjust: "exact",
            backgroundColor: "#fff",
            borderRadius: 20,
            padding: 8,
            paddingBottom: 8,
            marginBottom: 3,
            marginTop: 9,
        },
        panelheading: {
            fontSize: 10,
            fontWeight: "bold",
            marginBottom: 7,
            marginTop: 7,
            marginLeft: 3,
        },
        panelbdy_txt: {
            fontSize: 10,
            marginBottom: 4,
            marginTop: 4,
            fontWeight: "800",
            wordWrap: "break-word",
            wordBreak: "keep-all",
            textTransform: "capitalize",
            marginLeft: 3,
        },
        breakBefore: {
            pageBreakBefore: "always",
        },
        hr: {
            margin: "20px -10px",
            marginTop: 0,
            marginBottom: 2,
            background: "#E5E5E5",
            color: "#E5E5E5",
            border: "0px solid #E5E5E5",
            height: 1,
        },
        p: {
            margin: 0,
            padding: 0,
            fontFamily: "Roboto",
        },
        b: {
            fontFamily: "Roboto",
            fontWeight: "bold",
        },
        strong: {
            fontFamily: "Roboto",
            fontWeight: "bold",
        },
        page: {
            flexDirection: "column",
            backgroundColor: "#E4E4E4",
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
        },
        text: {
            color: "red",
            display: "flex",
            flexDirection: "column",
            width: "100%",
        },
    });
    const stylesheet = {
        p: {
            marginBottom: 5,
            fontSize: 12,
            fontFamily: "Roboto",
            fontWeight: "light",
        },
        b: {
            fontFamily: "Roboto",
            fontWeight: "bold",
        },
        ".cont": {
            color: "#000",
        },
    };

    Font.register({
        family: "Roboto",
        fonts: [
          {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
            fontWeight: "light",
          },
          {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
            fontWeight: "regular",
          },
          {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
            fontWeight: "medium",
          },
          {
            src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
            fontWeight: "bold",
          },
        ],
      });
    const header1 = (
        <div className="page" style={{ pageBreakAfter: "always", marginBottom: 0 }}>
          <div style={{flexDirection:"column"}}>
            <div style={styles.image}>
            {login.template_note.header ?(
                <img
                alt=""
                style={{maxWidth:"600px",width:"500px"}}
                  src={s3UrlLink(login.template_note.header)
                  }
                />
            ):(
                <img
                alt=""
                src={
                  "https://images.squarespace-cdn.com/content/v1/600a2734b232b75f58bb48f9/1611709064100-FZNI09YCZABNS97CXS1T/Logos-concepts-Updates-2-02.png?format=1500w"
                }
              />
            )}
              
            </div>
           
          </div>
        
          <div className="panel-heading">
            <div style={styles.titles}>Treatment Note Results</div>
          </div>
          <div style={{
            textAlign: "right",
            fontSize: 10,
            fontWeight: 400,
            marginTop: 5,
            // fontFamily: "Roboto",
          }} >
            {Personaldata.ig_fname} {Personaldata.ig_lname}
            <br />
            {new Date(Personaldata.ig_dob).toLocaleDateString()}
            <br />
            {Personaldata.ig_gender === "1"
              ? "Male"
              : Personaldata.ig_gender === "2"
                ? "Female"
                : "Do not want to disclose"}
            <br />
            {Personaldata.ig_phone}
            <br />
            {Personaldata.ig_medicare_no}
          </div>
        </div>
      );
    

      const footer1 = (
        <div className="page" style={{ pageBreakAfter: "always", marginBottom: 0 }}>
            <div className="panel-heading">
                <div style={styles.footer_para}>
                    {login.treatAdd.address} <br />
                    {login.treatAdd.suburb}
                    <br />
                    {login.treatAdd.postCode} <br />
                    {/* Private Hospital, QLD, 4120 Australia */}
                </div>
                <div style={styles.footer_para_mid}>
                    {login.official_email}
                    <br />
                    P: {login.country_code}{" "}{login.ing_mobile}
                    <br />
                    {/* F: +61 7 3445 1592 */}
                </div>
                <div style={styles.footer_para_last}>
                    <img
                        style={{ width: "70px", opacity: 0.1 }}
                        src={
                            "https://images.squarespace-cdn.com/content/v1/600a2734b232b75f58bb48f9/1611709064100-FZNI09YCZABNS97CXS1T/Logos-concepts-Updates-2-02.png?format=1500w"
                        }
                        alt=""
                    />
                </div>
            </div>
        </div>
    );


    const personal_details = (
        <>
            <div className="page"
                style={{ pageBreakAfter: "always", marginBottom: 0 }}
            >
                <div className="panel box" style={styles.panelbox}>
                    <div className="panel-heading" style={styles.panelheading}>
                    <b> Patient Details</b>
                    </div>
                    <div style={styles.hr} />
                    <div className="panel-body" style={{ padding: "0px 15px" }}>
                        <table
                            className=""
                            width="100%"
                            border={0}
                            cellPadding={6}
                            cellSpacing={0}
                        >
                            <tbody>
                                <tr>
                                    <td >
                                        <div  style={styles.panelbdy_txt}>
                                          <b>  <strong >Name: </strong></b>
                                            {datas.ig_fname} {datas.ig_lname}
                                        </div>
                                    </td>
                                    <td>
                                        <div style={styles.panelbdy_txt}>
                                        <b><strong>Date Of Birth: </strong></b>
                                            {new Date(datas.ig_dob).toLocaleDateString()}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div style={styles.panelbdy_txt}>
                                        <b><strong>Sex: </strong></b>
                                            {datas.ig_gender === "1" ? "Male" : datas.ig_gender === "2" ? "Female" : "Do not want to disclose"}
                                        </div>
                                    </td>
                                    <td>
                                        <div style={styles.panelbdy_txt}>
                                        <b> <strong>Phone: </strong></b>
                                            {datas.ig_phone}

                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div style={styles.panelbdy_txt}>
                                        <b>  <strong>Account Type: </strong></b>
                                            {datas.account_type}
                                        </div>
                                    </td>
                                    <td>
                                        <div style={styles.panelbdy_txt}>
                                        <b> <strong>Medicare Number: </strong></b>
                                            {datas.ig_medicare_no}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>



                <div>
                    <div className="panel box" style={styles.panelbox}>

                        <div className="panel-heading" style={styles.panelheading}>
                            <b>Treatment Details</b>
                        </div>
                        <div style={styles.hr} />
                        {/* <div className="panel-body" style={{ padding: "0px 15px" }}>

                        <div className="treatment-row">
                            <div className="treatment-col text-capitalize">
                            <div className="panel-heading" style={styles.panelheading}>
                            <b>  Surgeon</b>
                            </div>
                            <div style={styles.panelbdy_txt}>
                                {sdatas.surgeon_name}
                            </div>
                            </div>
                            <div className="treatment-col">
                            <div className="panel-heading" style={styles.panelheading}>
                            <b>  Date Performed</b>
                            </div>
                            <div style={styles.panelbdy_txt}>
                                
                                {UserDateFormatwithTimeZone(sdatas.surgery_date)}
                            </div>
                            </div>
                            </div>

                            <div className="panel-heading" style={styles.panelheading}>
                            <b>Specialty</b>
                            </div>
                            <div style={styles.panelbdy_txt}>
                                {sdatas.treatement_note === "4" ? "Shoulder" : sdatas.treatement_note === "6" ? "Hip" : sdatas.treatement_note === "5" ? "Knee" : sdatas.treatement_note === "22" ? "Elbow" : sdatas.treatement_note === "21" ? "Foot and ankle" : sdatas.treatement_note === "23" ? "Hand and wrist" : sdatas.treatement_note === "33" ? "General" : ""}
                            </div>
                            <div className="panel-heading" style={styles.panelheading}>
                            <b>Location</b>
                            </div>
                            <div style={styles.panelbdy_txt}>
                            {sdatas.location}
                            </div>
                            <div className="panel-heading" style={styles.panelheading}>
                            <b> Indication For Treatment</b>
                            </div>
                            <div style={styles.panelbdy_txt}>
                                {sdatas.operation_indication}
                            </div>

                            <div className="panel-heading" style={styles.panelheading}>
                            <b> Treatment Performed</b>
                            </div>
                            <div style={styles.panelbdy_txt}>
                                {sdatas.operation_performed}
                            </div>
                            {sdatas.is_op_note_general !== "33" && (
                                <>
                            <div className="panel-heading" style={styles.panelheading}>
                            <b>Primary Diagnosis</b>
                            </div>
                            <div style={styles.panelbdy_txt}>
                            
                            {sdatas.primary}
                            </div>
                            </>
                            )}

                            {sdatas.is_op_note_general !== "33" && (
                                <>
                                    <div className="panel-heading" style={styles.panelheading}>
                                    <b>    Treatement Side</b>
                                    </div>
                                    <div style={styles.panelbdy_txt}>
                                        {sdatas.operativeSide === "1" ? "Right" : "Left"}
                                    </div>
                                </>
                            )}

                            
                        </div> */}


                        <div className="panel-body" style={{ padding: "0px 15px" }}>


                            <table
                                className=""
                                width="100%"
                                border={0}
                                cellPadding={6}
                                cellSpacing={0}
                            >
                                <tbody>
                                    <tr>
                                        <td >
                                            <div style={styles.panelbdy_txt}>
                                            <b> <strong>Surgeon: </strong></b>
                                                {sdatas.surgeon_name}
                                            </div>
                                        </td>
                                        <td>
                                            <div style={styles.panelbdy_txt}>
                                            <b> <strong>Date Performed: </strong></b>
                                                {UserDateFormatwithTimeZone(sdatas.surgery_date)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                        <div style={styles.panelbdy_txt}>
                                        <b> <strong>Specialty: </strong></b>
                                                {sdatas.is_op_note_general === "4" ? "Shoulder" : sdatas.is_op_note_general === "6" ? "Hip" : sdatas.is_op_note_general === "5" ? "Knee" : sdatas.is_op_note_general === "22" ? "Elbow" : sdatas.is_op_note_general === "21" ? "Foot and ankle" : sdatas.is_op_note_general === "23" ? "Hand and wrist" : sdatas.is_op_note_general === "33" ? "General" : ""}
                                            </div>
                                           
                                        </td>
                                        <td>
                                            

                                            <div style={styles.panelbdy_txt}>
                                            <b> <strong>Location: </strong></b>
                                                {sdatas.location}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                        <div style={styles.panelbdy_txt}>
                                        <b> <strong>Indication For Treatment: </strong></b>
                                                {sdatas.operation_indication}
                                            </div>
                                        </td>
                                        <td>

                                        <div style={styles.panelbdy_txt}>
                                        <b> <strong>Treatment Performed: </strong></b>
                                                {sdatas.operation_performed}
                                            </div>
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td >
                                            <div style={styles.panelbdy_txt}>
                                            <b>  <strong>Primary Diagnosis: </strong></b>
                                                {sdatas.primary}
                                            </div>
                                        </td>
                                        <td >

                                        {sdatas.is_op_note_general !== "33" && (
                                                <div style={styles.panelbdy_txt}>
                                                  <b>  <strong> Treatment Side: </strong></b>
                                                    {sdatas.operativeSide === "1" ? "Right" : "Left"}
                                                </div>
                                            )}
                                            {/* <div style={styles.panelbdy_txt}>
                                                <strong>Description: </strong>
                                                {sdatas.Other}
                                            </div> */}
                                        </td>
                                    
                                    </tr>
                                </tbody>
                            </table>



                            
                        </div>
                    </div>
                </div>
            </div>

        </>
    );


    const header11 = ReactDOMServer.renderToStaticMarkup(header1);
    const footer11 = ReactDOMServer.renderToStaticMarkup(footer1);
    const personal_dettailshtml =
        ReactDOMServer.renderToStaticMarkup(personal_details);
    return (
        <Page style={styles.body}>
            <View fixed>
                <Html>{header11}</Html>
            </View>
            <View style={{ marginTop: 10 }}>
                <Html stylesheet={stylesheet}>{personal_dettailshtml}</Html>
            </View>
            <View style={styles.footerFixed} fixed>
                <Html style={styles.title}>{footer11}</Html>

            </View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                    `Page  ${pageNumber} of ${totalPages}`
                }
                fixed
            />
        </Page>
    )
}