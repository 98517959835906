export const DocType = (type, fileName) => {
  if (type === "mp4" || type === "mov" || type === "hevc") {
    return "Video";
  } else if (
    type === "png" ||
    type === "jpg" ||
    type === "jpeg" ||
    type === "heif" ||
    type === "heic"
  ) {
    return "Image";
  } else {
    return fileName.replace("_", " ");
  }
};

export const WordCutter = (text, maxWords) => {
  let content = text;
  let contentLength = content?.length;
  if (maxWords * 5 < contentLength) {
    let count = content?.slice(0, maxWords * 5) + "...";
    return count;
  } else {
    return content;
  }

  // const words = text?.split(" ");
  // const truncatedWords = words?.slice(0, maxWords);
  // const truncatedText = truncatedWords?.join(" ");
  // return (
  //   <span>
  //     {truncatedText}
  //     {words?.length > maxWords ? "..." : " "}
  //   </span>
  // );
};

export const Charactercount = (e, numview) => {
  let content = e;
  let contentLength = content?.length;
  if (numview < contentLength) {
    let count = content?.slice(0, numview) + "...";
    return count;
  } else {
    return content;
  }
};

export const ScoreColor = (value, type) => {
  if (type) {
    if (value >= 1 && value <= 10) {
      return ["#42E0CE", "#48DEFF"];
    } else if (value >= 11 && value <= 20) {
      return ["#7AE292", "#42E0CE"];
    } else if (value >= 21 && value <= 30) {
      return ["#A4E363", "#7AE292"];
    } else if (value >= 31 && value <= 40) {
      return ["#C8E43C", "#A0E368"];
    } else if (value >= 41 && value <= 50) {
      return ["#F9E607", "#C8E43C"];
    } else if (value >= 51 && value <= 60) {
      return ["#FFD001", "#F9E607"];
    } else if (value >= 61 && value <= 70) {
      return ["#FFA501", "#FFD001"];
    } else if (value >= 71 && value <= 80) {
      return ["#FF7301", "#FFA501"];
    } else if (value >= 81 && value <= 90) {
      return ["#FF4601", "#FF7301"];
    } else if (value >= 91 && value <= 100) {
      return ["#FF0301", "#FF4601"];
    } else {
      return ["#42E0CE", "#48DEFF"];
    }
  } else {
    if (value >= 1 && value <= 10) {
      return ["#FF0301", "#FF4601"];
    } else if (value >= 11 && value <= 20) {
      return ["#FF4601", "#FF7301"];
    } else if (value >= 21 && value <= 30) {
      return ["#FF7301", "#FFA501"];
    } else if (value >= 31 && value <= 40) {
      return ["#FFA501", "#FFD001"];
    } else if (value >= 41 && value <= 50) {
      return ["#FFD001", "#F9E607"];
    } else if (value >= 51 && value <= 60) {
      return ["#F9E607", "#C8E43C"];
    } else if (value >= 61 && value <= 70) {
      return ["#C8E43C", "#A0E368"];
    } else if (value >= 71 && value <= 80) {
      return ["#A4E363", "#7AE292"];
    } else if (value >= 81 && value <= 90) {
      return ["#7AE292", "#42E0CE"];
    } else if (value >= 91 && value <= 100) {
      return ["#42E0CE", "#48DEFF"];
    } else {
      return ["#FF0301", "#FF4601"];
    }
  }
};

export const vasColor = (value, type) => {
  if (type) {
    if (value >= 0 && value <= 1) {
      return ["#FF0301", "#FF4601"];
    } else if (value >= 1 && value <= 2) {
      return ["#FF4601", "#FF7301"];
    } else if (value >= 2 && value <= 3) {
      return ["#FF7301", "#FFA501"];
    } else if (value >= 3 && value <= 4) {
      return ["#FFA501", "#FFD001"];
    } else if (value >= 4 && value <= 5) {
      return ["#FFD001", "#F9E607"];
    } else if (value >= 5 && value <= 6) {
      return ["#F9E607", "#C8E43C"];
    } else if (value >= 6 && value <= 7) {
      return ["#C8E43C", "#A0E368"];
    } else if (value >= 7 && value <= 8) {
      return ["#A4E363", "#7AE292"];
    } else if (value >= 8 && value <= 9) {
      return ["#7AE292", "#42E0CE"];
    } else if (value >= 9 && value <= 10) {
      return ["#42E0CE", "#48DEFF"];
    } else {
      return ["#FF0301", "#FF4601"];
    }
  } else {
    if (value >= 0 && value <= 1) {
      return ["#42E0CE", "#48DEFF"];
    } else if (value >= 1 && value <= 2) {
      return ["#7AE292", "#42E0CE"];
    } else if (value >= 2 && value <= 3) {
      return ["#A4E363", "#7AE292"];
    } else if (value >= 3 && value <= 4) {
      return ["#C8E43C", "#A0E368"];
    } else if (value >= 4 && value <= 5) {
      return ["#F9E607", "#C8E43C"];
    } else if (value >= 5 && value <= 6) {
      return ["#FFD001", "#F9E607"];
    } else if (value >= 6 && value <= 7) {
      return ["#FFA501", "#FFD001"];
    } else if (value >= 7 && value <= 8) {
      return ["#FF7301", "#FFA501"];
    } else if (value >= 8 && value <= 9) {
      return ["#FF4601", "#FF7301"];
    } else if (value >= 9 && value <= 10) {
      return ["#FF0301", "#FF4601"];
    } else {
      return ["#42E0CE", "#48DEFF"];
    }
  }
};
export const FiveColor = (value, type) => {
  console.log(value, "===========10");
  if (!type) {
    if (value >= 0 && value <= 1) {
      return ["#FF0301", "#FF4601"];
    } else if (value >= 1 && value <= 2) {
      return ["#FF7301", "#FFA501"];
    } else if (value >= 2 && value <= 3) {
      return ["#F9E607", "#C8E43C"];
    } else if (value >= 3 && value <= 4) {
      return ["#A4E363", "#7AE292"];
    } else if (value >= 4 && value <= 5) {
      return ["#42E0CE", "#48DEFF"];
    } else {
      return ["#FF0301", "#FF4601"];
    }
  } else {
    if (value >= 0 && value <= 1) {
      return ["#42E0CE", "#48DEFF"];
    } else if (value >= 1 && value <= 2) {
      return ["#A4E363", "#7AE292"];
    } else if (value >= 2 && value <= 3) {
      return ["#F9E607", "#C8E43C"];
    } else if (value >= 3 && value <= 4) {
      return ["#FF7301", "#FFA501"];
    } else if (value >= 4 && value <= 5) {
      return ["#FF0301", "#FF4601"];
    } else {
      return ["#42E0CE", "#48DEFF"];
    }
  }
};

export const CircleColor = (total, score, res, min_score) => {
  if (res) {
    return min_score + (total - score);
  } else {
    return score;
  }
};
export const Circle5 = (total, score, res, min_score) => {
  // console.log(res, score, total , "hghgghghggh");

  if (res) {
    if (score === 5) {
      return 0;
    } else {
      return min_score + (total - score);
    }
  } else {
    return score;
  }
};
