import React, { useState, useEffect } from "react";
import { Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { DateFormate, UserDateFormat } from "../../utilities/DateFormate";

import ReactDOMServer from "react-dom/server";
import { returnStrings } from "../../utilities/ComaScript";
import Html from "react-pdf-html";
import { s3UrlLink } from "../../utilities/S3Url";
export default function PersonalSurgeryPdf({
  Personaldata,
  data,
  login,
  diagnosis,
  sergery,
  headerinage,
}) {
  // const login = useSelector((state) => state.mainReducer.loginData.arr);
  // const [datas, setData] = useState([]);
  // const [sdatas, setsData] = useState([]);

  // useEffect(() => {
  //   if (Personaldata !== undefined) {
  //     setData(Personaldata);
  //   }
  // }, [Personaldata]);
  // useEffect(() => {
  //   if (data !== undefined) {
  //     setsData(data);
  //   }
  // }, [data]);
  let sdatas = data;
  const styles = StyleSheet.create({
    body: {
      padding: 10,
      backgroundColor: "#F3F5F7",
      fontSize: "1px",
      paddingTop: 10,
      paddingBottom: 150,
      paddingHorizontal: 35,
      fontWeight: 700,
      fontFamily: "Roboto",
      flexDirection: "column",
    },
    footerFixed: {
      position: "absolute",
      fontSize: 12,

      bottom: 5,
      left: 0,
      right: 0,
      marginTop: 5,
      height: 90,
    },
    pageNumber: {
      position: "absolute",
      fontSize: 10,
      fontWeight: 700,
      fontFamily: "Roboto",
      bottom: 10,
      left: 0,
      right: 20,
      textAlign: "right",
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },
    row: {
      flexDirection: "row",
    },
    head_row: {
      flexDirection: "row",
      display: "flex",
    },
    image: {
      marginTop: 5,
      width: 100,
    },
    txt1: {
      // lineHeight:"10px",
    },
    title_header: {
      paddingTop: 10,
      fontSize: 12,
      fontWeight: 700,
    },
    titles: {
      textAlign: "center",
      fontSize: 16,
      margin: 0,
      paddingTop: 5,
      marginBottom: 5,
      marginTop: 5,
    },
    header_title: {
      textAlign: "right",
      fontSize: 12,
      fontWeight: 400,
      marginTop: 5,
      marginLeft: "auto",
      fontFamily: "Roboto",
    },
    header_para: {
      textAlign: "right",
      fontSize: 9,
      fontWeight: 400,
      marginLeft: "auto",
      padding: 0,
      fontFamily: "Roboto",
    },
    footer_para: {
      position: "absolute",
      left: 20,
      top: "18",
      fontSize: 11,
      fontWeight: 400,
      padding: 0,
      fontFamily: "Roboto",
    },
    footer_para_mid: {
      textAlign: "center",
      fontSize: 11,
      fontWeight: 400,
      marginTop: 18,
      padding: 0,
      fontFamily: "Roboto",
    },
    footer_para_last: {
      position: "absolute",
      right: "80",
      top: "18",
      fontSize: 11,
      fontWeight: 400,
      padding: 0,
      fontFamily: "Roboto",
    },
    panelbox: {
      WebkitPrintColorAdjust: "exact",
      backgroundColor: "#fff",
      borderRadius: 20,
      padding: 8,
      paddingBottom: 8,
      marginBottom: 3,
      marginTop: 9,
    },
    panelheading: {
      fontSize: 10,
      fontWeight: "bold",
      marginBottom: 7,
      marginTop: 7,
      marginLeft: 3,
    },
    panelbdy_txt: {
      fontSize: 10,
      marginBottom: 4,
      marginTop: 4,
      fontWeight: "400",
      wordWrap: "break-word",
      wordBreak: "keep-all",
      textTransform: "capitalize",
      marginLeft: 3,
    },
    breakBefore: {
      pageBreakBefore: "always",
    },
    hr: {
      margin: "20px -10px",
      marginTop: 0,
      marginBottom: 2,
      background: "#E5E5E5",
      color: "#E5E5E5",
      border: "0px solid #E5E5E5",
      height: 1,
    },
    p: {
      margin: 0,
      padding: 0,
      fontFamily: "Roboto",
    },
    b: {
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
    strong: {
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
    page: {
      flexDirection: "column",
      backgroundColor: "#E4E4E4",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    text: {
      color: "red",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
  });
  const stylesheet = {
    p: {
      marginBottom: 5,
      fontSize: 12,
      fontFamily: "Roboto",
      fontWeight: "light",
    },
    b: {
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
    ".cont": {
      color: "#000",
    },
  };
  console.log(sdatas.surgeon_name, "sdatas");
  console.log(Personaldata, "Personaldata");
  console.log(data, " , data");
  const personal_details = (
    <>
      <div className="page">
        <div className="panel box" style={styles.panelbox}>
          <div className="panel-heading" style={styles.panelheading}>
            Patient Details
          </div>
          <div style={styles.hr} />
          <div className="panel-body" style={{ padding: "0px 15px" }}>
            <table
              className=""
              width="100%"
              border={0}
              cellPadding={6}
              cellSpacing={0}
            >
              <tbody>
                <tr>
                  <td>
                    <div style={styles.panelbdy_txt}>
                      <strong>Name: </strong>
                      {Personaldata.ig_fname} {Personaldata.ig_lname}
                    </div>
                  </td>
                  <td>
                    <div style={styles.panelbdy_txt}>
                      <strong>Date Of Birth: </strong>
                      {new Date(Personaldata.ig_dob).toLocaleDateString()}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={styles.panelbdy_txt}>
                      <strong>Sex: </strong>
                      {Personaldata.ig_gender === "1"
                        ? "Male"
                        : Personaldata.ig_gender === "2"
                        ? "Female"
                        : "Do not want to disclose"}
                    </div>
                  </td>
                  <td>
                    <div style={styles.panelbdy_txt}>
                      <strong>Phone: </strong>
                      {Personaldata.ig_phone}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={styles.panelbdy_txt}>
                      <strong>Account Type: </strong>
                      {Personaldata.account_type}
                    </div>
                  </td>
                  <td>
                    <div style={styles.panelbdy_txt}>
                      <strong>Medicare Number: </strong>
                      {Personaldata.ig_medicare_no}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="panel box" style={styles.panelbox}>
          <div className="panel-heading" style={styles.panelheading}>
            Surgery Details
          </div>
          <div style={styles.hr} />

          <div className="panel-body" style={{ padding: "0px 15px" }}>
            <table
              className=""
              width="100%"
              border={0}
              cellPadding={6}
              cellSpacing={0}
            >
              <tbody>
                <tr>
                  <td>
                    <div style={styles.panelbdy_txt}>
                      <strong>Surgeon: </strong>

                      {Array.isArray(sdatas.surgeon_name)
                        ? sdatas.surgeon_name.join(" , ")
                        : ""}
                    </div>
                  </td>
                  <td>
                    <div style={styles.panelbdy_txt}>
                      <strong>Assistant Surgeon: </strong>

                      {Array.isArray(sdatas.asst_surgeon_name)
                        ? sdatas.asst_surgeon_name.join(" , ")
                        : ""}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={styles.panelbdy_txt}>
                      <strong>Anaesthetist: </strong>

                      {Array.isArray(sdatas.anaesthetist)
                        ? sdatas.anaesthetist.join(" , ")
                        : ""}
                    </div>
                  </td>
                  <td>
                    <div style={styles.panelbdy_txt}>
                      <strong>Location: </strong>
                      {sdatas.location}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={styles.panelbdy_txt}>
                      <strong>Start Date: </strong>
                      {DateFormate(sdatas.surgery_date)}
                    </div>
                  </td>
                  <td>
                    <div style={styles.panelbdy_txt}>
                      <strong>End Date: </strong>
                      {DateFormate(sdatas.surgery_end_date)}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={styles.panelbdy_txt}>
                      <strong>Time Started: </strong>
                      {sdatas.surgery_start_time}
                    </div>
                  </td>
                  <td>
                    <div style={styles.panelbdy_txt}>
                      <strong>Time Finished: </strong>
                      {sdatas.surgery_end_time}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} valign="top">
                    <div style={styles.panelbdy_txt}>
                      <strong>Indication For Operation: </strong>
                      {sdatas.operation_indication}
                    </div>
                  </td>

                  <td colSpan={2} valign="top">
                    <div style={styles.panelbdy_txt}>
                      <strong>Operation Performed: </strong>
                      {sdatas.operation_performed}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} valign="top">
                    <div style={styles.panelbdy_txt}>
                      <strong>Specialty: </strong>
                      {sdatas.is_op_note_general === "4"
                        ? "Shoulder"
                        : sdatas.is_op_note_general === "6"
                        ? "Hip"
                        : sdatas.is_op_note_general === "5"
                        ? "Knee"
                        : sdatas.is_op_note_general === "22"
                        ? "Elbow"
                        : sdatas.is_op_note_general === "21"
                        ? "Foot and ankle"
                        : sdatas.is_op_note_general === "23"
                        ? "Hand and wrist"
                        : sdatas.is_op_note_general === "33"
                        ? "General"
                        : ""}
                    </div>
                  </td>

                  <td colSpan={2} valign="top">
                    <div style={styles.panelbdy_txt}>
                      <strong>Treatment Side: </strong>
                      {sdatas.operativeSide === "1" ? "Right" : "Left"}
                    </div>
                  </td>
                </tr>
                <tr>
                  {sdatas.is_op_note_general === "6" && (
                    <td colSpan={2} valign="top">
                      <div style={styles.panelbdy_txt}>
                        <strong>Surgery Category: </strong>
                        {returnStrings([
                          diagnosis[0] && "Arthroscopy",
                          diagnosis[1] && "Arthroplasty",
                          diagnosis[2] && "Fracture Surgery",
                          diagnosis[3] && "Other hip preservation surgery",
                          diagnosis[4] && "Others",
                        ])}
                      </div>
                    </td>
                  )}

                  {sdatas.is_op_note_general === "5" && (
                    <td colSpan={2} valign="top">
                      <div style={styles.panelbdy_txt}>
                        <strong>Primary Diagnosis: </strong>
                        {returnStrings([
                          diagnosis[0] && "Arthritis",
                          diagnosis[1] && "Instability",
                          diagnosis[2] && "Patellar instability",
                          diagnosis[3] && "Meniscal injury",
                          diagnosis[4] && "Fracture",
                          diagnosis[5] && "Septic Knee",
                          diagnosis[6] && "Osteonecrosis",
                          diagnosis[7] && "Revision Arthroplasty",
                          diagnosis[8] && "Chondral Injury",
                          diagnosis[9] && "Others",
                        ])}
                      </div>
                    </td>
                  )}

                  {sdatas.is_op_note_general === "22" && (
                    <td colSpan={2} valign="top">
                      <div style={styles.panelbdy_txt}>
                        <strong>Primary Diagnosis: </strong>
                        {returnStrings([
                          diagnosis[0] && "Fracture",
                          diagnosis[1] && "Instability",
                          diagnosis[2] && "Arthritis",
                          diagnosis[3] && "Failed arthroplasty",
                          diagnosis[4] && "Ligament/Tendon injury",
                          diagnosis[5] && "Nerve entrapment",
                          diagnosis[6] && "Others",
                        ])}
                      </div>
                    </td>
                  )}

                  {sdatas.is_op_note_general === "21" && (
                    <td colSpan={2} valign="top">
                      <div style={styles.panelbdy_txt}>
                        <strong>Primary Diagnosis: </strong>
                        {returnStrings([
                          diagnosis[0] && "Fracture / Dislocation",
                          diagnosis[1] && "Arthritis",
                          diagnosis[2] && "Avascular Necrosis",
                          diagnosis[3] && "Charcot foot",
                          diagnosis[4] && "Amputation",
                          diagnosis[5] && "Arthroscopy",
                          diagnosis[6] && "Instability",
                          diagnosis[7] && "Deformity",
                          diagnosis[8] && "Achilles tendon pathology",
                          diagnosis[9] && "Others",
                        ])}
                      </div>
                    </td>
                  )}
                  {sdatas.is_op_note_general === "23" && (
                    <td colSpan={2} valign="top">
                      <div style={styles.panelbdy_txt}>
                        <strong>Primary Diagnosis: </strong>
                        {returnStrings([
                          diagnosis[0] && "Fracture / Dislocation",
                          diagnosis[1] && "Instability and ligament injury",
                          diagnosis[2] && "Tendon injury",
                          diagnosis[3] && "Compression Neuropathy",
                          diagnosis[4] && "Nerve injury",
                          diagnosis[5] && "Arthritis",
                          diagnosis[6] && "Others",
                        ])}
                      </div>
                    </td>
                  )}

                  <td colSpan={2} valign="top">
                    {/* <div style={styles.panelbdy_txt}>
                      <strong>Select previous visits: </strong>
                      {sdatas.form_id === 0 ? (
                        "N/A"
                      ) : (
                        <>
                          {`${UserDateFormat(
                            sdatas.created_at,
                            login.ing_date_formate,
                            login.ing_timezone
                          )}-${sdatas.is_op_note_general === "4"
                              ? "Shoulder"
                              : sdatas.is_op_note_general === "6"
                                ? "Hip"
                                : sdatas.is_op_note_general === "5"
                                  ? "Knee"
                                  : sdatas.is_op_note_general === "22"
                                    ? "Elbow"
                                    : sdatas.is_op_note_general === "21"
                                      ? "Foot and ankle"
                                      : sdatas.is_op_note_general === "23"
                                        ? "Hand and wrist"
                                        : sdatas.is_op_note_general === "33"
                                          ? "General"
                                          : ""
                            }-${sdatas.operativeSide === "1" ? "Right" : "Left"}`}
                        </>
                      )}
                    </div> */}
                  </td>
                </tr>
                {/* ------others should be included here */}
                <tr>
                  {diagnosis[4] && (
                    <td colSpan={2} valign="top">
                      <div style={styles.panelbdy_txt}>
                        <strong>Others: </strong>
                        {data.HipOthers_Inp}
                      </div>
                    </td>
                  )}
                  {diagnosis[6] && (
                    <td colSpan={2} valign="top">
                      <div style={styles.panelbdy_txt}>
                        <strong>Others: </strong>
                        {data?.Elbow_othersInp} {data?.Hand_OthersInp}
                      </div>
                    </td>
                  )}
                  {diagnosis[9] && (
                    <td colSpan={2} valign="top">
                      <div style={styles.panelbdy_txt}>
                        <strong>Others: </strong>
                        {data.Knee_othersInp}  {data?.others_input}
                      </div>
                    </td>
                  )}
                  {/* {diagnosis[9] && <View style={{ marginTop: 10 }} break>
                <Html stylesheet={stylesheet}>{Noteshtml9}</Html>
            </View>} */}
                </tr>

                <tr>
                  {sdatas.selectOpnote === "22" && (
                    <td colSpan={2} valign="top">
                      <div style={styles.panelbdy_txt}>
                        <strong>Diagnosis: </strong>
                        {sdatas.KneeDiagnosis}
                      </div>
                    </td>
                  )}
                  {sdatas.selectOpnote === "23" && (
                    <>
                      <td colSpan={2} valign="top">
                        <div style={styles.panelbdy_txt}>
                          <strong>Others: </strong>
                          {sdatas.HandSpecify}
                        </div>
                      </td>
                    </>
                  )}

                  {(sdatas.is_op_note_general === "23" || sdatas.is_op_note_general === "21" || sdatas.is_op_note_general === "22") &&(
                    <td colSpan={2} valign="top">
                      <div style={styles.panelbdy_txt}>
                        <strong>Item number: </strong>
                        {sdatas.FootItem}
                        {/* {sdatas.KneeItem} */}
                      </div>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );

  const header1 = (
    <div className="page" style={{ pageBreakAfter: "always", marginBottom: 0 }}>
      <div style={{ flexDirection: "column" }}>
        <div style={styles.image}>
          {login.template_note.header ? (
            <img
              alt=""
              style={{ maxWidth: "600px", width: "500px" }}
              src={s3UrlLink(login.template_note.header)}
            />
          ) : (
            <img
              alt=""
              src={
                "https://images.squarespace-cdn.com/content/v1/600a2734b232b75f58bb48f9/1611709064100-FZNI09YCZABNS97CXS1T/Logos-concepts-Updates-2-02.png?format=1500w"
              }
            />
          )}
        </div>
      </div>

      <div className="panel-heading">
        <div style={styles.titles}>Treatment Note Results</div>
      </div>
      <div
        style={{
          textAlign: "right",
          fontSize: 10,
          fontWeight: 400,
          marginTop: 5,
          // fontFamily: "Roboto",
        }}
      >
        {Personaldata.ig_fname} {Personaldata.ig_lname}
        <br />
        {new Date(Personaldata.ig_dob).toLocaleDateString()}
        <br />
        {Personaldata.ig_gender === "1"
          ? "Male"
          : Personaldata.ig_gender === "2"
          ? "Female"
          : "Do not want to disclose"}
        <br />
        {Personaldata.ig_phone}
        <br />
        {Personaldata.ig_medicare_no}
      </div>
    </div>
  );

  const footer1 = (
    <div className="page" style={{ pageBreakAfter: "always", marginBottom: 0 }}>
      <div className="panel-heading">
        <div style={styles.footer_para}>
          {login.treatAdd.address} <br />
          {login.treatAdd.suburb}
          <br />
          {login.treatAdd.postCode} <br />
          {/* Private Hospital, QLD, 4120 Australia */}
        </div>
        <div style={styles.footer_para_mid}>
          {login.official_email}
          <br />
          P: {login.country_code} {login.ing_mobile}
          <br />
          {/* F: +61 7 3445 1592 */}
        </div>
        <div style={styles.footer_para_last}>
          <img
            style={{ width: "70px", opacity: 0.1 }}
            src={
              "https://images.squarespace-cdn.com/content/v1/600a2734b232b75f58bb48f9/1611709064100-FZNI09YCZABNS97CXS1T/Logos-concepts-Updates-2-02.png?format=1500w"
            }
          />
        </div>
      </div>
    </div>
  );
  const personal_dettailshtml =
    ReactDOMServer.renderToStaticMarkup(personal_details);

  const header11 = ReactDOMServer.renderToStaticMarkup(header1);
  const footer11 = ReactDOMServer.renderToStaticMarkup(footer1);
  return (
    <Page style={styles.body}>
      <View fixed>
        <Html>{header11}</Html>
      </View>

      <View style={{ marginTop: 10 }}>
        <Html stylesheet={stylesheet}>{personal_dettailshtml}</Html>
      </View>

      <View style={styles.footerFixed} fixed>
        <Html style={styles.title}>{footer11}</Html>
      </View>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) =>
          `Page  ${pageNumber} of ${totalPages}`
        }
        fixed
      />
    </Page>
  );
}
