import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";
import profileStyle from "./Profile.module.css";
import arrowIcon from "../../../../images/arrowbluesm.svg";
import editIcon from "../../../../images/edit-icon.svg";
import useAxios from "../../../../axiosinstance";
import { yearDate } from "../../../../utilities/DateFormate";
import useTranslation from "../../../customHooks/translations";
import { UserDateFormat } from "../../../../utilities/DateFormate";
import { useSelector,useDispatch } from "react-redux";
// import Button from "../../../custom/button/Button";
import heart_icon_fill from "../../../../images/heart1_blue.png";
import heart_icon from "../../../../images/heart_out_blue.png";
import { patientDetails } from "../../../../redux/actions";

function Profile({ type, name, age }) {
  const axiosInstance = useAxios();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const { akid } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState("");
  const dispatch=useDispatch()
  // const [Favo, setFavo] = useState();

  useEffect(() => {
    const getData = async () => {
      try {
        
        const response = await axiosInstance.current.get(
          `extapp/doctors/getPatientProfile?ak_id=${window.atob(akid)}&ak_dc=${login.ing_ak_id}`
        );
        if (response) {
          setData(response.data.data);
          name(response.data.data.firstname + (" ") + response.data.data.lastname);
          age(yearDate(response.data.data.dob));
          dispatch(patientDetails(response.data.data));
        }
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/unauthorized");
        }
      }
    };
    getData();
  }, [patientDetails]);

  const [orgapiData, setOrgData] = useState([]);
  // /extapp/doctors/getAllUserOrganizations?doctor_id=AKDC000001&patient_id=AKPT000082&useFor=patient
  const getOrgData = async () => {
    const response = await axiosInstance.current.get(
      `extapp/doctors/getAllUserOrganizations?doctor_id=${login.ing_ak_id
      }&patient_id=${window.atob(akid)}&useFor=patient`
    );
    console.log(response.data.data);
    setOrgData(response.data.data);
  };
  useEffect(() => {
    getOrgData();
  }, []);
  const translation = useTranslation();

  const handelEdit = () => {
    navigate(`/patient-demographic/${akid}`);
  };
  const [showMore, setShowMore] = useState(false);

  const [Status, setStatus] = useState("0");
  // const [Status, setStatus] = useState("0");

  const save = () => {
    axiosInstance.current
      .get(`extapp/doctors/getMedicalSummaryList?ak_id=${window.atob(akid)}&doctor_id=${login.ing_ak_id}`)
      .then((res) => {
        setStatus(res.data.data.status)

      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate("/unauthorized");
        }
      });
  };
  useEffect(() => {
    save();
  }, [akid]);

  const handleFavo = (e) => {
    window.open(
      process.env.REACT_APP_FORM_URL +
      `medical-treatment/${akid}?doctor=null&doctor_id=${login.ing_ak_id}&is_doctor_form=true`  ,
      "_blank"
    );
    // navigate(`/medical-treatment/${akid}`);
  }
  const handleRedirect = () => {
    window.open(
      process.env.REACT_APP_FORM_URL + 
      `/medical-history/${akid}?doctor=${login.ing_ak_id}&is_doctor_form=true`,
      "_blank"
    );
    // navigate("/medical-history")
  };
  console.log('====================================');
  console.log(data.name);
  console.log('====================================');
  return (
    <div className={`profile_expand_sec ${profileStyle.profile_sec}`}>
      <div className="whitebox">
        <div className="row">
          <div className="col-md-7 col-sm-7">
            {/* <h4 className={`${profileStyle.top_left_pf_txt}`}>
              {translation.patient_profile}
            </h4> */}
            <div>
              {Status === "0" ? (
                <>
                  <img className="heart_ico" src={heart_icon} alt="Medical summary" onClick={() => handleRedirect()} style={{ cursor: "pointer" }} data-bs-toggle="tooltip" data-bs-placement="top" title="Patient did not fillup yet your medical summary" />
                  <span onClick={() => navigate("/medical-history")} className="c_pointer text-danger mt-1 ms-2"></span>


                </>
              ) : (

                <img className="heart_ico" src={heart_icon_fill} alt="Medical summary" onClick={() => handleFavo()} style={{ cursor: "pointer" }} data-bs-toggle="tooltip" data-bs-placement="top" title="Patient medical summary" />
              )}
            </div>
          </div>
          <div className="col-md-5 col-sm-5 ">
            <div className="text-end">
              <span onClick={() => handelEdit()}>
                <img
                  src={editIcon}
                  className={`mr_15 c_pointer`}
                  alt="icon"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit Notes"
                />
              </span>
            </div>
            {/* <img src={checkIcon} className={`mt_4`} alt="icon" /> */}
          </div>
        </div>

        <div className={`profile ${profileStyle.profile}`}>
          <div
            className={`profile_pic_letter ${profileStyle.profile_pic_letter}`}
          >
            {/* <img
              src={profileTick}
              className={` ${profileStyle.profile_tic_icon}`}
              alt="icon"
            /> */}

            <span className="text-capitalize">
              {data.name === undefined ? " " : data.name.replace(' ', "").charAt(0)}
            </span>
          </div>
          <h4>{data.name}
            {" "}
            {/* {Status === "1" && (
              <img className="heart_ico" src={heart_icon_fill} alt="Medical summary" style={{ cursor: "pointer" }} onClick={() => handleFavo()} data-bs-toggle="tooltip" data-bs-placement="top" title="Patient medical summary" />
          )} */}


          </h4>

          <h6>{window.atob(akid)} &nbsp;</h6>

          <p className="mb-1">
            <strong>Age: {yearDate(data.dob)} Year </strong>{" "}
          </p>

        </div>
        {showMore ? (
          <div className={`${profileStyle.profile_show_more} mt-3`}>
            {/* Management plan screen profile code */}

            <>
              {/* Patient Details screen profile code */}
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="mb-3">
                    <strong>{translation.name}: </strong>
                    <span className="ms-1">{data.name}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="mb-3">
                    <strong>{translation.dob1}: </strong>
                    <span className="ms-1">
                      {UserDateFormat(data.dob, login.ing_date_formate, login.ing_timezone)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="mb-3">
                    <strong>{translation.sex}: </strong>
                    <span className="ms-1">
                      {data.gender === "1"
                        ? "Male"
                        : data.gender === "2"
                          ? "Female"
                          : "Do not want to disclose"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="mb-3">
                    <strong>{translation.email}: </strong>
                    <span className="ms-1">{data.email}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="mb-3">
                    <strong>{translation.address}: </strong>
                    <span className="ms-1">{data.address}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="mb-3">
                    <strong>{translation.mobile}: </strong>
                    <span className="ms-1">{data.mobile} </span>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <strong>Treatment Category: </strong>
                    <span>Shoulder Assessment</span>
                  </div>
                </div> */}

              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="mb-3">
                    <strong>{translation.lastVisit}: </strong>
                    <span className="ms-1">{data.last_visit}</span>
                  </div>
                </div>
              </div>
              {data.card_number && (
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="mb-3">
                      <strong>{translation.medicareCardNumber}: </strong>
                      <span className="ms-1 text-wrap">{data.card_number}</span>
                    </div>
                  </div>
                </div>
              )}

              {data.dva_card && (
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="mb-3">
                      <strong>{translation.dVAConcessionCard}: </strong>
                      <span className="ms-1">
                        {data.dva_card === "0" ? "Not Available" : "Available"}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {data.health_fund && (
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="mb-3">
                      <strong>{translation.privateHealthFund}: </strong>
                      <span className="ms-1">{data.health_fund}</span>
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <strong>Organization Name: </strong>
                  {orgapiData.map((row) => (
                    <p>
                      {row.label}
                      {/* ({row.id},{" "}
                      {new Date(row.last_visited).toLocaleDateString()}) */}
                    </p>
                  ))}
                </div>
              </div>
            </>
            {/* {type === "managementPlan" ? (
              <>
               
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <strong>{translation.dob1}: </strong>
                    <p>{UserDateFormat(data.dob,login.ing_date_formate)}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <strong>{translation.sex}: </strong>
                    <p>
                      {data.gender === "1"
                        ? "Male"
                        : data.gender === "2"
                          ? "Female"
                          : "Do not want to disclose"}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <strong>{translation.email}: </strong>
                    <p>{data.email}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <strong>{translation.address}: </strong>
                    <p>{data.address}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <strong>{translation.mobile}: </strong>
                    <p>{data.mobile} </p>
                  </div>
                </div>
               

                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <strong>{translation.lastVisit}: </strong>
                    <p>{data.last_visit}</p>
                  </div>
                </div>

                <div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <strong>GP Details: </strong>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <strong>GP Name: </strong>
                      <span>{data.gp_name}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <strong>Phone: </strong>
                      <span></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <strong>Practice Name: </strong>
                      <span>{data.gp_practice_name}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12 mb_20">
                      <strong>Address: </strong>
                      <span></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <strong>Insurance Details:: </strong>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <strong>Helth Fund: </strong>
                      <span></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <strong>Membership No: </strong>
                      <span></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <strong>Work Cover: </strong>
                      <span></span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-sm-12 mb_20">
                      <strong>Defence: </strong>
                      <span></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <strong>{translation.medicareCardNumber}: </strong>
                      <p>{data.card_number}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <strong>{translation.dVAConcessionCard}: </strong>
                      <p>{data.dva_card === "0" ? "Not Available" : "Available"}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <strong>{translation.privateHealthFund}: </strong>
                      <p>{data.health_fund}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <strong>Medicare Details: </strong>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <strong>Medicare#: </strong>
                      <span></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <strong>Ref: </strong>
                      <span></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <strong>Expiry: </strong>
                      <span></span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
              
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="mb-3">
                      <strong>{translation.dob1}: </strong>
                      <span className="ms-1">{UserDateFormat(data.dob,login.ing_date_formate)}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="mb-3">
                      <strong>{translation.sex}: </strong>
                      <span className="ms-1">
                        {data.gender === "1"
                          ? "Male"
                          : data.gender === "2"
                            ? "Female"
                            : "Do not want to disclose"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="mb-3">
                      <strong>{translation.email}: </strong>
                      <span className="ms-1">{data.email}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="mb-3">
                      <strong>{translation.address}: </strong>
                      <span className="ms-1">{data.address}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="mb-3">
                      <strong>{translation.mobile}: </strong>
                      <span className="ms-1">{data.mobile} </span>
                    </div>
                  </div>
                </div>
               

                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="mb-3">
                      <strong>{translation.lastVisit}: </strong>
                      <span className="ms-1">{data.last_visit}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="mb-3">
                      <strong>{translation.medicareCardNumber}: </strong>
                      <span className="ms-1 text-wrap">{data.card_number}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="mb-3">
                      <strong>{translation.dVAConcessionCard}: </strong>
                      <span className="ms-1">{data.dva_card === "0" ? "Not Available" : "Available"}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="mb-3">
                      <strong>{translation.privateHealthFund}: </strong>
                      <span className="ms-1">{data.health_fund}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    
                    <strong>Organization Name: </strong>
                    {orgapiData.map((row) => (
                      <p>
                        {row.org_name} ({row.id},{" "}
                        {new Date(row.last_visited).toLocaleDateString()})
                      </p>
                    ))}
                  </div>
                </div>
              </>
            )} */}
          </div>
        ) : null}

        <div className="text-end">
          <span
            className="default_c c_pointer"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? "Display Less " : "Display More "}
            <img
              className={` ${showMore ? `${profileStyle.show_more}` : null} ${profileStyle.arrow_icon
                }`}
              src={arrowIcon}
              alt="icon"
            />
          </span>
        </div>
      </div>
    </div>
  );
}

Profile.defaultProps = {
  type: "",
};

export default Profile;
