import React, { useState } from "react";
import { useTable } from "react-table";
import InfiniteScroll from "react-infinite-scroll-component";
import TableSpinner from "../../custom/spinner/TableSpinner";
import upload from "../../../images/download.png";
import Modal from "react-bootstrap/Modal";
import FileUpload from "./FileUpload";
// import sonStyles from "../../operative-notes/saved-notes/PostOperativeNote.module.css";
function OrgListTableOrg({ data, updatePagination, DataIdget }) {
  console.log(DataIdget);
  const [show, setShow] = useState(false);
  const [autoid, setautoid] = useState("");
  const handleCloseShow = () => {
    setShow(false);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Organizaion ID",
        accessor: "akou_id",
        headerClassName: "col-orgid",
        className: "col-orgid",
      },
      {
        Header: "Organizaion Name",
        accessor: "org_name",
        headerClassName: "col-orgname",
        className: "col-orgname",
      },
      {
        Header: "Status",
        headerClassName: "col-status",
        accessor: "approved_status",
        className: "col-status",
        Cell: (row) => (
          <span>
            {row.value === 1 && "Registered"}
            {row.value === 0 && "Review in progress"}
          </span>
        ),
      },
    ],
    []
  );

  // const handleEdit = (e, i) => {
  //   DataIdget(e, i);
  // };
  const { rows } = useTable({
    columns,
    data,
  });

  return (
    <>
      <div className="tableContainer" id="rrpScrollableDiv">
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rrpScrollableDiv"
          // loader={<TableSpinner />}
        >
          <table role="table" className="custom-tbl my_clinic">
            <thead>
              <tr role="row">
                <th colSpan={1} role="columnheader" className="col-orgid">
                  Organization ID
                </th>
                <th colSpan={1} role="columnheader" className="col-orgname">
                  Organization Name
                </th>
                <th colSpan={1} role="columnheader" className="col-status">
                  Status
                </th>
                <th colSpan={1} role="columnheader" className="col-status">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => {
                return (
                  <>
                    <tr role="row">
                      <td role="cell" className="col-orgid">
                        {row.org_id}
                      </td>
                      <td role="cell" className="col-orgname">
                        {row.org_name}
                      </td>
                      <td role="cell" className="col-status">
                        <span>
                          {row.status_id === -1 && "Suspended"}
                          {row.status_id === 1 && "Registered"}
                          {row.status_id === undefined || row.status_id === 0
                            ? "Review in progress"
                            : ""}
                          {console.log(row.status_id, "row.status_id")}
                        </span>
                      </td>

                      <td
                        role="cell"
                        className="col-orgname "
                        onClick={() => {
                          setShow(true);
                          setautoid(row.auto_id);
                        }}
                      >
                        <img
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Upload proof"
                          className={`action_icons c_pointer`}
                          src={upload}
                          alt="Icon"
                          // onClick={() => handleEdit(row.auto_id,2)}
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
        <Modal
          show={show}
          onHide={handleCloseShow}
          dialogClassName="modal-lg patient_notes_popup"
          centered
          //  dialogClassName="modal-lg"
          // className={`create_newnote_pop ${sonStyles.create_newnote_pop}`}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-center">{"Upload Files"}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <FileUpload
              auto_id={autoid}
              id={"28"}
              name={"Upload Proof Of Organization"}
            />
            {/* <FileUpload hideModel={setShow} /> */}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default OrgListTableOrg;
