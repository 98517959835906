/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import sonStyles from "../../operative-notes/saved-notes/PostOperativeNote.module.css";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import VideoPlayer from "../video-player/VideoPlayer";
// import test from "../../../images/test.docx"
import axios from "axios";
// import useAxios from "../../../axiosinstance";
import downloadIcon from "../../../images/download-icon.svg";
import downloadIconWhite from "../../../images/download-icon-white.svg";
import prints from "../../../images/print_blue.png";
import printsWhite from "../../../images/print_white.png";
import { s3UrlLinkFile } from "../../../utilities/S3Url";
import { s3UrlLink } from "../../../utilities/S3Url";
import { PDFViewer } from '@react-pdf/renderer';
export default function Viewdoc({
  showModal,
  handleCloseShow,
  // link_url,
  filetype,
  filelinkUrl,
  fileName,
}) {
  // const [createSvnShow, setCreateSvnShow] = useState(false);
  // const axiosInstance = useAxios();

  // const GetTask = async () => {
  //   try {
  //     const response = await axios.get(filelinkUrl);
  //     setFileUrl(response.data);
  //   } catch (err) {
  //     // console.log(err);
  //   }
  // }
  // useEffect(() => {
  //   GetTask()
  // }, [filelinkUrl])

  //   const handleClose = () => setCreateSvnShow(false);
  // const headers = {
  //   "X-Access-Token": "1234567890",
  //   "My-Custom-Header": "my-custom-value",
  // };

  // const downloadURI=(uri, name) =>{
  //   var link = document.createElement("a");
  //   link.download = name;
  //   link.href = uri;
  //   link.click();
  //   link.remove();
  // }

  const downloadURI = (url, name) => {
    axios
      .get(s3UrlLink(url), {
        headers: {
          "Content-Type": "application/octet-stream",
        },
        responseType: "blob",
      })
      .then((response) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(response.data);
        console.log(url, "hello")
        a.href = url;
        a.download = name;
        a.click();

      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const docs = [
    { uri: s3UrlLinkFile(filelinkUrl, fileName), fileType: filetype },
  ];
 
  //   const printContent = (url, name) => {
  // window.print()
  let Url = s3UrlLinkFile(filelinkUrl, fileName)

  const printContent = (filelinkUrl, fileName, typee) => {   
    let Urlsssss = s3UrlLinkFile(filelinkUrl, fileName); 
    // Replace the hardcoded link with your dynamic link
    let link = Url;  
    fetch(link)
      .then(response => response.text())
      .then(content => {
        if (typee === "pdf" || typee === "docx" || typee === "doc" || typee === "xlsx") {
          window.open(Urlsssss, '_blank');
        } else if(typee==="txt"){
          const newWindow = window.open('', '_blank'); 
          newWindow.document.write(`
            <html>
              <head>
                <title>Print</title>
              </head>
              <body>
                <pre>${content}</pre>
                <script type="text/javascript">
                  window.onload = function() {
                    window.print();
                    window.close();
                  }
                </script>
              </body>
            </html>
          `);
          newWindow.document.close();
        }else if(typee==="jpg" || typee==="png"){
          const newWindow = window.open('', '_blank');  
          newWindow.document.write(`
            <html>
              <head>
                <title>Print</title>
              </head>
              <body>
                <img src=${Urlsssss} style="height:100%;width:100%"/>
                <script type="text/javascript">
                  window.onload = function() {
                    window.print();
                    window.close();
                  }
                </script>
              </body>
            </html>
          `);
          newWindow.document.close();
        }
      })
      .catch(error => console.error('Error fetching content:', error));
  };

  return (
    <div>
      <Modal
        show={showModal}
        onHide={handleCloseShow}
        dialogClassName="modal-lg"
        className={`create_newnote_pop ${sonStyles.create_newnote_pop}`}
      >
        <Modal.Header closeButton>
          <Modal.Title className="">{"View Document"}</Modal.Title>
          <Modal.Title className=" text-center">
            {filetype === "pdf" ? (
              // <a href={fileUrl} download="file">
              <>
                <button
                  onClick={() => downloadURI(filelinkUrl, fileName)}
                  className={`btn_outline new__note_btn f_600 img_white`}
                  style={{ position: "absolute", right: "55px", top: "12px" }}
                >
                  <img
                    src={downloadIcon}
                    alt="img"
                    className="mr_15"
                    style={{ marginTop: "-4px" }}
                  />{" "}
                  <img
                    src={downloadIconWhite}
                    alt="img"
                    className="mr_15 img_hover_doc"
                    style={{ marginTop: "-4px" }}
                  />{" "}
                  Download
                </button>
                <button
                  onClick={() =>
                    printContent(filelinkUrl, fileName , filetype)
                  }
                  className={`btn_outline new__note_btn f_600 img_white`}
                  style={{ position: "absolute", right: "215px", top: "12px" }}
                >
                  <img
                    src={prints}
                    alt="img"
                    className="mr_15"
                    style={{ marginTop: "-4px", width: "26px" }}
                  />{" "}
                  <img
                    src={printsWhite}
                    alt="img"
                    className="mr_15 img_hover_view"
                    style={{ marginTop: "-8px", width: "26px" }}
                  />{" "}
                  Print
                </button>
              </>
            ) : (
              // {/* </a> */}
              <>
                <button
                  onClick={() =>
                    downloadURI(filelinkUrl, fileName)
                  }
                  className={`btn_outline new__note_btn f_600 img_white`}
                  style={{ position: "absolute", right: "55px", top: "12px" }}
                >
                  <img
                    src={downloadIcon}
                    alt="img"
                    className="mr_15"
                    style={{ marginTop: "-4px" }}
                  />{" "}
                  <img
                    src={downloadIconWhite}
                    alt="img"
                    className="mr_15 img_hover_view"
                    style={{ marginTop: "-4px" }}
                  />{" "}
                  Download
                </button>
                {filetype !== "mp4" && (

                  <button
                    onClick={() =>
                      printContent(filelinkUrl, fileName , filetype)
                    }
                    className={`btn_outline new__note_btn f_600 img_white`}
                    style={{ position: "absolute", right: "230px", top: "12px" }}
                  >
                    <img
                      src={prints}
                      alt="img"
                      className="mr_15"
                      style={{ marginTop: "-4px", width: "26px" }}
                    />{" "}
                    <img
                      src={printsWhite}
                      alt="img"
                      className="mr_15 img_hover_view"
                      style={{ marginTop: "-8px", width: "26px" }}
                    />{" "}
                    Print
                  </button>
                )}
              </>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="create_newnote_pop_bdy">
          <div className="row">
            {filetype === "mp4" ? (
              <VideoPlayer url={s3UrlLinkFile(filelinkUrl, fileName)} />
            ) : (
              <div>
                {/* <button onClick={href(filelinkUrl)}>Download</button> */}
                <DocViewer
                  pluginRenderers={DocViewerRenderers}
                  prefetchMethod="GET"
                  documents={docs}
                  // requestHeaders={headers}
                  config={{
                    // loadingRenderer: {
                    //   overrideComponent: MyLoadingRenderer,
                    // },
                    header: {
                      disableHeader: false,
                      disableFileName: true,
                      retainURLParams: true,
                    },
                  }}
                  style={{ height: "75vh" }}
                />
                {/* <iframe src={s3UrlLinkFile(filelinkUrl, fileName)} style={{ width: "100%", height: "600px" }} /> */}
              </div>
            )}
            {filetype === "xlsx" ? (
              <span className="xlsx_bg"></span>
            ) : (
              <span className="white_bg"></span>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
