import React, { useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import useAxios from "../../axiosinstance";
import Profile from "./patient-details/profile/Profile";
import { useSelector } from "react-redux";
// import ManagementPlan from "../management-plans/management-plan/ManagementPlan";
import ManagementPlan from "./patient-details/management-plan-sec/ManagementPlan";
import { useParams, useNavigate } from "react-router-dom";
import Button from "../custom/button/Button";
import SmallProfile from "../document-list/small-profile/SmallProfile";
import SchedulerTable from "./SchedulerTable";
import PatientProms from "./PatientProms";
import settingsStyle from "../settings/Settings.module.css";
import SchedulerTableDis from "./SchedulerTableDis";

// import { Navigate } from "react-router-dom";
export default function Schedule(resource_id) {
  const { akid } = useParams();
  const [manageMantdata, setData1] = useState([]);
  const axiosInstance = useAxios();
  const [name1, setName1] = useState("");
  const [age1, setAge1] = useState("");
  const [org, setOrg] = useState("");
  const [type, setType] = useState("");
  const [show, setShow] = useState(false);
  const [scoringData, setScoringData] = useState([]);
  const [, setdefaultOrg] = useState("");
  const [, setdefaultType] = useState("");
  const [, setAllData] = useState("");
  const [rangeOfMotion, setrangeOfMotion] = useState({});
  const [rangeOfMotionRecent, setrangeOfMotionRecent] = useState({});
  const [refs, setRefs] = useState(false);
  const [schedulerForm, setschedulerForm] = useState([]);
  const [schedulerForm2, setschedulerForm2] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const refresh = useSelector((state) => state.mainReducer.noteRef)
  const [treatement, setTreatment] = useState("");
  const [resourceId, setResourceId] = useState("");
  const [activeData, setActiveData] = useState([]);
  const [resource_Id, setResource_Id] = useState("")
  const [comleteData, setComleteData] = useState([]);
  const [disContinued, setDiscontinued] = useState([]);
  const { rId } = useParams();
  const navigate = useNavigate();
  const { akId } = useParams();
  const [offset, setOffset] = useState(10);
  const [key, setKey] = useState("allcases");
  const [keyID, setKeyID] = useState(0);
  const { ptId } = useParams();
  const handleSelect = (key) => {
    if (key === "allcases") {
      setKey(key);
      setKeyID(0);
    } else if (key === "submitted") {
      setKey(key);
      setKeyID(1);
    } else if (key === "discontinued") {
      setKey(key);
      setKeyID(2);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const response = await axiosInstance.current.get(
        `extapp/doctors/viewDetailsManagementPlansSection/${login.ing_ak_id
        }/${window.atob(akid)}?org_id=${""}&type_id=${""}&offset=0&limit=10`
      );
      setData1(response.data.data);
      // console.log(response.data.data);
    };
    getData();
  }, []);

  useEffect(() => {
    const getScore = async () => {
      setShow(false);
      try {
        const response = await axiosInstance.current.get(
          `extapp/doctors/viewDetailsFormsSection/${login.ing_ak_id
          }/${window.atob(akid)}?org_id=${org}&type=${type}`
          // `extapp/doctors/viewDetailsFormsSection/AKDC000001/AKPT000003?org_id=AKOU000001&type=4`
        );
        // console.log(response)
        if (response.status === 200) {
          setScoringData(response.data.scoringData);
          setShow(true);
          setAllData(response.data);
          setType(response.data.type);
          setOrg(response.data.org_id);
          setdefaultOrg(response.data.org_id);
          setdefaultType(response.data.type);
          setrangeOfMotion(response.data.data[0].initial);
          setrangeOfMotionRecent(response.data.data[1].follow_up);
        }
      } catch (err) {
        // setScoringData([])
        setShow(true);
        if (err.response.status === 401) {
          navigate("/unauthorized");
        }
      } finally {
        console.log("api response finally");
      }
    };
    getScore();
  }, [type, refs]);

  const name = (e) => {
    setName1(e);
  };
  const age = (e) => {
    setAge1(e);
  };

  useEffect(() => {
    const getData = async () => {
      const response = await axiosInstance.current.get(
        `extapp/doctors/viewDetailsManagementPlansSection/${login.ing_ak_id
        }/${window.atob(akid)}?org_id=${""}&type_id=${""}&offset=0&limit=10`
      );
      setData1(response.data.data);
    };
    getData();
  }, []);
  const ViewProfile = () => {
    navigate(`/patient-view/${akid}/${window.btoa(org)}`);
  };

  useEffect(() => {
    const getStatusData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extapp/doctors/active_complete_schedules/${login.ing_ak_id
          }?ak_pt=${window.atob(akid)}&status=0`
        );

        setActiveData(response.data.data);
        setResource_Id(response.data.data[0].resource_id)
      } catch (error) { }
    };
    if (key === "allcases") {
      getStatusData();
    }
  }, [key, refresh]);
  console.log(resource_Id, "resource_Idresource_Id")
  useEffect(() => {
    const getStatusComplteData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extapp/doctors/active_complete_schedules/${login.ing_ak_id
          }?ak_pt=${window.atob(akid)}&status=1`
        );
        setComleteData(response.data.data);
      } catch (error) {

      }
    };
    if (key === "submitted") {
      getStatusComplteData();
    }
  }, [key, refresh]);

  useEffect(() => {
    const getDiscontinued = async () => {
      try {
        const response = await axiosInstance.current.get(`extapp/patients/get_discontinue_schedule?ak_id=${login.ing_ak_id}&ak_pt=${window.atob(akid)}`)
        if (response) {
          console.log(response, "responseresponseresponse1")
          setDiscontinued(response.data.data)
        }
      } catch (error) {

      }
    };
    if (key === "discontinued") {
      getDiscontinued();
    }
  }, [key, refresh]);

  return (
    <>
      <div className="row">
        <div className="col-6 text-end"></div>
        {/* <div className="col-6 text-end">
                    <SmallProfile />
                </div> */}
      </div>
      <div className="row">
        <div className="col-6 text-end"></div>
        <div className="col-6 text-end">
          <Button
            onClick={ViewProfile}
            value="Patient Dashboard"
            buttonStyle="btn_outline ms-2"
            width="200px"
          />
        </div>
      </div>
      <div className="row" style={{ marginTop: "20px" }}>
        <>
          <div className="col-xxl-3 col-lg-3 col-md-5 col-sm-3">
            <Profile name={name} age={age} />

            <ManagementPlan
              org={""}
              formType={""}
              manageMentdata={manageMantdata}
              name1={name1}
              age1={age1}
            />
          </div>

          <div className="col-xxl-9 col-lg-9 col-md-7 col-sm-9">
            <div className="whitebox">
              <div className="row d-flex flex-wrap">
                <div className="col-md-6 col-sm-2">
                  <h4 class={`${settingsStyle.content_main_title}`}>
                    {"PROMs Scheduler"}
                  </h4>
                </div>
                <div className="col-md-6 col-sm-2 text-end pe-5">
                  <PatientProms
                    name={name}
                    age1={age1}
                    schedulerForm={schedulerForm}
                    setTreatment={setTreatment}
                    treatement={treatement}

                    resource_ids={resource_Id}
                  />
                </div>
              </div>

              <hr></hr>
              <br></br>
              <br></br>
              <div className="ms-2">
                <div className="my_visit_screen org_tab mb_100 ul_bottom">
                  <Tabs
                    id="case-list-tabs"
                    activeKey={key}
                    onSelect={handleSelect}
                  // className="border"
                  >
                    <Tab eventKey="allcases" title={"Active"}>
                      {keyID === 0 &&
                        (activeData.length > 0 ? (
                          <SchedulerTable data={activeData}
                          //    updatePagination={fetchMoreData}

                          />
                        ) : (
                          <div className="no_data">
                            {"No data found"}
                          </div>
                        ))}
                    </Tab>
                    <Tab eventKey="submitted" title={"Completed"}>

                      {keyID === 1 &&
                        (comleteData.length > 0 ? (
                          <SchedulerTable data={comleteData} />
                        ) : (
                          <div className="no_data">{"No data found"}</div>
                        ))}

                    </Tab>
                    <Tab eventKey="discontinued" title={"Discontinued"}>
                      {keyID === 2 &&
                        (disContinued.length > 0 ? (
                          <SchedulerTableDis data={disContinued} />
                        ) : (
                          <div className="no_data">{"No data found"}</div>
                        ))}
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
}
