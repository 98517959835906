import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTable } from "react-table";
import Button from "../../custom/button/Button";
import InputField from "../../custom/inputfield/InputField";
import { useSelector } from "react-redux";
import PromsAction from "./PromsAction";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
export default function PromsDataTable({
  promsdata,
  organisation,
  updatePagination,
  doctorid,
  schedulerForm,
  setActiveTab,
activeTab
}) {
  const login = useSelector((state) => state.mainReducer.loginData.arr);

  const { akid } = useParams();
  const { akId } = useParams();
  const [doctorID] = useState(login.ing_ak_id);
  const [isLoading, setIsLoading] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1000);
  // }, []);
  let data = promsdata;

  const columns = React.useMemo(
    () => [
      // {
      //   Header: "",
      //   id: "1",
      //   headerClassName: "col-img",
      //   className: "col-img",
      //   accessor: "type",
      //   Cell: (row) => (
      //     <span>
      //       <label className="user-letter small">
      //         {row.value.charAt(0).toUpperCase()}
      //       </label>
      //     </span>
      //   ),
      // },
      {
        Header: "Title",
        accessor: "title",
        style: {
          margin: '0 10px', // Add margin between columns
        },
      },
      {
        Header: "Treatment Category",
        accessor: "treatment_category",
        style: {
          margin: '0 5px', // Add margin between columns
        },
      },
      {
        Header: "Primary Diagnosis",
        accessor: "primary",
        style: {
          margin: '0 10px', // Add margin between columns
        },
        Cell: (row) => (
          //       <div>
          //   {Object.keys(row.row.original.primary_diagnosis).map((key) => (
          //     <p key={key}>
          //        {row.row.original.primary_diagnosis[key]}
          //     </p>
          //   ))}
          // </div>
          <div>
            {row.row.original.primary_diagnosis?.non_surgicalinput ? (
              <p>{row.row.original.primary_diagnosis?.non_surgicalinput}</p>
            ) : (
              Object.keys(row.row.original.primary_diagnosis).map((key) => (
                <p key={key}>{row.row.original.primary_diagnosis[key]}</p>
              ))
            )}
          </div>
        ),
      },
      {
        Header: "Organization",
        accessor: "organization",
        style: {
          margin: '0 10px', // Add margin between columns
        },
        Cell: (row) => (
          <span>
            {row.row.original.organization.map((org, index) => (
              <div key={index}>{org}</div>
            ))}
          </span>
        ),
      },

      {
        Header: "Action",
        headerClassName: "col-action",
        accessor: "action",
        className: "col-action",
        style: {
          margin: '0 5px', // Add margin between columns
        },
        Cell: (row) => (
          <span>
            {/* <button onClick={e=> handleEdit(row.row.original)}>Edit</button> */}
            {/* action */}
            {/* <Button
              onClick={continueRedirects}
              value="Submit"
              buttonStyle="btn_outline"
              width="140px"
            /> */}

            <PromsAction
              title={row.row.original.title}
              resource_id={row.row.original.resource_id}
              speciality={row.row.original.speciality}
              label={row.row.original.organization}
              non_surgicalinput={
                row.row.original.primary_diagnosis.non_surgicalinput
                  ? row.row.original.primary_diagnosis.non_surgicalinput
                  : row.row.original.primary_diagnosis.primary
              }
              treatment_category={row.row.original.treatment_category}
              alert={
                row.row.original?.alert && row.row.original.alert.length > 0
                  ? row.row.original?.alert[0] === 0
                    ? "No alert"
                    : row.row.original?.alert[0] === 1
                      ? "Same day"
                      : row.row.original?.alert[0] === 2
                        ? "1 day before"
                        : row.row.original?.alert[0] === 3
                          ? "2 days before"
                          : "1 week before"
                  : ""
              }
              // overdue={
              //   row.row.original?.overdue[0] === 0
              //     ? "No alert"
              //     : row.row.original?.overdue[0] === 1
              //     ? "1 day after"
              //     : row.row.original?.overdue[0] === 2
              //     ? "2 days after"
              //     : "1 week after"
              // }
              overdue={
                row.row.original?.overdue && row.row.original.overdue.length > 0
                  ? row.row.original.overdue[0] === 0
                    ? "No alert"
                    : row.row.original.overdue[0] === 1
                      ? "1 day after"
                      : row.row.original.overdue[0] === 2
                        ? "2 days after"
                        : "1 week after"
                  : ""
              }
              reference_date={
                row.row.original?.reference_date[0] === "1"
                  ? "Treatment Date"
                  : "Date of initial PROMs"
              }
              schedules={row.row.original.schedules}
              viewAction="true"
              editAction="true"
              feedbackAction="false"
              deleteAction="true"
              arrowAction="true"
              rowName={doctorID}
              rowId={row.row.ea_akpt_id}
              // rowData={actionData}
              Status={row.row.original.status}
              rowType="patients"
              patientId={akId === undefined ? akid : akId}
              notetitle={row.row.original.ea_name}
              rowOrgId={row.row.original.ea_akpt_id}
              orgId={row.row.original.ea_org_id}
              setActiveTab={setActiveTab}
               activeTab={activeTab}
            />
          </span>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      organisation,
    });
  // console.log(data, "vvvvvv");

  return (
    <div className="tableContainer dasbord_height" id="ripScrollableDiv">
      {console.log("rows.length", rows.length)}
      <InfiniteScroll
        dataLength={rows.length}
        next={updatePagination}
        hasMore={true}
        scrollableTarget="ripScrollableDiv"
      // loader={<TableSpinner />}
      >
        <table {...getTableProps()} className="custom-tbl seting_model">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      { className: column.headerClassName },
                    ])}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps([
                          { className: cell.column.className },
                        ])}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
}
