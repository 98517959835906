import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTable } from "react-table";

import { useSelector } from "react-redux";

import { useParams } from "react-router-dom";

import { useSearchParams } from "react-router-dom";

export default function AddModelTableSchedular({
  data,
  updatePagination,
  doctorid,
  onCheckboxChange,
  updateCheckboxValues,
}) {
//   const login = useSelector((state) => state.mainReducer.loginData.arr);
  const { akid } = useParams();
  const { akId } = useParams();
//   const [doctorID] = useState(login.ing_ak_id);
  const [searchParams] = useSearchParams();
  let org = searchParams.get("org");
 
  const [check, setCheck] = useState(false);
  // const handleCheckboxClick = () => {
  //   // Perform checkbox logic
  //   // Call the callback passed from the parent
  //   if (onCheckboxChange && typeof onCheckboxChange === 'function') {
  //     setCheck()
  //     onCheckboxChange();
  //   }
  // };
  // const handleCheckboxChange = (event) => {
  //   const { name, checked } = event.target;
  //   console.log(name, checked,"name, checked");
  //   //
  //   updateCheckboxValues({ name, checked });
  // };
  // const handleCheckboxChange = (resource_id, event) => {
  //   const { name, checked } = event.target;
  //   console.log(name, checked, "name, checked");
  //   // Pass resource_id along with name and checked to the parent component
  //   updateCheckboxValues({ resource_id, name, checked });
  // };/
  const handleCheckboxChange = (resource_id, event) => {
    const { name, checked } = event.target;
    updateCheckboxValues({ resource_id, name, checked });
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    const year = date.getFullYear().toString().slice(2); 
  
    return `${day}/${month}/${year}`;
  };
  const columns = React.useMemo(
    () => [
      // {
      //   Header: "",
      //   id: "1",
      //   headerClassName: "col-img",
      //   className: "col-img",
      //   accessor: "type",
      //   Cell: (row) => (
      //     <span>
      //       <label className="user-letter small">
      //         {row.value.charAt(0).toUpperCase()}
      //       </label>
      //     </span>
      //   ),
      // },

      {
        Header: "assessment_date",
        accessor: "assessment_date",
        Cell: (row) => (
          <label className="text-black f_500"> <span className=""></span>
          {row.row.original.assessment_date}
          </label>
        ),
      },

      {
        Header: "Speciality",
        accessor: "speciality",
        Cell: (row) => (
          <label className="text-black">
            {row.row.original.type_id === 5
              ? "knee"
              : row.row.original.type_id === 4
                ? "shoulder"
                : row.row.original.type_id === 6
                  ? "Hip"
                  : row.row.original.type_id === 21
                    ? "Foot and ankle"
                    : row.row.original.type_id === 22
                      ? "Elbow"
                      : row.row.original.type_id === 23
                        ? "Hand and Wrist"
                        : row.row.original.type_id === 33
                          ? "General"
                          : ""}
          </label>
        ),
      },
      
      {
        Header: "Checkbox",
        accessor: "checkbox",
        Cell: (row) => (
          <>
            <div
              className={`form-group custom_check_box custom_radio_box large${check ? "tick" : ""
                } text-black`}
            >
              <input
                type="radio"
                value={check}
                name="radio"
                id={row.row.original.resource_id}
                
                onChange={(event) =>
                  handleCheckboxChange(row.row.original.resource_id, event)
                }
              />
              <label key={row.row.original.resource_id}
                className="me-4"
                id={row.row.original.resource_id}
                for={row.row.original.resource_id} > </label>
            </div>
          </>
        ),
      },
    ],
    []
  );
  // console.log(resource_id, "checkcheck");
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  return (
    <div className=" " id="ripScrollableDiv">
      {/* <InfiniteScroll
        dataLength={rows.length}
        next={updatePagination}
        hasMore={true}
        scrollableTarget="ripScrollableDiv"
        // loader={<TableSpinner />}
      > */}
      <table {...getTableProps()} className="custom-tbl">
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                <td>
                  <span className="circle_"></span>
                </td>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps([
                      { className: cell.column.className },
                    ])}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* </InfiniteScroll> */}
    </div>
  );
}