import React, { useState, useEffect } from "react";
import "../../css/ViewTreatmentForm.css";
import { useParams, useNavigate } from "react-router-dom";
import PersonalDetails from "../operative-notes/view-operative-notes/personal-details/PersonalDetails";
import useAxios from "../../axiosinstance";

import { returnStrings } from "../../utilities/ComaScript";
import opnSty from "../operative-notes/view-operative-notes/ViewOperativeNotes.module.css";
import { Document, PDFDownloadLink, pdf } from "@react-pdf/renderer";
import PersonalSurgeryPdf from "./PersonalSurgeryPdf";
import downloadIcon from "../../images/download-icon.svg";
import downloadIconWhite from "../../images/download-icon-white.svg";
import Button from "../custom/button/Button";
// import Button from "../../../custom/button/Button";
// import { DateFormate } from "../../../../utilities/DateFormate";
import { useSelector } from "react-redux";
import {
  UserDateFormat,
  UserDateFormatwithTimeZone,
} from "../../utilities/DateFormate";
import TreatmentDetailsPdf from "./TreatmentDetailsPdf";
import PostOperativeNotes from "./view-operative-notes/post-operative-notes/PostOperativeNotes";
import PostOppdf from "./view-operative-notes/PostOppdf";
import useAxiosUpload from "../../axiosinstanceUpload";

function TreatementDetails({}) {
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const { akid } = useParams();
  const { rId } = useParams();
  let akId = window.atob(akid);
  let rid = window.atob(rId);
  const navigate = useNavigate();
  const [org, setOrg] = useState("");
  const axiosInstanceUpload = useAxiosUpload();
  // let akId = window.atob(akid);
  // let rid = window.atob(rId);
  const [data, setData] = useState([]);
  const [datapost, setDatapost] = useState([]);
  const [Personaldata, setPersonaldata] = useState([]);
  const axiosInstance = useAxios();
  const [diagnosis, setDiagnosis] = useState([]);
  const [orgId, setOrgId] = useState("");



  const GetTask = async () => {
    try {
      const response = await axiosInstance.current.get(
        `/extapp/forms/get_patients_pDetails?doctor_id=${login.ing_ak_id}&resource_id=${rid}&patient_id=${akId}`
      );
      setOrgId(response.data.op_notes[0].org_id);
      setData(response.data.op_notes);
      setDatapost(response.data.op_notes[0]);
      setDiagnosis(response.data.op_notes[0].diagnosis);
      setPersonaldata(response.data.personal_details);
     
      // setDownloadlink(response.data.link);
    } catch (err) {
      console.log(err);
    }
  };

  const generatePDFDocument = async () => {
    const blob = await pdf(
      <Document>
        <TreatmentDetailsPdf
          Personaldata={Personaldata}
          data={data}
          login={login}
        />
      </Document>
    ).toBlob();
    const formData = new FormData();
    formData.append(
      "file",
      blob,
      `${Personaldata.ig_fname}_${Personaldata.ig_lname}_${rid}.pdf`
    );
    const res = await axiosInstanceUpload.current.post(
      `extglobal/upload_documents?ak_pt=${akId}&ak_ou=${orgId}&ak_dc=${login.ing_ak_id}&resource_id=${rid}&type=46`,
      formData
    );
    if (res.status === 201) {
    }
  };

  useEffect(() => {
    setTimeout(() => {
    generatePDFDocument();
    },3000)
  }, [login.ing_ak_id,orgId]);

  useEffect(() => {
    GetTask();
  }, []);

  const ViewProfile = () => {
    navigate(
      `/patient-view/${window.btoa(Personaldata.ig_patient_id)}/${window.btoa(
        org
      )}`
    );
  };

  console.log(orgId, "Personaldata");
  // const login2 = useSelector((state) => state.mainReducer.loginData.arr);
  // const HourFormate = (time, format) => {
  //   if (format === "2") {

  //     let date = new Date(`01/01/2023 ${time}`);

  //     return  date.toLocaleTimeString("en-US", { hour12: false }).slice(0, 5);
  //   } else {
  //     return time;
  //   }
  // };
  //   const ViewProfile = () => {
  //     navigate(
  //         `/patient-view/${window.btoa(Personaldata.ig_patient_id)}/${window.btoa(
  //             org
  //         )}`
  //     );
  // };

  return (
    <>
      <div className={`view_op_notes ${opnSty.view_op_notes}`}>
        <div className="row page_header">
          <div className="col-sm-6 col-md-7">
            <h4 className="ph_title">Treatment Note Results</h4>
          </div>
          <div className="col-sm-6 col-md-5  text-end">
            <PDFDownloadLink
              document={
                <Document>
                  {/* <PersonalSurgeryPdf Personaldata={Personaldata} data={data} login={login}  diagnosis={diagnosis} /> */}

                  <TreatmentDetailsPdf
                    Personaldata={Personaldata}
                    data={data}
                    login={login}
                  />
                  {/* <PostOppdf item={data} /> */}
                </Document>
              }
              fileName={Personaldata.ig_fname + new Date().getTime() + ".pdf"}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading . . ."
                ) : (
                  <>
                    <button
                      className={`btn_outline new__note_btn f_600 img_white`}
                    >
                      <img
                        src={downloadIcon}
                        alt="img"
                        className="mr_15"
                        style={{ marginTop: "-4px" }}
                      />{" "}
                      <img
                        src={downloadIconWhite}
                        alt="img"
                        className="mr_15 img_hover_view"
                        style={{ marginTop: "-4px" }}
                      />{" "}
                      Download as PDF
                    </button>
                  </>
                )
              }
            </PDFDownloadLink>

            <Button
              onClick={ViewProfile}
              value="Patient Dashboard"
              buttonStyle="btn_outline ms-2"
              width="200px"
            />
          </div>
        </div>
        {/* <PersonalDetails Personaldata={Personaldata} /> */}
      </div>

      <PersonalDetails Personaldata={Personaldata} />
      {data.map((item) => (
        <div className="whitebox padding-none">
          <div className="treatment-header">
            <h2>Treatment Details</h2>
            <div className="treatment-header-right"></div>
          </div>

          <div className="treatment-content">
            <div className="treatment-row">
              <div className="treatment-col text-capitalize">
                <label>Surgeon:</label>
                {/* {item.surgeon_name.join(", ")} */}
                {item.surgeon_name && Array.isArray(item.surgeon_name)
                  ? item.surgeon_name.join(", ")
                  : ""}
              </div>
              <div className="treatment-col">
                <label>Date Performed:</label>
                {UserDateFormat(item.surgery_date,login.ing_date_formate,login.ing_timezone)} 

                {/* {UserDateFormatwithTimeZone(item.surgery_date)} */}
              </div>

              {/* <div className="treatment-col text-capitalize">
                <label>Assistant Surgeon:</label>
                {item.asst_surgeon_name.join(", ")}
         
              </div> */}
            </div>

            {/* <div className="treatment-row">
              <div className="treatment-col">
                <label>Date Performed:</label>
               
                {UserDateFormat(item.surgery_date, login.ing_date_formate, login.ing_timezone)}
              
              </div>


            </div> */}

            <div className="treatment-row result-row-separator">
              <div className="treatment-col">
                <label>Indication For Treatement:</label>
                <pre className="p_description1">
                  {" "}
                  {item.operation_indication}
                </pre>
              </div>

              <div className="treatment-col">
                <label>Treatment Performed:</label>
                <pre className="p_description1">{item.operation_performed}</pre>
              </div>
            </div>

            <div className="treatment-row result-row-separator">
              <div className="treatment-col">
                <label>Specialty:</label>
                {item.is_op_note_general === "4"
                  ? "Shoulder"
                  : item.is_op_note_general === "6"
                  ? "Hip"
                  : item.is_op_note_general === "5"
                  ? "Knee"
                  : item.is_op_note_general === "22"
                  ? "Elbow"
                  : item.is_op_note_general === "21"
                  ? "Foot and ankle"
                  : item.is_op_note_general === "23"
                  ? "Hand and wrist"
                  : item.is_op_note_general === "33"
                  ? "General"
                  : ""}
              </div>
              {item.treatement_note === "4" ||
              item.treatement_note === "6" ||
              item.treatement_note === "5" ||
              item.treatement_note === "22" ||
              item.treatement_note === "21" ||
              item.treatement_note === "23" ? (
                <>
                  {item.is_op_note_general !== "33" && (
                    <div className="treatment-col">
                      <label>Treatment Side:</label>
                      {item.operativeSide === "1" ? "Right" : "Left"}
                    </div>
                  )}
                </>
              ) : (
                ""
              )}
            </div>

            <div className="treatment-row result-row-separator">
              <div className="treatment-col">
                <label>Primary Diagnosis:</label>
                {item.nonprimary}
              </div>
            </div>
            {item.treatement_note && (
              <>
                <div className="treatment-row result-row-separator">
                  <div className="treatment-col">
                    <label>Description:</label>
                    <pre className="p_description1">{item.Other}</pre>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ))}

      {/* <PostOperativeNotes item={datapost} /> */}
    </>
  );
}

export default TreatementDetails;
