import moment from "moment-timezone";

const dateChange = (i, timeZone) => {
  return moment(i).tz(timeZone).format("YYYY-MM-DDTHH:mm:ss");
};
export const UserDateFormat = (date, dateformate, timezone) => {
  // const userDetails = useUserDetails()
  // console.log(userDetails, "userDetailsuserDetailsuserDetailsuserDetailsHHHH");
  if (dateformate === "1") {
    // "DD/MM/YYYY"
    // return new Date(date).toLocaleDateString("en-GB", { timeZone:userDetails.ing_timezone });
    let dates = moment(date).tz(timezone)?.format("YYYY-MM-DDTHH:mm:ss");
    const inputDate = new Date(dates);
    const year = inputDate.getFullYear();
    const month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
    const day = ("0" + inputDate.getDate()).slice(-2);
    const convertedDateString = `${day}/${month}/${year}`;
    return convertedDateString;
  }
  if (dateformate === "2") {
    // let mmdd = new Date(date).toLocaleDateString("en-GB", { timeZone:timezone });
    let dates = moment(date).tz(timezone)?.format("YYYY-MM-DDTHH:mm:ss");
    // "MM/DD/YYYY"
    const inputDate = new Date(dates);
    const year = inputDate.getFullYear();
    const month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
    const day = ("0" + inputDate.getDate()).slice(-2);
    const convertedDateString = `${month}/${day}/${year}`;
    return convertedDateString;
  } else {
    // let mmdd = new Date(date).toLocaleDateString("en-GB", { timeZone:timezone });
    let dates = moment(date).tz(timezone)?.format("YYYY-MM-DDTHH:mm:ss");
    // "YYYY/MM/DD"
    const inputDate = new Date(dates);
    const year = inputDate.getFullYear();
    const month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
    const day = ("0" + inputDate.getDate()).slice(-2);
    const convertedDateString = `${year}/${month}/${day}`;
    return convertedDateString;
  }
};
export const UserDateFormatwithTimeZone = (date, dateformate) => {
  // const userDetails = useUserDetails()
  // console.log(userDetails, "userDetailsuserDetailsuserDetailsuserDetailsHHHH");
  if (dateformate === "1") {
    // "DD/MM/YYYY"
    // return new Date(date).toLocaleDateString("en-GB", { timeZone:userDetails.ing_timezone });
    // let dates =  moment(date).tz(timezone)?.format("YYYY-MM-DDTHH:mm:ss")
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
    const day = ("0" + inputDate.getDate()).slice(-2);
    const convertedDateString = `${day}/${month}/${year}`;
    return convertedDateString;
  }
  if (dateformate === "2") {
    // let mmdd = new Date(date).toLocaleDateString("en-GB", { timeZone:timezone });
    // let dates =  moment(date).tz(timezone)?.format("YYYY-MM-DDTHH:mm:ss");
    // "MM/DD/YYYY"
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
    const day = ("0" + inputDate.getDate()).slice(-2);
    const convertedDateString = `${month}/${day}/${year}`;
    return convertedDateString;
  } else {
    // let mmdd = new Date(date).toLocaleDateString("en-GB", { timeZone:timezone });
    // let dates =  moment(date).tz(timezone)?.format("YYYY-MM-DDTHH:mm:ss");
    // "YYYY/MM/DD"
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = ("0" + (inputDate.getMonth() + 1)).slice(-2);
    const day = ("0" + inputDate.getDate()).slice(-2);
    const convertedDateString = `${year}/${month}/${day}`;
    return convertedDateString;
  }
};
export const documentTime = (date, dateformate) => {
  var dateObj = new Date(date);

  var year = dateObj.getUTCFullYear();
  var month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0");
  var day = dateObj.getUTCDate().toString().padStart(2, "0");
  if (dateformate === "1") {
    // "DD/MM/YYYY"

    const convertedDateString = `${day}/${month}/${year}`;
    return convertedDateString;
  }
  if (dateformate === "2") {
    // "MM/DD/YYYY"

    const convertedDateString = `${month}/${day}/${year}`;
    return convertedDateString;
  } else {
    // "YYYY/MM/DD"

    const convertedDateString = `${year}/${month}/${day}`;
    return convertedDateString;
  }
};
export const DateFormate = (item) => {
  return `${new Date(item).getDate() + 1 >= 10
      ? `${new Date(item).getDate()}`
      : `0${new Date(item).getDate()}`
    }/${new Date(item).getMonth() + 1 >= 10
      ? `${new Date(item).getMonth() + 1}`
      : `0${new Date(item).getMonth() + 1}`
    }/${new Date(item).getFullYear()}`;
};

export const yearDate = (item) => {
  const birthDate = new Date(item);
  const currentDate = new Date();
  const ageDiff = currentDate - birthDate;
  const ageDate = new Date(ageDiff);
  const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
  return calculatedAge;
};

export const DobFormeat = (dobformeat) => {
  let y = dobformeat.slice(0, 4);
  let m = dobformeat.slice(5, 7);
  let d = dobformeat.slice(8, 10);
  return `${y}-${m}-${d}T10:00:00.000Z`;
};

export const dateChangeByTimezone = (i, timeZone) => {
  return moment(i).tz(timeZone).format("YYYY-MM-DDTHH:mm:ss");
};
// export const HoursMinutesFormat = (d, s, timeZone) => {
//   const timeString = dateChange(d, timeZone);
//   const date = new Date(timeString);
//   const time = date.toLocaleString("en-US", {
//     hour: "2-digit",
//     minute: "2-digit",
//     hour12: s,
//   });
//   return time;
// };

export const HoursMinutesFormat = (d, timeFormat, timeZone) => {
  if (d) {
    const date = new Date(d);

    if (!timeFormat) {
      const options = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: timeFormat,
        timeZone,
      };
      return date.toLocaleTimeString("en-US", options);
    } else {
      const options = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: timeFormat,
        timeZone,
      };
      return date.toLocaleTimeString("en-US", options);
    }
  }
};

export const dateSave = (i, timeZone) => {
  return moment(i).tz(timeZone).format();
};

export const dateChangeUtc = (da) => {
  let strStartdate = String(da);
  let startdateConv = `${strStartdate.slice(0, 3)}, ${strStartdate.slice(
    4,
    7
  )} ${strStartdate.slice(8, 10)}, ${strStartdate.slice(11, 15)}`;
  return startdateConv;
};

export const formatDateTo20120201 = (inputDate) => {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}${month}${day}`;
};

export const formatDateToDashSeparated = (inputDate) => {
  const year = inputDate.slice(0, 4);
  const month = inputDate.slice(4, 6);
  const day = inputDate.slice(6, 8);

  return `${year}-${month}-${day}`;
};

export const HoursMinutesFormatDataExport = (d, timeFormat) => {
  if (d) {
    if (!timeFormat) {
      const date = new Date(d);
      let hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedHours = hours < 10 ? `0${hours}` : hours;
      return `${formattedHours}:${formattedMinutes}`;
    } else {
      const date = new Date(d);
      let hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      const seconds = date.getUTCSeconds();
      let ampm = "AM";

      if (hours >= 12) {
        ampm = "PM";
        if (hours > 12) {
          hours -= 12;
        }
      }

      if (hours === 0) {
        hours = 12;
      }

      // Format minutes with leading zero if less than 10
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedHours = hours < 10 ? `0${hours}` : hours;

      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    }
  }
};

export const getDateDifferenceInDays = (scheduleData) => {
  const today = new Date();
  const targetDate = new Date(scheduleData);
  console.log(scheduleData, "iiiiiiiiiiiiii", targetDate);

  const differenceMs = targetDate - today;

  const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  console.log(differenceDays, "ggggggggggggggg");
  return differenceDays;
};

export const placeholderDateFormat = (e) => {
  if (e === "1") {
    return 'DD/MM/YYYY'
  } else if (e === "2") {
    return 'MM/DD/YYYY'
  } else {
    return 'YYYY/MM/DD'
  }

}
