import React from "react";
import { useTable } from "react-table";
import { HoursMinutesFormat, UserDateFormat, getDateDifferenceInDays } from "../../utilities/DateFormate";
import expired from "../../images/expired.svg";
export default function ActiveViewTable({ data, datas, date, type ,login}) {
  let types= type == "1"? "Treatment date": type == "2"? "Initial PROMs date": ""
  const getStatusClass = (status) => {
    switch (status) {
      case "Upcoming":
        return "incomplete";
      case "Expired":
      case "Pending":
        return "expired";
      case "Complete":
        return "text-success";
      default:
        return "";
    }
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "date",

        Cell: (row) => {
          return (
            <>
              <span style={{ fontWeight: 800, color: "#000" }}>
                {row.row.original.label}
              </span>
              <p style={{ display: "inline" }}>
                From{" "}
                {types}
              </p>
            </>
          );
        },
      },
      {
        Header: "",
        accessor: "status",
        Cell: (row) => {
          return (
            <>
              {row.row.original.status === "Upcoming" && (
                // <span className="incomplete">{row.row.original.status}</span>
               
                <b className="expired"> {UserDateFormat(
                  row.row.original.date,
                  login.ing_date_formate,
                  login.ing_timezone
                )}
<br></br>
                {
                  HoursMinutesFormat(
                    row.row.original.date,
                    login.ing_timeformat === "1" ? true : false,
                    login.ing_timezone
                  )}</b>
              )}

              {row.row.original.status === "Expired" && (
                <div>
                  <img src={expired} alt="" className="me-2" />
                  <span className="expired">{row.row.original.status}</span>
                </div>
              )}

              {row.row.original.status === "Complete" && (
                <span className="text-success">{row.row.original.status}</span>
              )}

              {row.row.original.status === "Pending" && (
                <span className="expired">{row.row.original.status}</span>
              )}

              {/* <span
                className={` ${
                  row.row.original.status === "Upcoming"
                    ? "incomplete"
                    : row.row.original.status === "Expired"
                    ? "expired"
                    : row.row.original.status === "Complete"
                    ? "text-success"
                    : row.row.original.status === "Pending"
                    ? "expired"
                    : ""
                } `}
              >
                {row.row.original.status}{" "}
              </span> */}
            </>
          );
        },
      },
    ],
    [type]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  const timestamp = date;

  const datee = new Date(timestamp);

  const options = { month: "long", day: "2-digit", year: "numeric" };
  const formattedDate = datee.toLocaleDateString("en-US", options);

  return (
    <div className="" id="ripScrollableDivf">
      <div style={{ textAlign: "center" }}>
        <label style={{ fontWeight: 800 }}>
          {datas.speciality === "4"
            ? "Shoulder"
            : datas.speciality === "6"
            ? "Hip"
            : datas.speciality === "21"
            ? "Foot and Ankle"
            : datas.speciality === "22"
            ? "Elbow"
            : datas.speciality === "5"
            ? "Knee"
            : datas.speciality === "23"
            ? "Hand and Wrist"
            : "General"}
          -{datas.title}
        </label>
      </div>
      <div style={{ textAlign: "center" }}>
        <label>
          Surgery:
          {datas?.primary_diagnosis?.primary
            ? datas?.primary_diagnosis?.primary
            : datas?.primary_diagnosis?.non_surgicalinput
            ? datas?.primary_diagnosis?.non_surgicalinput
            : ""}
        </label>
      </div>

      <div className="row" style={{ textAlign: "center" }}>
        <div className="col-1">
          <span className="circle" style={{ marginLeft: "15px" }}></span>
        </div>
        <div className="col-6 fw-bold text-start ps-4">
          <label style={{ fontWeight: 800, color: "#000" }}>
            {types}
          </label>
        </div>
        <div className="col-5 d-flex">
          <label style={{ fontWeight: 500, color: "#000" }}>
            {formattedDate}
            {/* {formattedDate ==="1" ?"Treatment Date" : formattedDate ==="2" ? "proms initial date":""} */}
          </label>
        </div>
      </div>

      <table {...getTableProps()} className="table-borderless">
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <>
                {/* <tr style={{}} className="">
                  <td>
                    <span className="circle"></span>
                  </td>
                  <td className="td-bold">Treatment Date</td>
                  <td style={{ fontWeight: 500, color: "#000" }}>
                    {formattedDate}
                  </td>
                </tr> */}

                <tr {...row.getRowProps()} style={{}}>
                  <td>
                    <span className="circle"></span>
                  </td>
                  {row.cells.map((cell) => (
                    <td
                      className=""
                      {...cell.getCellProps([
                        { className: cell.column.className },
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
