import React from "react";
import "../../../../css/ViewTreatmentForm.css";
import { UserDateFormat } from "../../../../utilities/DateFormate";
import { useSelector } from "react-redux";
function PatientDetails({Personaldata}) {

const login = useSelector((state) => state.mainReducer.loginData.arr);
let dateformate=login.ing_date_formate
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-header">
          <h2>Personal Details</h2>
          <div className="treatment-header-right"></div>
        </div>

        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-col">
              <label>Name:</label>
             {Personaldata.ig_fname} {Personaldata.ig_lname}
            </div>

            <div className="treatment-col">
              <label>Date Of Birth:</label>
              {UserDateFormat(Personaldata.ig_dob,dateformate,login.ing_timezone)}
            </div>
          </div>

          <div className="treatment-row">
            <div className="treatment-col">
              <label>Sex:</label>
              {Personaldata.ig_gender==="1"?"Male":Personaldata.ig_gender==="2"?"Female":"Do not want to disclose"}
            </div>

            <div className="treatment-col">
              <label>Phone:</label>
             {Personaldata.ig_phone}
            </div>
          </div>

          <div className="treatment-row">
            <div className="treatment-col">
              <label>Account :</label> {Personaldata.account_type}
            </div>

            <div className="treatment-col">
              <label>Medicare Number: </label> {Personaldata.ig_medicare_no}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PatientDetails;
