import React, { useEffect, useState } from 'react'
import "./mermaidstyle.css"
import logo from './img/mermaid-logo.png'
import scan from './img/scan.png'
import carbon from './img/carbon.png'
import { PDFViewer } from "@react-pdf/renderer";
import MermaidPdfView from './MermaidPdfView';
import Modal from "react-bootstrap/Modal";
import Button from "../../../custom/button/Button";
import useAxiosUpload from "../../../../axiosinstanceUpload";
import { pdf } from "@react-pdf/renderer";
import { UserDateFormat } from "../../../../utilities/DateFormate";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
export default function MermaidRadiology({ requried, userData, otherReq, reason, providerId,datarequried ,title}) {
    const navigate = useNavigate();
    const requriedTest = requried.filter((row) => {
      return row.label !=="Other";
    }).map((row)=>{
        return row.label 
    });
    const axiosInstanceUpload = useAxiosUpload();
    const login = useSelector((state) => state.mainReducer.loginData.arr);
    const { akId } = useParams();
    const [name, setName] = useState("")
    const [address, setaddress] = useState(" ")
    const [medicare, setmedicare] = useState(" ")
    const [investigations, setinvestigations] = useState("")
    const [doctor, setdoctor] = useState(" ")
    const [address2, setaddress2] = useState(" ")
    const [provider, setprovider] = useState(" ")
    const [phone, setphone] = useState(" ")
    const [clinical, setclinical] = useState(" ")
    const [copy, setcopy] = useState(" ")
    const [copy2, setcopy2] = useState(" ")
    const [referral, setreferral] = useState(" ")
    const [additional, setadditional] = useState(" ")
    const [isApiPending, setIsApiPending] = useState(false);
    useEffect(()=>{
        setinvestigations("Examination Required:"+"\n"+requriedTest.join("\n").slice(0,250)+"\n"+"\n"+"Reason for Investigation:"+"\n"+reason.slice(0,250)+"\n")
    },[reason,requried])

    const handleName = (e) => {
        setName(e.target.value)
    }
    const handleAddress = (e) => {
        setaddress(e.target.value)
    }
    const handleMedicare = (e) => {
        setmedicare(e.target.value)
    }
    const handleInvestigations = (e) => {
        setinvestigations(e.target.value)
    }
    const handleDoctor = (e) => {
        setdoctor(e.target.value)
    }
    const handleAddress2 = (e) => {
        setaddress2(e.target.value)
    }
    const handleProvider = (e) => {
        setprovider(e.target.value)
    }
    const handlePhone = (e) => {
        setphone(e.target.value)
    }
    const handleClinical = (e) => {
        setclinical(e.target.value)
    }
    const handleCopy = (e) => {
        setcopy(e.target.value)
    }
    const handleCopy2 = (e) => {
        setcopy2(e.target.value)
    }
    const handleReferral = (e) => {
        setreferral(e.target.value)
    }
    const handleAdditional = (e) => {
        setadditional(e.target.value)
    }
    const [save, setSave] = useState(false);
    const handleCloseShow = () => {
        setSave(false);
    };

    useEffect(()=>{
   setName(userData.name)
   setaddress(userData.address)
   setphone(userData.mobile)
   setdoctor(login.ing_fullname)
   setprovider(login.ing_provider_number)
   setmedicare(userData.card_number)
    },[userData])

    const generatePDFDocument = async () => {
        const blob = await pdf(
            <MermaidPdfView
            name={name}
            address={address}
            medicare={medicare}
            investigations={investigations}
            doctor={doctor}
            address2={address2}
            provider={provider}
            phone={phone}
            clinical={clinical}
            copy={copy}
            copy2={copy2}
            referral={referral}
            additional={additional} 
            login={login}
            reason={reason}
            requriedTest={requriedTest}
            />
        ).toBlob();
        const formData = new FormData();
    
        formData.append(
          "file",
          blob,
          `${name.split(" ").join("_")}_${"Mermaid"}.pdf`
        );
        setIsApiPending(true);
        const res = await axiosInstanceUpload.current.post(
          `extglobal/template_pdf_upload?ak_dc=${login.ing_ak_id
          }&ak_pt=${window.atob(akId)}&template_type=${"6"}&auto_id=${providerId}&scan_types=${datarequried}&template_name=${title}`,
          formData
        ).then((res)=>{
            if (res.status === 201) {
                navigate(`/request-view/${6}`);
              }
        }).finally(() => {
            setIsApiPending(false); // Set API request to not pending after it completes
          });     
      };
    return (
        <>
            <div className=" col-12 text-end mb-4 ">
                <span className="c_pointer  span-btn" onClick={() => setSave(true)}>
                    View & Generate
                </span>
            </div>
            {/* <PDFViewer
                style={{ height: "80vh", width: "100%", marginTop: "10px" }}
            >
                <MermaidPdfView
                    name={name}
                    address={address}
                    medicare={medicare}
                    investigations={investigations}
                    doctor={doctor}
                    address2={address2}
                    provider={provider}
                    phone={phone}
                    clinical={clinical}
                    copy={copy}
                    copy2={copy2}
                    referral={referral}
                    additional={additional} />
            </PDFViewer> */}
            <div className='whitebox'>
                <div className='row'>
                    <div className='col-md-4 col-sm-4'>
                        <div className='logo'>
                            <img className='img-fluid w-50' src={logo} alt='Mermaid Logo' />
                        </div>
                    </div>
                    <div className='col-md-4 col-sm-4'>
                        <div className='address '>
                            <b className='bold_'>Mermaid Beach Radiology </b> <br />
                            <p className='para_ mb-0'>2469 Gold Coast Highway,</p>
                            <p className='para_ mb-0'>Mermaid Beach, QLD, 4218</p>
                            <b className='bold_'>info@mermaidbeachradiology.com.au </b><br />
                            <b className='bold_'> mermaidbeachradiology.com.au </b>
                        </div>
                    </div>
                    <div className='col-md-4 col-sm-4'>
                        <div className='d-flex'>
                            <div className='telphonic bold_ w-100'>
                                <span className='tel_col'>tel </span>07 56 199 499<br />
                                <span className='tel_col'>fax </span>07 2102 5825

                            </div>
                            <div>
                                <img className='img-fluid w-100' src={scan} alt='' />
                            </div>
                        </div>
                    </div>

                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='mt-3'>
                            <h4 className='f_24 bold_'>Patient Details:</h4>
                            <div className='d-flex'>
                                <label className='label_col me-3'>Name:</label>
                                <input
                                    style={{
                                        paddingLeft: "10px",
                                        backgroundColor: "#f1f4ff",
                                        border: 0,
                                        width: "100%",
                                    }}
                                    value={name}
                                    onChange={handleName}

                                />
                            </div>
                            <div className='d-flex mt-3'>
                                <label className='label_col me-3'>Address:</label>
                                <input
                                    style={{
                                        paddingLeft: "10px",
                                        backgroundColor: "#f1f4ff",
                                        border: 0,
                                        width: "100%",
                                    }}
                                    value={address}
                                    onChange={handleAddress}
                                />
                            </div>
                            <div className='d-flex mt-3'>
                                <label className='label_col me-3'>Medicare:</label>
                                <input
                                    style={{
                                        paddingLeft: "10px",
                                        backgroundColor: "#f1f4ff",
                                        border: 0,
                                        width: "100%",
                                    }}
                                    value={medicare}
                                    onChange={handleMedicare}

                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                    </div>

                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='mt-5'>
                            <h4 className='f_24 bold_'>Investigations Required:</h4>
                            <div className='d-flex'>
                                <textarea
                                    style={{
                                        paddingLeft: "10px",
                                        backgroundColor: "#f1f4ff",
                                        border: 0,
                                        width: "100%",
                                        height: "20vh",
                                    }}
                                    
                                     maxLength={500}
                                    value={investigations}
                                    onChange={handleInvestigations}
                                />
                            </div>
                            <div className='text-end'>
                            <span className='text-end'>500 characters are allowed</span>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <h4 className='f_24 bold_'>Referring Doctor Details:</h4>
                            <div className='d-flex'>
                                <label className='label_col me-3'>Doctor:</label>
                                <input
                                    style={{
                                        paddingLeft: "10px",
                                        backgroundColor: "#f1f4ff",
                                        border: 0,
                                        width: "100%",
                                    }}
                                    maxlength="500"
                                    value={doctor}
                                    onChange={handleDoctor}

                                />
                            </div>
                            <div className='d-flex mt-3'>
                                <label className='label_col me-3'>Address:</label>
                                <input
                                    style={{
                                        paddingLeft: "10px",
                                        backgroundColor: "#f1f4ff",
                                        border: 0,
                                        width: "100%",
                                    }}
                                    value={address2}
                                    onChange={handleAddress2}

                                />
                            </div>
                            <div className='d-flex mt-3'>
                                <label className='label_col me-3'>Provider No.</label>
                                <input
                                    style={{
                                        paddingLeft: "10px",
                                        backgroundColor: "#f1f4ff",
                                        border: 0,
                                        width: "100%",
                                    }}
                                    value={provider}
                                    onChange={handleProvider}

                                />
                            </div>
                            <div className='d-flex mt-3'>
                                <label className='label_col me-3'>Phone No.</label>
                                <input
                                    style={{
                                        paddingLeft: "10px",
                                        backgroundColor: "#f1f4ff",
                                        border: 0,
                                        width: "100%",
                                    }}
                                    value={phone}
                                    onChange={handlePhone}
                                />
                            </div>
                        </div>
                        <div className='mt-5'>
                            <h4 className='f_24 bold_'>Referring Doctor Signature:</h4>
                            <div className='doc_sign mt-5'>{login.ing_sign_type === 2 ? <img width="25%" src={login.ing_sign}></img> : login.ing_sign}</div>
                        </div>
                        <div className='mt-3'>
                            <p className='para_ f_20'>(Doctor’s Name, Provider Number and Date are a legal requirement)</p>
                            <p className='para_ f_20'>Thankyou for choosing <b className='bold_ f_20'>Mermaid Beach Radiology</b></p>
                        </div>


                    </div>
                    <div className='col-md-6'>
                        <div className='mt-5'>
                            <h4 className='f_24 bold_'>Clinical Notes:</h4>
                            <div className='d-flex'>
                                <textarea
                                    style={{
                                        paddingLeft: "10px",
                                        backgroundColor: "#f1f4ff",
                                        border: 0,
                                        width: "100%",
                                        height: "30vh",
                                    }}
                                    maxLength={500}
                                    value={clinical}
                                    onChange={handleClinical}
                                />
                            </div>
                                <div className='text-end'>
                            <span className='text-end'>500 characters are allowed</span>
                            </div>
                        </div>
                        <div className='mt-3'>
                            <h4 className='f_24 bold_'>Copy of Report to:</h4>
                            <input
                                style={{
                                    paddingLeft: "10px",
                                    backgroundColor: "#f1f4ff",
                                    border: 0,
                                    width: "100%",
                                    height: "5vh",
                                    borderBottom: "2px solid #50b3e5",
                                }}
                                value={copy}
                                onChange={handleCopy}
                            />
                            <input
                                style={{
                                    marginTop: "10px",
                                    paddingLeft: "10px",
                                    backgroundColor: "#f1f4ff",
                                    border: 0,
                                    width: "100%",
                                    height: "5vh",
                                    borderBottom: "2px solid #50b3e5",
                                }}

                                value={copy2}
                                onChange={handleCopy2}
                            />
                        </div>
                        <div className='d-flex mt-3'>
                            <h4 className='f_24 bold_ me-2'>Referral Date:</h4>
                  
                            <input
                                style={{
                                    paddingLeft: "10px",
                                    backgroundColor: "#f1f4ff",
                                    border: 0,
                                    width: "30%",
                                    borderBottom: "2px solid #50b3e5",
                                   
                                }}
                                type="date"
                                placeholder="dd-mm-yyyy"
                                value={referral}
                                onChange={handleReferral}
                            />

                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='mt-3'>
                            <h4 className='f_24 bold_ me-2'>Additional Clinical Information:</h4>
                            <textarea
                                style={{
                                    paddingLeft: "10px",
                                    backgroundColor: "#f1f4ff",
                                    border: 0,
                                    width: "100%",
                                    height: "20vh",
                                }}
                                maxLength={500}
                                value={additional}
                                onChange={handleAdditional}
                            />
                            <div className='text-end'>
                            <span className='text-end'>500 characters are allowed</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='w-75'>
                        <h4 className='f_40 foot_col'>Mermaid Beach Radiology is the only certified
                            Carbon Neutral Radiology Clinic in Australia</h4>
                    </div>
                    <div className='w-25'>
                        <img className='img-fluid' src={carbon} alt='carbon netural radiology' />
                    </div>

                </div>
            </div>
            <Modal
                show={save}
                onHide={handleCloseShow}
                dialogClassName="modal-xl patient_notes_popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">{"Save"}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <Button
                        buttonStyle="btn_fill float-end"
                        onClick={() => generatePDFDocument()}
                        value="Save"
                        disabled={isApiPending}
                    />
                    <PDFViewer
                        style={{ height: "80vh", width: "100%", marginTop: "10px" }}
                    >
                        <MermaidPdfView
                   name={name}
                   address={address}
                   medicare={medicare}
                   investigations={investigations}
                   doctor={doctor}
                   address2={address2}
                   provider={provider}
                   phone={phone}
                   clinical={clinical}
                   copy={copy}
                   copy2={copy2}
                   referral={referral}
                   additional={additional} 
                   login={login}
                   reason={reason} 
                   requriedTest={requriedTest}
                   />
                    </PDFViewer>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "flex-end" }}>
                    {/* <Button
            onClick={handleSubmit}
            value="Save"
            buttonStyle="btn_fill mr_20 "
          /> */}
                </Modal.Footer>
            </Modal>
        </>
    )
}
