import React from "react";
import timelineStyle from "./ManagementPlan.module.css";
import { Link, useParams } from "react-router-dom";
import viewIcon from "../../../../images/view-right-arrow.svg";
// import Button from "../../../custom/button/Button";
import arrowRightIcon from "../../../../images/arrow-bright.svg";
// import pendingIcon from "../../../../images/caseapprovedicon.svg";
import useTranslation from "../../../customHooks/translations";
import { useSelector } from "react-redux";
import { UserDateFormat } from "../../../../utilities/DateFormate";

function ManagementPlan({ org, formType, manageMentdata, name1, age1 }) {
  const translation = useTranslation();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const { akid } = useParams();
  // const [showMore, setShowMore] = useState(false);

  return (
    <div className={`mmPlanSec ${timelineStyle.mm_plan_sec}`}>
      <div className="whitebox">
        <div className="row">
          <div className="col-md-7 col-sm-7">
            <h4 className={`${timelineStyle.top_left_pf_txt}`}>
              {translation.managementPlans}
            </h4>
          </div>
          <div className="col-md-5 col-sm-5 text-end">
            <p className="updated_date f_12">
              <Link
                to={
                  "/management-plan/" +
                  akid + `?&name=${name1}&age=${age1}`
                }
              >
                {translation.viewall}{" "}
                <img src={viewIcon} className="ml_4" alt="icon" />
              </Link>
            </p>
          </div>
        </div>
        <div className="row">
          <div className=" col-md-12 mxh_520 scrollbar_vert">
            <ul className={`${timelineStyle.stepProgress}`}>
              {manageMentdata.length === 0 ? <div className="no_data p-0">
                {translation.No_data_found}
              </div> :
                manageMentdata.map((row) => (
                  <li
                    className={`${timelineStyle.stepProgress_item} ${timelineStyle.is_submit}`}
                  >
                    <span
                      className=""
                      style={{
                        display: "inline-block",
                        position: "absolute",
                        top: "2",
                        left: "-20px",
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%",
                        border: `2px solid ${row.color_code}`,
                        backgroundColor: `${row.color_code}`,
                      }}
                    ></span>
                    <Link
                      to={
                        "/view-management-plan/" +
                        `${akid}/${window.btoa(
                          row.resource_id
                        )}/?org=${window.btoa(row.org_id)}&formType=${window.btoa(
                          row.type_id
                        )}`
                      }
                    >
                      <img
                        src={arrowRightIcon}
                        alt="icon"
                        className="float-end"
                      />
                    </Link>
                    <p>
                      {/* {UserDateFormat(row.assessment_date, login.ing_date_formate, login.ing_timezone)} - {row.evaluted_value ? row.evaluted_value + "-" : " "}  {row.type} */}
                      {row.assessment_date} -  {row.type}
                    </p>
                  </li>
                ))}


            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManagementPlan;
