import React from "react";
import {
    Page,
    View,
    Document,
    StyleSheet,
    Image,
} from "@react-pdf/renderer";
import Html from "react-pdf-html";
// import ReactHtmlParser from "react-html-parser";
import ReactDOMServer from "react-dom/server";

export default function MermaidPdfView({ name, address, medicare, investigations, doctor, address2, provider, phone, clinical, copy, copy2, referral, additional, login, reason, requriedTest }) {
    // console.log(investigations, "loginloginloginloginloginloginlogin");
    const styles = StyleSheet.create({
        image: {

            width: '100%',
            // marginLeft:30,
            paddingLeft: 10,
            marginTop: 20,
            //     paddingLeft:210,
            paddingRight: 10,
            //   justifyContent:"center",
            //   alignContent:"center",
            //   alignItems:"center",
        },

    });
    const headbar = (
        <>
            <style>
                {`
                     .bold_ {
                        color: #231e5f;
                        font-weight: 700;
                        font-size: 16px;
                    }
                    .para_ {
                        color: #253d90;
                        font-weight: 400;
                        font-size: 12px;
                        margin-left:20px
                    }
                    .para_exm {
                        color: #253d90;
                        font-weight: 400;
                        font-size: 12px;
                     
                    }
                    .tel_col{
                        color: #50b3e5;
                        font-size: 16px;
                    }
                   
                    .f_10{
                        font-size: 10px;
                    }
                    .f_20{
                        font-size: 20px;
                    }
                    .f_40{
                        font-size: 40px;
                    }
                    .label_col{
                        color: #231e5f;
                        font-weight: 400;
                        font-size: 10px;
                    }
                    .doc_sign{
                        border-bottom: 2px solid #50b3e5;
                    }
                    .foot_col{
                        color: #28b04a;
                        font-weight: 700;
                    }
                    .d-flex{
                        display:flex;
                    }
                    .mt-3{
                        margin-top:3px;
                    }
                    .mt-15{
                        margin-top:15px;
                    }
                    .mt-40{
                        margin-top:32%;
                    }
                    .mb-3{
                        margin-bottom:5px;
                    }
                    .w-50{
                        width:50%;
                        
                    }
                    .headtxt {
                        margin-top:10px;
                    }
                    
               `}
            </style>
            <div className="header" style={{ flexDirection: "row", padding: 10 }}>


                <div className="" style={{ width: '50%' }}>
                    <div className="headtxt">
                        <div className='bold_ mb-3'>Patient Details:</div>
                        <div className='d-flex'>
                            <label className='label_col me-3'>Name:  </label>
                            <span className="para_">{name}</span>
                        </div>
                        <div className='d-flex mt-3'>
                            <label className='label_col me-3'>Address:  </label>
                            <span className="para_">{address}</span>
                        </div>
                        <div className='d-flex mt-3'>
                            <label className='label_col me-3'>Medicare:  </label>
                            <span className="para_">{medicare}</span>
                        </div>
                    </div>
                    <div className="d-flex mt-15">
                        <div className=' bold_ mb-3'>Investigations Required:
                            <div>
                                <pre id="data" className="para_exm">
                                    {investigations}
                                </pre>
                                {/* <span className="para_">{investigations}</span> */}

                                {/* <span className="para_">
                                    Examination Required: <br />
                                    {requriedTest.join("\n").slice(0,250)+investigations} <br /><br/>
                                    Reason for Investigation: <br />
                                   {reason.slice(0,250)+investigations}
                                </span> */}

                            </div>

                        </div>


                    </div>

                    <div className="headtxt">
                        <div className='bold_ mb-3'>Referring Doctor Details:</div>
                        <div className='d-flex'>
                            <label className='label_col me-3'>Doctor: </label>
                            <span className="para_">{doctor}</span>
                        </div>
                        <div className='d-flex mt-3'>
                            <label className='label_col me-3'>Address:  </label>
                            <span className="para_">{address2}</span>
                        </div>
                        <div className='d-flex mt-3'>
                            <label className='label_col me-3'>Provider No.  </label>
                            <span className="para_">{provider}</span>
                        </div>
                        <div className='d-flex mt-3'>
                            <label className='label_col me-3'>Phone No.  </label>
                            <span className="para_">{phone}</span>
                        </div>
                    </div>
                    <div className="headtxt">
                        <div className='bold_ mb-3'>Referring Doctor Signature:
                            <div>
                                <span className="para_">{login?.ing_sign_type === 2 ? <img width="25%" src={login?.ing_sign}></img> : login?.ing_sign}</span>
                            </div>

                        </div>
                        <div className='d-flex'>

                            <span className="f_10 bold_">(Doctor’s Name, Provider Number and Date are a legal requirement)<br></br>

                                Thankyou for choosing Mermaid Beach Radiology</span>
                        </div>

                    </div>
                </div>
                <div className="" style={{ width: '50%' }}>
                    <div className="d-flex mt-40">

                        <div className='bold_ mb-3'>Clinical Notes:
                            <div>
                                <span className="para_" style={{height:50}}>{clinical}</span>

                            </div>
                        </div>
                    </div>
                    <div className="headtxt">
                        <div className='bold_ mb-3'>Copy of Report to:
                            <div>
                                <span className="para_">{copy}</span>
                            </div>
                            <div>
                                <span className="para_">{copy2}</span>
                            </div>

                        </div>
                        <div className='d-flex'>
                            <label className='label_col me-3'>Referral Date: </label>
                            <span className="para_">{referral}</span>
                        </div>

                    </div>
                </div>

            </div>
            <div className="" style={{ flexDirection: "row", padding: 10 }}>

                <div className="headtxtv mt-15">
                    <div className='bold_ mb-3'>Additional Clinical Information:
                        <div>
                            <span className="para_">{additional}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
    const html = ReactDOMServer.renderToStaticMarkup(headbar);
    const header = ReactDOMServer.renderToStaticMarkup(headbar);
    return (
        <>
            <Document>
                <Page
                    style={{
                        paddingBottom: "10px",
                    }}
                >
                    <View fixed style={{ flexDirection: "row" }}>
                        <Image
                            style={styles.image}
                            source={require("./img/header_referral.png")}
                            resizeMode="cover"
                        />
                    </View>

                    <View style={{ justifyContent: "space-between" }}>
                        <Html>{html}</Html>
                    </View>
                    <View fixed style={{ flexDirection: "row", bottom: 0 }}>
                        <Image
                            style={styles.image}
                            source={require("./img/footer_marmaid.png")}
                            resizeMode="cover"
                        />
                    </View>
                </Page>
            </Document>
        </>
    )
}
