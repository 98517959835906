/* eslint-disable jsx-a11y/anchor-has-content */

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import document from "../../images/dasboardicon/Document_White.svg";
import Modal from "react-bootstrap/Modal";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";

//import icons from react icons
// import { FaList, FaRegHeart } from "react-icons/fa";
// import {
//   FiHome,
//   FiLogOut,
//   FiArrowLeftCircle,
//   FiArrowRightCircle,
// } from "react-icons/fi";
// import { RiPencilLine } from "react-icons/ri";
// import { BiCog } from "react-icons/bi";

//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import "./LeftSidebar.css";
import arrowCollapse from "../../images/m-arrow-left.svg";
import homeIconFill from "../../images/icon1-fill.svg";
import patientsIconFill from "../../images/icon2-fill.svg";
import mgmtPlanIconFill from "../../images/icon3-fill.svg";
import opNotesIconFill from "../../images/icon4-fill.svg";
import settingsIconFill from "../../images/Settings.svg";
import calenderIconFill from "../../images/Calendar.svg";
import pathologyPlanIconFill from "../../images/sidebar/i4.svg";
import dataaExportIconFill from "../../images/sidebar/i6.svg";
import useAxios from "../../axiosinstance";
import med from "../../images/med.svg";
import Settings from "../settings/Settings";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "../customHooks/translations";
import { accessFeatures } from "../../redux/actions";
const LeftSidebar = (props) => {
  const translation = useTranslation();
  // const { keycloak, initialized } = useKeycloak();
  //assigning location variable
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const freatures = useSelector((state) => state.mainReducer.featuresData.arr);
  const axiosInstance = useAxios();
  const location = useLocation();
  const dispatch = useDispatch();
  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  //create initial menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(false);

  //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  // const appLogout = () => {
  //   UserService.doLogout()
  //   localStorage.setItem('local_auth', JSON.stringify(false));
  //   localStorage.removeItem("local_token");

  // };

  const [show, setShow] = useState(false);

  // const [items, setItems] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // menu lock
  useEffect(() => {

    const getData = async () => {
      const response = await axiosInstance.current.get(

        `extapp/doctors/doctorFeature?ak_id=${login.ing_ak_id}`
      );
      if (response.status === 200) {
        console.log(response.data.features_data
          , "freatures")
        // setItems(response.data.features_data);
        dispatch(accessFeatures(response.data.features_data))
      }
    };
    getData();
  }, []);

  console.log(freatures.Pacs, "freatures freatures")
  return (
    <>
      {/* collapsed props to change menu size using menucollapse state */}
      <ProSidebar collapsed={menuCollapse} id="sidebar_main">
        <SidebarHeader>
          <br />
          <div
            className="closemenu"
            onClick={() => {
              menuIconClick();
              props.onClick();
            }}
          >
            {/* changing menu collapse icon on click */}
            {<img src={arrowCollapse} className="left-logo" alt="logo" />}
          </div>
        </SidebarHeader>
        <SidebarContent className="left_sidebar_body">
          <Menu iconShape="square">
            <MenuItem
              className={splitLocation[1] === "" ? "active" : ""}
              icon={
                <img src={homeIconFill} alt="menu-icon" className="nav_icon" />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.home}
            >
              {translation.home}
              <Link to="/" />
            </MenuItem>
            <MenuItem
              className={splitLocation[1] === "schedular" ? "active" : ""}
              icon={
                <img
                  src={calenderIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.calender}
            >
              {translation.calender}
              <Link to="/schedular" />
            </MenuItem>
            <MenuItem
              className={
                splitLocation[1] === "patient-list" ||
                  splitLocation[1] === "patient-view" ||
                  splitLocation[1] === "patient-details" ||
                  splitLocation[1] === "management-plan" ||
                  splitLocation[1] === "view-management-plan" ||
                  splitLocation[1] === "doctor-score-list" ||
                  splitLocation[1] === "patient-demographic" ||
                  splitLocation[1] === "akunah-connect" ||
                  splitLocation[1] === "notes" ||
                  splitLocation[1] === "patient-operative-note-list" ||
                  splitLocation[1] === "document-list" ||
                  splitLocation[1] === "add-document" ||
                  splitLocation[1] === "new-document" ||
                  splitLocation[1] === "pdf-generate" ||
                  splitLocation[1] === "insurance"
                  ? "active"
                  : ""
              }
              icon={
                <img
                  src={patientsIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.patients}
            >
              {translation.patients}
              <Link to="/patient-list" />
            </MenuItem>

            <MenuItem
              className={
                splitLocation[1] === "operative-notes-list" ||
                  splitLocation[1] === "add-operative-notes" ||
                  splitLocation[1] === "post-operative-note" ||
                  splitLocation[1] === "view-genaral-operative-notes" ||
                  splitLocation[1] === "view-operative-notes"
                  ? "active"
                  : ""
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.OperativeNotes}
              icon={
                <img
                  src={mgmtPlanIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
            >
              {translation.OperativeNotes}
              <Link to="/operative-notes-list" />
            </MenuItem>

            {/* <MenuItem
              className={splitLocation[1] === "cases-list" ? "active" : ""}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.REFLECTComplex}
              icon={
                <img
                  src={dataExportIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
            >
              {translation.REFLECTComplex}
          
              <a
                href={process.env.REACT_APP_REFLECT_URL}
                target="_blank"
                rel="noreferrer"
              ></a>
            </MenuItem> */}
            {freatures.Radiology ?

              <MenuItem
                className={splitLocation[1] === "radiology" ? "active" : ""}
                icon={
                  <img
                    src={opNotesIconFill}
                    alt="menu-icon"
                    className="nav_icon"
                  />
                }
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={translation.Radiology}
              >
                {translation.Radiology}
                {/* <a
                href={"https://pacs.akunah.com/sign-in?ReturnUrl=%2f"}
                target="_blank"
                rel="noreferrer"
              ></a> */}
                <Link to="/radiology" />
              </MenuItem>
              : ""}
            {freatures.Pathology ?

              <MenuItem
                className={splitLocation[1] === "pathology" ? "active" : ""}
                icon={
                  <img
                    src={pathologyPlanIconFill}
                    alt="menu-icon"
                    className="nav_icon"
                  />
                }
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={translation.Pathology}
              >
                {translation.Pathology}
                <Link to="/pathology" />
              </MenuItem>
              : ""}
            {freatures.Pacs ?
              <MenuItem
                className={splitLocation[1] === "akmed" ? "active" : ""}
                icon={<img src={opNotesIconFill} alt="menu-icon" className="nav_icon" />}
              >
                {"PACS"}

                <a
                  href={"https://pacs.akunah.com/sign-in?ReturnUrl=%2F"}
                  target="_blank"
                  rel="noreferrer"
                  alt=""
                ></a>
              </MenuItem>
              : ""}




            {/* https://pacs.akunah.com/sign-in?ReturnUrl=%2F */}
            {freatures.Akunah_Med ?
              <MenuItem
                className={splitLocation[1] === "akmed" ? "active" : ""}
                icon={<img src={med} alt="menu-icon" className="nav_icon" />}
              >
                {translation.akmed}

                <a
                  href={"https://med.akunah.com/"}
                  target="_blank"
                  rel="noreferrer"
                ></a>
              </MenuItem>
              : ""}
            {/* <MenuItem
              className={splitLocation[1] === "akmed" ? "active" : ""}
              icon={<img src={med} alt="menu-icon" className="nav_icon" />}
            >
              Reminders

              <a
                href={"https://med.akunah.com/"}
                target="_blank"
                rel="noreferrer"
              ></a>
            </MenuItem> */}





            <MenuItem
              className={splitLocation[1] === "my-document" ? "active" : ""}
              icon={
                <img

                  src={document}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.MyDocument}
            >
              {translation.MyDocument}
              <Link to="/my-document" />
            </MenuItem>
            <MenuItem
              className={splitLocation[1] === "data-export" ? "active" : ""}
              icon={
                <img
                  src={dataaExportIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.DataExport}
            >
              {translation.DataExport}
              <Link to="/data-export" />
            </MenuItem>
            <MenuItem
              className={splitLocation[1] === "data-export-advance" ? "active" : ""}
              icon={
                <img
                  src={dataaExportIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Advance Data Export"
            >
              Advance Data Export
              <Link to="/data-export-advance" />
            </MenuItem>

            <MenuItem
              onClick={handleShow}
              icon={
                <img
                  src={settingsIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
            >
              {translation.settings}
            </MenuItem>
            {/* <MenuItem
              className={splitLocation[1] === "template" ? "active" : ""}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.document_templates}
              icon={<img src={form} alt="menu-icon" className="nav_icon" />}
            >
              {translation.document_templates}
              <Link to={`/template/${login.ing_ak_id}`} />
            </MenuItem> */}
          </Menu>
          <SidebarFooter>
            {/* <span className="user_letter">
            <img
              src={homeIconFill}
              alt="icon"
              className=""
            />
          </span> */}
            <span className="user_letter">
              {login.ing_image === "" ? (
                login.ing_firstname.slice(0, 1) + login.ing_lastname.slice(0, 1)
              ) : (
                <img src={login.ing_image} alt={login.ing_firstname} />
              )}
            </span>
            <div className="username-info">
              <h5>{login.ing_fullname}</h5>
              <span>{login.ing_email}</span>
            </div>
            <p className="app_ver_txt_menu_responsive">
              {translation.AppVersion}: {process.env.REACT_APP_VERSION}
            </p>
          </SidebarFooter>
        </SidebarContent>

      </ProSidebar>

      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        // keyboard={false}
        dialogClassName="modal-xl modal-dialog-centered"
        className="settings_ppup_main"
      >
        <Modal.Header closeButton>
          <Modal.Title>{translation.settings}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="settings_ppup_bdy">
          <Settings handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LeftSidebar;
