import React, { useState, useEffect } from "react";
import viewIcon from "../../images/eye-icon.svg";
import Stop_Icon from "../../images/Remove.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { Modal } from "react-bootstrap";
import toggleIcon from "../../images/dots-blue.svg";
import InputField from "../custom/inputfield/InputField";
import Button from "../custom/button/Button";
import active_icon from "../../images/active_Icon.png";
import disable_icon from "../../images/disable_icon.png";
import actionStyles from "../custom/table-action-items/TableActionItems.module.css";
import useAxios from "../../axiosinstance";
import Checkbox from "../custom/checkbox/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import Message from "../custom/toster/Message";
import SmallProfilescheduler from "../settings/Proms/SmallProfilescheduler";
import { useSearchParams, useParams } from "react-router-dom";
import ScheduleTable from "./ScheduleTable";
import ActiveViewTable from "./ActiveViewTable";
import { getDateDifferenceInDays } from "../../utilities/DateFormate";
import { noteRefresh } from "../../redux/actions";
export default function ScheuleActions({
  PostOpNoteshow,
  rowData,
  Status,
  resource_id,
  personalData,
  title1,
  edit


}) {
  const axiosInstance = useAxios();
  const dispatch = useDispatch()
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);

  const [showModal, setShowModal] = useState(false);
  const [showModalStop, setShowModalStop] = useState(false);
  const [messageType, setMessageType] = useState();
  const [message, setMessage] = useState("");
  const [showInviteIcon, setShowInviteIcon] = useState(true);
  const [stopIconClicked, setStopIconClicked] = useState(true);
  const [scheduleData, setScheduleData] = useState([]);
  const [title, setTitle] = useState([]);
  const [treatmentDate, setTreatmentDate] = useState("");
  const [type, setType] = useState(0);

  let scheddd = []

  const handleCloseModal = () => {
    setShowModal(false);
  };
  // useEffect(() => {
  const getData = async (dataRefe) => {
    try {
      const response = await axiosInstance.current.get(
        `extapp/doctors/get_details_of_active_schedules/${login.ing_ak_id}?resource_id=${resource_id}`
      );
      // console.log(response.data.data.reference_date[0],"nonsurgdata")
      setScheduleData(response.data.data.arr);
      setTitle(response.data.data);
      setTreatmentDate(response.data.data.treatment_date);
      setType(response.data.data.reference_date[0])
    } catch (error) { }
  };
  // console.log(type , 'nonsurgdata1')
  // getData();
  // }, [dataRefe]);
  const handleShowModal = () => {
    getData(dataRefe);
    setShowModal(true);
  };
  const handleShowStop = () => {
    setShowModalStop(true);
  };
  const handleCloseStop = () => {
    setShowModalStop(false);
  };

  // const json = JSON.stringify({
  //   assign_ids: [resource_id],
  // });

  // const handleSaveTask = async () => {
  //   try {
  //     const res = await axiosInstance.current.put(
  //       `/extapp/forms/update_op_notes?resource_id=${resource_id}&status=1`,
  //       json,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     if (res.status === 200) {


  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const handleStop = async () => {
    try {
      const result = await axiosInstance.current.put(
        `extapp/doctors/proms_clinicians_schedulerConDis?ak_dc=${login.ing_ak_id}&resource_id=${resource_id}&n=-1`
      );
      if (result.status === 200) {
        // handleSaveTask();
        setMessageType("success");
        setMessage("Scheduler Stopped Successfully");
        setShowModalStop(false);
        setShowInviteIcon(false);
        setStopIconClicked(true);
        dispatch(noteRefresh(!dataRefe));

        
        setTimeout(() => {
          setMessage(false)
          dispatch(noteRefresh(!dataRefe));
        }, 2500);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (Status === "-1") {
      setShowInviteIcon(false);
      setStopIconClicked(false);
    }
  }, [Status]);


  console.log('====================================');
  console.log(edit, "edit");
  console.log('====================================');

  return (
    <>
      {message && <Message message={message} type={messageType} />}
      <div
        className={`action_large_dev custom_sort_dropdown ${actionStyles.action_large_dev}`}
      >
        <img
          className={`action_icons`}
          src={viewIcon}
          alt="Icon"
          onClick={handleShowModal}
          style={{ cursor: 'pointer' }}
        />
        {/* {edit != true && (

          <img
            className={`action_icons`}
            src={stopIconClicked ? active_icon : disable_icon}
            alt="Icon"
            style={{ marginLeft: "8px", width: "20px", height: "20px", cursor: 'pointer' }}
            onClick={handleShowStop}
          />
        )} */}

        {
          !edit && (
            <img
              className={`action_icons`}
              src={stopIconClicked ? active_icon : disable_icon}
              alt="Icon"
              style={{ marginLeft: "8px", width: "20px", height: "20px", cursor: 'pointer' }}
              onClick={handleShowStop}
            />
          )
        }

      </div>
      <div
        className={`action_small_dev text-end custom_sort_dropdown ${actionStyles.action_small_dev}`}
      >
        <Dropdown
          className={`custom_dropdown ${actionStyles.drop_down} text-start`}
        >
          <Dropdown.Toggle
            className={actionStyles.dropdown_button}
            variant="default"
            id="dropdown-basic"
          >
            {" "}
            <span>
              <img
                className={`toggle_dots_img`}
                src={toggleIcon}
                alt="toggle"
              />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <img
                className={`action_icons`}
                src={viewIcon}
                alt="Icon"
                onClick={handleShowModal}
                style={{ cursor: 'pointer' }}
              />
              View
            </Dropdown.Item>
            {
          !edit && (
            <Dropdown.Item>
              <img
                className={`action_icons`}
                src={active_icon}
                alt="Icon"
                onClick={handleShowStop}
                style={{ cursor: 'pointer' }}
              />
              Stop
            </Dropdown.Item>
          )}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <Modal
        centered
        show={showModal}
        // show={true}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        dialogClassName="patient_notes_popup"
      >
        <Modal.Header className="">
          <div className="" style={{ marginBottom: '19px' }}>
            <button className="close-button " onClick={handleCloseModal}>
              &times;
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="">
            <div className="p-2" style={{ backgroundColor: 'rgb(243, 245, 255)', borderRadius: 10 }}>
              <SmallProfilescheduler PostOpNoteshow={PostOpNoteshow} personalData={personalData} showModal={showModal} />
            </div>
          </div>
          <div
            className="whitebox"
            style={{
              border: "2px solid #ECE9F3",
              marginBottom: "4px",
              marginTop: "20px",
            }}
          >
            <div className="scrollbar_vert" style={{ height: "240px", }}>
              <ActiveViewTable
                data={scheduleData}
                datas={title}
                date={treatmentDate}
                type={type}
                login={login}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal
        centered
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-lg patient_notes_popup"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">{title.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <>
                    <InputField
                      label="PROMs schedule title"
                      value={title.title}
                      type="text"
                      placeholder="Select patient"
                      disabled={true}
                      readOnly={true}
                    />
                  </>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <InputField
                    label="Organization*"
                    // value={label}
                    type="text"
                    placeholder="Select patient"
                    disabled={true}
                    readOnly={true}
                  />
                </div>
                <div className="col-6">
                  <InputField
                    label="Specialty*"
                   
                    value= {title.speciality}
                    type="text"
                    placeholder="Select patient"
                    disabled={true}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <InputField
                    label="Treatment category*"
                    value={title.treatment_category}
                    type="text"
                    placeholder="Select patient"
                    disabled={true}
                    readOnly={true}
                  />
                </div>
                <div className="col-6">
                  <InputField
                    label="Primary Diagnosis*"
                    value={title.primary_diagnosis?.primary}
                    type="text"
                    placeholder="Select patient"
                    disabled={true}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <InputField
                    label="Alert"
                    
                    value={ 
                      title.alert && title.alert.length > 0
                ? title.alert[0] === 0
                  ? "No alert"
                  : title.alert[0] === 1
                  ? "Same day"
                  : title.alert[0] === 2
                  ? "1 day before"
                  : title.alert[0] === 3
                  ? "2 days before"
                  : "1 week before"
                 :""}
                    type="text"
                    placeholder="Select patient"
                    disabled={true}
                    readOnly={true}
                  />
                </div>
                <div className="col-6">
                  <InputField
                    label="Alert for Overdue Forms"
                    value={
                      title.alert && title.alert.length > 0
                      ?title.overdue[0] ==="0"
                    ?"No alert"
                    :title.overdue[0] ==="1"
                    ?"1 day after"
                    :title.overdue[0] ==="2"
                    ?"2 days after"
                    :"1 week after"
                    :""}
                    type="text"
                    placeholder="Select patient"
                    disabled={true}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-1">
                  <div class="horizontal-line"></div>
                </div>
                <div className="col-10">
                  <div className="row">
                    <div className="col-6">
                      <InputField
                        label="Reference Date*"
                        value={
                          title.reference_date && title.reference_date.length > 0
                          ?title.reference_date[0] ==="1"  ? 
                          "Treatment Date":"Date of initial PROMs":""}
                        type="text"
                        placeholder="Select patient"
                        disabled={true}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  {title.schedules && title.schedules.length !== 0 ? (
                    <div className="row">
                      <label>Set Schedule*</label>
                      {title.schedules[0].value && (
                        <div className="row">
                          <div className="col-6">
                          <InputField
                              value={title.schedules[0].varient}
                              type="text"
                              placeholder="Specify"
                              styleClass="mw-100 rounded-pill"
                              disabled={true}
                              readOnly={true}
                            />
                          </div>
                          <div className="col-6">
                           
                            <InputField
                              value={title.schedules[0].value}
                              type="text"
                              placeholder="Specify"
                              disabled={true}
                              readOnly={true}
                              styleClass="mw-100 rounded-pill"
                            />
                          </div>
                        </div>
                      )}
                      {title.schedules[1].value && (
                        <div className="row">
                          <div className="col-6">
                          <InputField
                              value={title.schedules[1].varient}
                              type="text"
                              placeholder="Specify"
                              styleClass="mw-100 rounded-pill"
                              disabled={true}
                              readOnly={true}
                            />
                          </div>
                          <div className="col-6">
                           
                            <InputField
                              value={title.schedules[1].value}
                              type="text"
                              placeholder="Specify"
                              disabled={true}
                              readOnly={true}
                              styleClass="mw-100 rounded-pill"
                            />
                          </div>
                        </div>
                      )}
                      {title.schedules[2].value && (
                        <div className="row">
                          <div className="col-6">
                          <InputField
                              value={title.schedules[2].varient}
                              type="text"
                              placeholder="Specify"
                              styleClass="mw-100 rounded-pill"
                              disabled={true}
                              readOnly={true}
                            />
                          </div>
                          <div className="col-6">
                           
                            <InputField
                              value={title.schedules[2].value}
                              type="text"
                              placeholder="Specify"
                              disabled={true}
                              readOnly={true}
                              styleClass="mw-100 rounded-pill"
                            />
                          </div>
                        </div>
                      )}
                      {title.schedules[3].value && (
                        <div className="row">
                          <div className="col-6">
                          <InputField
                              value={title.schedules[3].varient}
                              type="text"
                              placeholder="Specify"
                              styleClass="mw-100 rounded-pill"
                              disabled={true}
                              readOnly={true}
                            />
                          </div>
                          <div className="col-6">
                            
                             <InputField
                              value={title.schedules[3].value}
                              type="text"
                              placeholder="Specify"
                              disabled={true}
                              readOnly={true}
                              styleClass="mw-100 rounded-pill"
                            />
                          </div>
                        </div>
                      )}
                      {title.schedules[4].value && (
                        <div className="row">
                          <div className="col-6">
                          <InputField
                              value={title.schedules[4].varient}
                              type="text"
                              placeholder="Specify"
                              styleClass="mw-100 rounded-pill"
                              disabled={true}
                              readOnly={true}
                            />
                          </div>
                          <div className="col-6">
                           
                            <InputField
                              value={title.schedules[4].value}
                              type="text"
                              placeholder="Specify"
                              disabled={true}
                              readOnly={true}
                              styleClass="mw-100 rounded-pill"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      <Modal
        centered
        show={showModalStop}
        onHide={handleCloseStop}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-s patient_notes_popup"
      >
        <Modal.Header >
          <Modal.Title className="text-center">
            {"Stop PROMs Schedule"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <p>
              <strong>You are about to stop the PROMs schedule for </strong>
            </p>
            <p>
              <strong>{title1}</strong>
            </p>
            <p>
              {" "}
              <strong>All patient notifications will be discontinued</strong>
            </p>
            <p>
              <strong>Click "Yes" if you would like to proceed</strong>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <div className="row">
            <div className="col-6">
              <Button
                value="No"
                radius="20px"
                width="140px"
                fontSize="14px"
                buttonStyle="btn_outline f_600"
                border=""
                color=""
                minHeight="40px"
                height=""
                onClick={handleCloseStop}
              />
            </div>
            <div className="col-6">
              <Button
                value="Yes"
                radius="20px"
                width="140px"
                fontSize="14px"
                buttonStyle="btn_outline f_600"
                border=""
                color=""
                minHeight="40px"
                height=""
                // setShowModals={true}
                onClick={handleStop}
              />
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
