import React, { useEffect, useState, useRef } from "react";
// import useTranslation from "../../customHooks/translations";
import InputField from "../../custom/inputfield/InputField";
import SignaturePad from "react-signature-pad-wrapper";
import { Validators } from "../../../utilities/Validator";
import close from "../../../images/close.png";
import Dropdownno from "../../custom/dropdown/Dropdownno";
import Button from "../../custom/button/Button";
import useAxios from "../../../axiosinstance";
import { loginData } from "../../../redux/actions/index";
import { useSelector, useDispatch } from "react-redux";
import Message from "../../custom/toster/Message";
const Sign = ({ handlePopup,setType }) => {
  const axiosInstance = useAxios();
  // const translation = useTranslation();
 
  const dispatch = useDispatch();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const [signText, setSignText] = useState("");
  const [image1, setImage1] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const signaturePadRef = useRef({});
  const [signType, setSignType] = useState("0");
  const signTypeRef = useRef();
  const signTextRef = useRef();
  let akToken = localStorage.getItem("AkTOKEN");
  const [show, setShow] = useState(false);
  const chooseSign = (e) => {
    setSignType(e);
    setShow(true);
  };

  const handleSignText = (e) => {
    setSignText(e);
  };
  useEffect(() => {
    setSignType(login.ing_sign_type === 1 ? "0" :login.ing_sign_type === null?"0": "1");
    setSignText(login.ing_sign_type === 1 ? login.ing_sign : "");
    setImageUrl(login.ing_sign_type === 2 ? login.ing_sign : "");
  }, [login]);

  const saveSign = async () => {
    let image = "";
    if (signType === "1") {
      image = signaturePadRef.current.toDataURL();
    }
    const json = JSON.stringify({
      sign: signType === "0" ? signText : image,
      // sign_image: image,
      sign_type: signType === "0" ? 1 : 2,
    });
    try {
      const result = await axiosInstance.current.put(
        `extapp/doctors/updateDoctorSignature/${login.ing_ak_id}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) {
        handleConnectMe();
        setMessage("Update Successful");
        setMessageType("success");
        setTimeout(() => {
          setMessage(false);
        }, 3000);
        setShow(false);
        setType("")
        handlePopup(false);

      }
    } catch (err) {
    } finally {
      console.log("api response finally");
    }
  };

  const handleConnectMe = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/doctors/myprofile?ak_id=${akToken}`
      );
      if (result.status == "200") {
        // if(result.message)

        if (result.data.data.ing_acc_status == 1) {
          dispatch(loginData(result.data.data));
        }
      }
    } catch (err) {
      console.log(err.message || "Unexpected Error!");
    } finally {
      console.log("api response finally");
    }
  };
  const updateSign = () => {
    if (signType === "0") {
      if (!signText) {
        signTextRef.current.errUpdate();
      } else {
        saveSign();
      }
    } else if (signType === "1") {
      if (signaturePadRef.current.isEmpty()) {
        setImage1(true);
      } else {
        saveSign();
      }
    }
  };
  const handleShow = () => {
    setShow(!show);
  };
  useEffect(() => {
    if (login.ing_sign_type===null) {
      setShow(!show);
      
    }
  }, [login.ing_sign_type])
console.log(login.ing_sign,signType ,"login.ing_signlogin.ing_sign",login.ing_sign_type);
  return (
    <>
      <div className="">
        <div className="row">
          <div className="col-md-8 col-sm-8">
            <h4
              style={{
                color: `#3f2783`,
                fontSize: "18px",
                marginBottom: "20px",
                fontWeight: 700,
              }}
            >
              My Signature
            </h4>
          </div>
          {/* <Button
            onClick={(e) => setShow(true)}
            value="upload"
            buttonStyle="btn_fill mr_15"
            width="160px"
          /> */}
          <div className="col-md-4 col-sm-4 text-end">
            {message && <Message message={message} type={messageType} />}
            {/* {imageUrl !== "" && ( */}
              <Button
                onClick={(e) => handleShow()}
                value={!show ? "Edit" : "View"}
                buttonStyle="btn_fill mr_15"
                width="auto"
              />
            {/* )} */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-5">
          <div className="input-row sign-type signup__sign_type">
            <div className="input-box mt-20">
              <Dropdownno
                data={[
                  { value: "0", label: "Text" },
                  { value: "1", label: "Sign" },
                ]}
                label="Type"
                value={signType}
                onChange={(e) => chooseSign(e)}
                styleClass="br_10 mxw_100 mb_20"
                placeholder="Select "
                ref={signTypeRef}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="col-12 pe-4">
          {signType == "0"  ?(
            <div className="input-row me-2" style={{ display: "" }}>
              <InputField
                label={"Signed by Keyboard"}
                value={signText}
                ref={signTextRef}
                type="textarea"
                placeholder="Sign here"
                onChange={handleSignText}
                styleClass="h_150"
                formGroupClass="label_back"
                disabled={!show}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
              />
            </div>
          ):""}
          {signType == "1" && (
            <>
              <label>{"Signed by Mouse *"}</label>
              <div className="SignaturePad " style={{ marginBottom: "15px" }}>
                {show ? (
                  <>
                    <a
                      href="javaScript:void(0)"
                      className="clearlink"
                      onClick={() => {
                        signaturePadRef.current.clear();
                      }}
                    >
                      <img alt="" src={close} />
                      {"Clear"}
                    </a>
                    <SignaturePad
                      height={140}
                      ref={signaturePadRef}
                      options={{
                        minWidth: 2,
                        maxWidth: 4,
                        penColor: "rgb(000, 000, 000 ,1)",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <img alt="" width={"100%"} src={imageUrl}></img>
                  </>
                )}
              </div>
              {image1 && (
                <span className="text-danger"> This field is required</span>
              )}
            </>
          )}
        </div>
      </div>
      <div className="row me-0">
        <div className="col-12 text-end">
          {show ? (
            <Button value="Update" onClick={updateSign} />
          ) : (
            <Button buttonStyle="btn_disabled" value="Update" />
          )}
        </div>
      </div>
    </>
  );
};

export default Sign;
