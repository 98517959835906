import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { accessToken } from "./redux/actions";
import { useSelector, useDispatch } from "react-redux";
const useAxios = (baseURL) => {
  const axiosInstance = useRef();
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [token, setToken] = useState({});
  
  const tokens = useSelector((state) => state.mainReducer.accessTokens.arr);
  
// let tc =localStorage.getItem("token");
  useEffect(() => {
    setToken(keycloak.token)
    console.log(tokens , "tokensssssssssss")
    const createAxiosInstance = () => {
      
      axiosInstance.current = axios.create({
        baseURL: process.env.REACT_APP_APIURL,
        headers: {
          Authorization:
            initialized && keycloak.token
              ? `Bearer ${ typeof token !=="object" ? token : keycloak.token}`
              : undefined,
        },
      });
    };
   

    // Check for token expiration and refresh
    const checkTokenExpiration = async () => {
      const token = keycloak.token;
      // console.log(token, "tokentoken");
      dispatch(accessToken(token))
      setToken(token)
      if (token) {
        const decodedToken = keycloak.tokenParsed;
        const currentTime = Math.floor(Date.now() / 1000);
        const tokenExpirationThreshold = 300; // 5 minutes before expiration

        // if (decodedToken.exp - currentTime < tokenExpirationThreshold) {
          // Token is about to expire or has already expired
          if (!isRefreshing) {
            setIsRefreshing(true);
            try {
            const aa =  await keycloak.updateToken(5); // Refresh the token
            console.log("refresh token" , aa);
            if(aa){
              console.log('set new Token' , aa)
              setToken(token)
            }
              setIsRefreshing(false);
       
              dispatch(accessToken(token))
              createAxiosInstance();
            } catch (error) {
              console.error("Error refreshing token:", error);
              setIsRefreshing(false);
            }
          }
        // }
      }
    };
    const handleRequestError = (error) => {
      console.log('hello , token expire' , error,isRefreshing)
      if (error.response ) {
        // The API returned a 401 unauthorized status, which may indicate an expired token.
        // Try to refresh the token and reattempt the request.
        if(error.response.status === 401 || error.response.status === 404 ){
           console.log('hello , token expire1' , error,isRefreshing)

          setIsRefreshing(false);
          checkTokenExpiration();
        }
      } else {
        // Handle other error cases here.
      }
      throw error; // Rethrow the error to propagate it to the caller.
    };
    createAxiosInstance();
    axiosInstance.current.interceptors.response.use(
      (response) => response,
      (error) => {
        return Promise.reject(handleRequestError(error));
      }
    );
    // Set up an interval to periodically check token expiration
    const tokenCheckInterval = setInterval(checkTokenExpiration, 60 * 1000); // Check every minute

    return () => {
      clearInterval(tokenCheckInterval);
    };
  }, [initialized, isRefreshing, keycloak,token]);

  return axiosInstance;
};

export default useAxios;