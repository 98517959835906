import React from "react";
import PropTypes from "prop-types";
const Button = ({
  value,
  border,
  color,
  // children,
  height,
  onClick,
  radius,
  width,
  minHeight,
  fontSize,
  buttonStyle,
  minWidth,
  disabled,
  opacity,
  cursor,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={(event) => onClick(event)}
      style={{
        backgroundColor: color,
        border,
        borderRadius: radius,
        height,
        minHeight,
        width,
        fontSize,
        minWidth,
        opacity,
        cursor
      }}
      className={buttonStyle}
    >
      {value}
    </button>
  );
};

Button.propTypes = {
  styleClass: PropTypes.string,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
  buttonStyle: "btn_fill",
};

export default Button;
