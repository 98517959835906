import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import useAxios from "../../axiosinstance";
import { useSelector, useDispatch } from "react-redux";
import { Link, useSearchParams, useParams, useNavigate } from "react-router-dom";
import { yearDate } from "../../utilities/DateFormate";
// import WidgetScheduler from "./WidgetScheduler";
import AddModelTableSchedular from "./AddModelTableSchedular";
import Checkbox from "../custom/checkbox/Checkbox";
import Button from "../custom/button/Button";
import { useRef } from "react";
import Dropdown from "../custom/dropdown/Dropdown";
import CreatableSelect from "react-select/creatable";
import { noteRefresh } from "../../redux/actions";
import Message from "../custom/toster/Message";
import InitialPromsActive from "../settings/Proms/InitialPromsActive";
import SmallProfilescheduler from "../settings/Proms/SmallProfilescheduler";

export default function PatientProms({ name, schedulerForm, age1, setTreatment, treatement, orgId, resource_ids }) {
  const dispatch = useDispatch()
  const [showModal, setshowModal] = useState(false);
  const [Personaldata, setPersonaldata] = useState([]);
  const [respo, setRespo] = useState("");
  const axiosInstance = useAxios();
  const [searchParams] = useSearchParams();
  let resId = searchParams.get("rId");
  let age = searchParams.get("age");
  const { ptId } = useParams();
  const [data, setData] = useState("");
  const navigate = useNavigate();
  const { akid } = useParams();
  const [isButtonsClicked, setButtonsClicked] = useState(false);
  const [isModelOpen, setModelOpen] = useState(false);
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [speciality, setSpeciality] = useState("");
  const [getData2, setGetData2] = useState(null);
  const [concatenatedAutoIds, setConcatenatedAutoIds] = useState('')
  const [Selecttreatment, setSelecttreatment] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [Selectspec, setSelectspec] = useState([]);
  const [assignErr, setassignErr] = useState(false)
  const [schedulerForm2, setschedulerForm2] = useState([]);
  const [showModal1, setshowModal1] = useState(false);
  const [isApiPending, setIsApiPending] = useState(false);
  const [orgapiData, setOrgData] = useState([]);
  const [org, setOrg] = useState("");
  const [messageType, setMessageType] = useState();
  const [message, setMessage] = useState("");
  const [messageShow, setmessageShow] = useState(false);

  const getOrgData = async () => {
    const response = await axiosInstance.current.get(
      `extapp/doctors/getAllUserOrganizations?doctor_id=${login.ing_ak_id
      }&patient_id=${window.atob(akid)}&useFor=patient`
    );
    setOrgData(response.data.data);
  };
  useEffect(() => {
    getOrgData();
  }, []);
  const handleOrg = (e) => {
    setOrg(e);
    setassignErr(false);
  };
  const HandleSelecttreatment = (e) => {
    setSelecttreatment(e);
    setSelectspec([])
  };
  const HandleSchedularopen = () => {
    setshowModal(true);
    // setConcatenatedAutoIds("")
  };

  const handleClose = () => {
    setshowModal(false);
    setButtonsClicked(false);
    setSelectspec([])
    setSelecttreatment("")
    setCheckboxValues([])
    setOrg("")
  };
  console.log(resource_ids, "resource_idresource_id1")
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extapp/doctors/getPatientProfile?ak_id=${window.atob(akid)}&ak_dc=${login.ing_ak_id}`
        );
        if (response) {
          setData(response.data.data);
          name(response.data.data.firstname + (" ") + response.data.data.lastname);
          age(yearDate(response.data.data.dob));
        }
      } catch (error) {
        if (error.response?.status === 401) {
          navigate("/unauthorized");
        }
      }
    };
    getData();
  }, []);

  const GetNoteDetails2 = () => {
    axiosInstance.current
      .get(
        `/extapp/forms/get_patients_pDetails?doctor_id=${login.ing_ak_id}&resource_id=${resource_ids}&patient_id=${ak_pt}`
      )
      .then((response) => {
        console.log(response);

        setPersonaldata(response.data.personal_details);
      })
      .catch((error) => {
        if (error.response.status === 401) {
        }
      });
  };



  useEffect(() => {
    if (resource_ids !== 0) {
      GetNoteDetails2();
    }

    if (checkboxValues.length > 0) {
      setassignErr(false);
    }
  }, [respo, resource_ids]);

  const handleOpenModal = () => {
    setshowModal1(true)
  }

  let selectspecialty = [
    { value: 4, label: "Shoulder" },
    { value: 5, label: "Knee" },
    { value: 6, label: "Hip" },
    { value: 33, label: "General" },
    { value: 23, label: "Hand and Wrist" },
    { value: 21, label: "Foot and Ankle" },
    { value: 22, label: "Elbow" },
  ]

  const HandleSelectspec = (selectedOptions) => {
    setSelectspec(selectedOptions);
    setschedulerForm2([]);
    setCheckboxValues([])
  };

  let specialtyarr = Selectspec?.map((item) => item.value)
  const getData = async () => {
    setIsLoading(true)
    try {
      const response = await axiosInstance.current.get(
        `extapp/doctors/proms_clinicians_schedulerGet?ak_dc=${login.ing_ak_id}&treatment_category=${Selecttreatment}&speciality=${specialtyarr.toString()}&akou_id=${org}&status=1`
        // `extapp/doctors/proms_clinicians_schedulerGet?ak_dc=${login.ing_ak_id}&speciality=${specialtyarr.toString()}&treatment_category=${Selecttreatment}`
      );
      let data = Array.isArray(response.data.data) && response.data.data.filter((item) => item.reference_date[0] === "2")
      setschedulerForm2(data);
      // const resourceId = response.data.data?.[0]?.resource_id;
    } catch (error) {
      setschedulerForm2([])
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (specialtyarr.length !== 0) {
      getData();
    }
  }, [specialtyarr.length, Selecttreatment, org])
  function isObjectEmpty(obj) {
    return Object.entries(obj).length === 0;
  }
  const handleCreate = () => {
    const operativeSide = getData2?.operativeSide || '';
    if (checkboxValues.length === 0) {
      setassignErr(true);
      return;
    } else {
      setassignErr(false);
    }
    if (org.length === 0) {
      setassignErr(true);
      return

    } else {
      const json = JSON.stringify({
        auto_id: checkboxValues.join(','),
        side: operativeSide,
        ak_ou: org,
        ak_pt: window.atob(akid),
        ak_dc: login.ing_ak_id,
        speciality: specialtyarr.toString(),
      });
      setIsApiPending(true);
      axiosInstance.current
        .post(
          `extapp/doctors/assigned_schedulesCreate?ak_dc=${login.ing_ak_id}`,
          json,
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          if (res.status === 201) {
            setmessageShow(true)
            setMessageType("success");
            setMessage("Schedule Assigned successfully");

            setshowModal(false);
            setButtonsClicked(false);
            setSelectspec([])
            setCheckboxValues([])
            setSelecttreatment("")
            dispatch(noteRefresh(new Date().getMilliseconds()));
            setIsApiPending(false);

            setOrg();
            if (!isObjectEmpty(res.data.error_arr.message)) {

              setmessageShow(true)
              setMessageType("error");
              setMessage(res.data.error_arr.message);
            }

          }
          setTimeout(() => {
            setMessage("");
            setmessageShow(false)
          }, 3000);
        })
        .catch((err) => {

        });
    }
  };
  console.log(messageShow, message, "checkingofmessge")

  // console.log(selectspecialty,"spec")
  // console.log(Selectspec,"specvalue")
  const submitButtonRef = useRef(null);
  // const handleCheckboxChange = () => {
  //   if (submitButtonRef && submitButtonRef.current) {
  //     submitButtonRef.current.click();
  //   }
  // };
  const handleCheckboxChange = (selectedValues) => {
    setCheckboxValues(selectedValues);
    setConcatenatedAutoIds("");
  };

  const updateCheckboxValues = (newValue) => {
    if (newValue.checked) {
      setCheckboxValues((prevValues) => [...prevValues, newValue.resource_id]);
    } else {
      setCheckboxValues((prevValues) =>
        prevValues.filter((value) => value !== newValue.resource_id)
      );
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Speciality",
        accessor: "speciality",
      },
      // {
      //   Header: "Title",
      //   accessor: "title",
      //   Cell: (row) => (
      //     <span>
      //       {row.row.original.speciality}
      //       {/* {schedulerForm} */}
      //       {/* {UserDateFormat(
      //                 row.row.original.assessment_date,
      //                 login.ing_date_formate,login.ing_timezone
      //               )} */}
      //     </span>
      //   ),
      // },

      // {
      //   Header: "Action",
      //   headerClassName: "col-action",
      //   accessor: "action",
      //   className: "col-action",
      //   Cell: (row) => (
      //     <span>
      //       <button onClick={e=> handleEdit(row.row.original)}>Edit</button>
      //       <ScheuleAction
      //         viewAction="true"
      //         editAction="true"
      //         feedbackAction="false"
      //         deleteAction="false"
      //         arrowAction="true"
      //         // rowName={doctorID}
      //         rowId={row.row.ea_akpt_id}
      //         // rowData={actionData}
      //         rowType="patients"

      //         // patientId={akId === undefined ? akid : akId}
      //         notetitle={row.row.original.ea_name}
      //         rowOrgId={row.row.original.ea_akpt_id}
      //         orgId={row.row.original.ea_org_id}
      //       />
      //     </span>
      //   ),
      // },
    ],
    []

  );

  let datas = schedulerForm ? schedulerForm : []
  // const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
  //   useTable({
  //     columns,
  //     datas,
  //   });


  const ak_pt = window.atob(akid)
  console.log(Selectspec.length, "SelectspecSelectspec")
  return (
    <>
      {messageShow && <Message message={message} type={messageType} />}
      <div>

        <button
          className="btn_outline"
          onClick={() => HandleSchedularopen()}
          style={{
            borderRadius: 20,
            minHeight: 40,
            width: 250,
            fontSize: 14,
          }}
        >
          {"+ Add PROMs Schedule"}
        </button>
        <Modal
          show={showModal}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-xs patient_notes_popup modal-dialog-centered modal-content1"
        >
          <Modal.Header className="close-buttonM">
            <Modal.Title className="">
              {"Set PROMs Schedule"}
            </Modal.Title>
            <button className="close-button" onClick={handleClose}>
              &times;
            </button>
          </Modal.Header>
          <Modal.Body className="">
            {/* <SmallProfilescheduler showModal={showModal}/> */}
            <div className="whitebox" style={{ backgroundColor: "rgb(209 203 230 / 45%)", border: "rgb(209 203 230 / 45%)" }}>
              <div className='d-flex justify-content-between mb-2'>
                <h6 className="text-capitalize" style={{ color: "#3f2783" }}>
                  <strong>{data.name}</strong>
                </h6>

                <div className="float-end ps-5" style={{ width: '80px' }}>
                  <Checkbox
                    selected={"CarpalDiagnosis1"}
                    onChange={"handleCarpalDiagnosis1"}
                    styleClass="custom_check_box tick f_400 large"
                    id="CarpalDiagnosis1"
                  />
                </div>
              </div>
              <div className='d-flex justify-content-between'>
                <span className="" style={{ color: "#3f2783" }}>
                  <strong>{yearDate(data.dob)}{Number(age) <= 1 ? " Years" : " Years"}</strong></span>
                <span className="float-end ps-5 text-capitalize" style={{ color: "#3f2783" }}><strong>{window.atob(akid)} </strong>&nbsp;</span>
              </div>
            </div>

            <div
              className="whitebox"
              style={{
                border: "1px solid #3f2783",
                // height: "200px",
                // width: "100%",
              }}>
              <div>
                <Dropdown
                  data={orgapiData}
                  label="Organization"
                  value={org}
                  onChange={handleOrg}
                  styleClass="br_10 mxw_100 mb_20"
                  placeholder="Select Organization"
                />
                {assignErr && (
                  <>
                    <span className="text-danger">Please select atleast one organization.</span>
                  </>
                )}
              </div>
              <div>
                <label className="mb-4">Please Choose Treatment Category</label>
                <div className="text-center">

                  <Dropdown
                    data={[
                      { value: "Surgical", label: "Surgical" },
                      { value: "Non-Surgical", label: "Non-Surgical" },
                      { value: "Research", label: "Research" },
                    ]}
                    // label="Treatment category*"
                    className="custom-dropdown mw-100"
                    value={Selecttreatment}
                    onChange={HandleSelecttreatment}
                    placeholder="Select"

                  />
                  {/* {!Selecttreatment && (
                          <>
                            {"err" && (
                              <span className="text-danger">
                                This field is required
                              </span>
                            )}
                          </>
                        )} */}

                </div>
              </div>

              {/* <InfiniteScroll> */}
              {/* <p>Please Choose PROMs Schedule</p>
            <div>
              <AddModelTable data={schedulerForm}/>
            </div> */}
              {/* </InfiniteScroll> */}
            </div>

            {Selecttreatment && (
              <>

                <div
                  className="whitebox"
                  style={{
                    border: "1px solid #3f2783",
                    // height: "200px",
                    // width: "100%",
                  }}>
                  <div className="col-12">
                    <label className="form-label">Select Specialty</label>
                    <CreatableSelect
                      isClearable
                      isMulti
                      value={Selectspec}
                      onChange={HandleSelectspec}
                      options={selectspecialty}
                      className="multi_select_drp1"
                      isSearchable={false}
                    />
                  </div>
                </div>

              </>
            )}

            {Selectspec && Selectspec.length > 0 && (
              <>
                {schedulerForm2.length > 0 ? (
                  <div className="whitebox"
                    style={{
                      border: "1px solid #3f2783",
                      // height: "200px",
                      // width: "100%",
                    }}>
                    <label>Please Choose PROMs Schedule</label>
                    <div className="scrollbar_vert " style={{ height: "140px" }}>
                      <AddModelTableSchedular data={schedulerForm2} updateCheckboxValues={updateCheckboxValues} />
                    </div>
                  </div>
                ) : (
                  <>
                    {!isLoading && schedulerForm2.length === 0 ? (
                      <>
                        <p style={{ textAlign: "center", fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.5)' }}>No data found</p>
                      </>
                    ) : ""}
                  </>
                )}
              </>
            )}

            <div className="text-center">
              <br />
              {Selectspec.length > 0 && orgapiData.length > 0 && schedulerForm2.length > 0 && (
                <>
                  <Button
                    onClick={handleCreate}
                    value="Submit"
                    buttonStyle="btn_outline ms-2 mb-3 "
                    width="400px"
                    height={'50px'}
                    disabled={isApiPending}
                  />
                </>
              )}

              <br />
              {assignErr && (
                <>
                  {checkboxValues.length === 0 && (
                    <span className="text-danger">Please select atleast one schedule or close.</span>
                  )}
                </>
              )}
              <p>Can't find appropriate PROMs Schedule to link?</p>
              <label onClick={handleOpenModal} className="c_pointer">
                <u><b>Create a New PROMs Schedule</b></u>
              </label>
            </div>
          </Modal.Body>
        </Modal>
        {/* <WidgetScheduler resource_num={resource_ids} resId={resId} showModal1={showModal1} setshowModal1={setshowModal1} setshowModal={setshowModal} showModal={showModal} ak_pt={ak_pt} BlockReference={true} blockupdate="true" /> */}
        <InitialPromsActive showModal1={showModal1} setshowModal1={setshowModal1} setshowModal={setshowModal} showModal={showModal} ak_pt={ak_pt} BlockReference={true} />
      </div>
    </>
  );
}
