import React from "react";
import { useTable } from "react-table";
import InfiniteScroll from "react-infinite-scroll-component";
import editIcon from "../../../images/edit-icon.svg";
function OrgListTable({ data, updatePagination, DataIdget }) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Organizaion ID",
        accessor: "akou_id",
        headerClassName: "col-orgid",
        className: "col-orgid",
      },
      {
        Header: "Organizaion Name",
        accessor: "org_name",
        headerClassName: "col-orgname",
        className: "col-orgname",
      },
      {
        Header: "Status",
        headerClassName: "col-status",
        accessor: "approved_status",
        className: "col-status",
        Cell: (row) => (
          <span>
            {row.value === 1 && "Registered"}
            {row.value === 0 && "Review in progress"}
          </span>
        ),
      },
    ],
    []
  );

  const handleEdit = (e, i , org_id) => {
    DataIdget(e, i , org_id);
  };
  const { rows } =
    useTable({
      columns,
      data,
    });

  return (
    <>
      <div className="tableContainer" id="rrpScrollableDivdd">
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rrpScrollableDivdd"
          // loader={<TableSpinner />}
          // loader={data.length > 9 ? <h4>Loading...</h4> : ""}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <table role="table" className="custom-tbl my_clinic">
            <thead>
              <tr role="row">
                <th
                  colSpan={1}
                  role="columnheader"
                  className="col-orgid"
                  style={{ width: "20%" }}
                >
                  Organization ID
                </th>
                <th
                  colSpan={1}
                  role="columnheader"
                  className="col-orgname"
                  style={{ width: "25%" }}
                >
                  Organization Name
                </th>
                <th
                  colSpan={1}
                  role="columnheader"
                  className="col"
                  style={{ width: "35%" }}
                >
                  Speciality Name
                </th>
                <th
                  colSpan={1}
                  role="columnheader"
                  className="col-status"
                  style={{ width: "10%" }}
                >
                  Status
                </th>

                {/* <th
                  colSpan={1}
                  role="columnheader"
                  className="col"
                  style={{ width: "25%" }}
                >
                  Approved
                </th> */}
                {/* <th
                  colSpan={1}
                  role="columnheader"
                  className="col"
                  style={{ width: "25%" }}
                >
                  Pending
                </th> */}
                <th
                  colSpan={1}
                  role="columnheader"
                  className="col-status"
                  style={{ width: "10%" }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => {
                let rows = [];
                if (row.activeResourceTypes.length) {
                  rows.push(
                    <tr role="row">
                      <td role="cell" className="col-orgid">
                        {row.org_id}
                      </td>
                      <td role="cell" className="col-orgname">
                        {row.org_name}
                      </td>

                      <td role="cell" className="col-status">
                        <span>{row.activeResourceTypes.join(" , ")}</span>
                      </td>
                      <td role="cell" className="col-status">
                        <span>
                          <span className="fw-bolder text-success">Approved</span> <br></br>
                          {/* {row.activeResourceTypes.join(" , ")} */}
                        </span>
                      </td>
                      {/* <td className="col">
                        <span> {row.activeResourceTypes.join(" , ")}</span>
                      </td>
                      <td className="col">
                        <span> {row.pendingResourceTypes.join(" , ")}</span>
                      </td> */}
                      <td role="cell" className="col-orgname ">
                        {row.speciality_status === false && (
                          <img
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Edit"
                            className={`action_icons c_pointer`}
                            src={editIcon}
                            alt="Icon"
                            onClick={() => handleEdit(row.auto_id, 1 , row.org_id)}
                          />
                        )}
                      </td>
                    </tr>
                  );
                }
                if (row.pendingResourceTypes.length) {
                  rows.push(
                    <tr role="row">
                      <td role="cell" className="col-orgid">
                        {row.org_id}
                      </td>
                      <td role="cell" className="col-orgname">
                        {row.org_name}
                      </td>

                      <td role="cell" className="col-status">
                        <span>
                          <span>{row.pendingResourceTypes.join(" , ")}</span>
                        </span>
                      </td>
                      <td role="cell" className="col-status">
                        <span>
                          <span>
                            {" "}
                            <span className="fw-bolder text-warning">Pending </span>
                          </span>
                        </span>
                      </td>
                      {/* <td className="col">
                        <span> {row.activeResourceTypes.join(" , ")}</span>
                      </td>
                      <td className="col">
                        <span> {row.pendingResourceTypes.join(" , ")}</span>
                      </td> */}
                      <td role="cell" className="col-orgname ">
                        <img
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Edit"
                          className={`action_icons c_pointer`}
                          src={editIcon}
                          alt="Icon"
                          onClick={() => handleEdit(row.auto_id, 2 ,  row.org_id)}
                        />
                      </td>
                    </tr>
                  );
                }
                if (row.rejectedResourceTypes.length) {
                  rows.push(
                    <tr role="row">
                      <td role="cell" className="col-orgid">
                        {row.org_id}
                      </td>
                      <td role="cell" className="col-orgname">
                        {row.org_name}
                      </td>

                      <td role="cell" className="col-status">
                        <span>
                          <span>{row.rejectedResourceTypes.join(" , ")}</span>
                        </span>
                      </td>
                      <td role="cell" className="col-status">
                        <span>
                          <span>
                            {" "}
                            <span className="fw-bolder text-danger">Rejected </span>
                          </span>
                        </span>
                      </td>
                      {/* <td className="col">
                        <span> {row.activeResourceTypes.join(" , ")}</span>
                      </td>
                      <td className="col">
                        <span> {row.pendingResourceTypes.join(" , ")}</span>
                      </td> */}
                      <td role="cell" className="col-orgname ">
                        <img
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Edit"
                          className={`action_icons c_pointer`}
                          src={editIcon}
                          alt="Icon"
                          onClick={() => handleEdit(row.auto_id, 2 ,  row.org_id)}
                        />
                      </td>
                    </tr>
                  );
                }

                return rows;
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default OrgListTable;
