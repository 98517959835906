import {
  SHOW_LOADER,
  HIDE_LOADER,
  FETCH_SUBID_SUCCESS,
  FETCH_SUBID_FAIL,
  FETCH_MAIL_SUCCESS,
  FETCH_MAIL_FAIL,
  UPDATE_ADULT_KNEE_DATA,
  UPDATE_ADULT_KNEE_PAIN_FIELD_DATA,
  UPDATE_ADULT_KNEE_PAIN_DATA,
  UPDATE_ADULT_KNEE_SYMPTOMS_DATA_SINGLE,
  UPDATE_ADULT_KNEE_SYMPTOMS_DATA,
  UPDATE_ADULT_KNEE_FUNCTION_FIELD_DATA,
  UPDATE_ADULT_KNEE_FUNCTION_DATA,
  UPDATE_ADULT_KNEE_QOL_FIELD_DATA,
  UPDATE_ADULT_KNEE_QOL_DATA,
  UPDATE_ADULT_KNEE_SATISFACTION_FIELD_DATA,
  UPDATE_ADULT_KNEE_SATISFACTION_DATA,
  UPDATE_ADULT_KNEE_TOTAL_SCORES,
  UPDATE_ADULT_KNEE_NORWICHINSTA_FIELD_DATA,
  UPDATE_ADULT_KNEE_NORWICHINSTA_DATA,
  UPDATE_FOOT_FEEDBACK_FIELD_DATA,
  UPDATE_ADULT_KNEE_FEEDBACK
} from "./actionTypes";

export const showLoader = () => {
  return {
    type: SHOW_LOADER,
  };
};
export const hideLoader = () => {
  return {
    type: HIDE_LOADER,
  };
};

export const fetchUserIdSuccess = (products) => {
  return {
    type: FETCH_SUBID_SUCCESS,
    data: products,
  };
};

export const fetchProductsFail = (error) => {
  return {
    type: FETCH_SUBID_FAIL,
    data: error,
  };
};

export const fetchMailSuccess = (user) => {
  return {
    type: FETCH_MAIL_SUCCESS,
    data: user,
  };
};

export const fetchMailFail = (error) => {
  return {
    type: FETCH_MAIL_FAIL,
    data: error,
  };
};

export const loginData = (e) =>{
  return {
    type: 'SET_LOGIN_DATA',
    data: e,
  };
}
export const timeZone = (e) =>{
  return {
    type: 'SET_TIME_ZONE',
    data: e,
  };
}
export const noteRefresh = (e) =>{
  return {
    type: 'SET_REFRESH',
    data: e,
  };
}
export const patientDetails = (e) =>{
  return {
    type: 'SET_PATIENTDETAILS',
    data: e,
  };
}
export const chatRefresh = (e) =>{
  return {
    type: 'SET_CHATREFRESH',
    data: e,
  };
}
export const accessFeatures = (e) =>{
  return {
    type: 'SET_FEATURES',
    data: e,
  };
}



export const accessToken = (e) =>{
  return {
    type: 'SET_TOKEN',
    data: e,
  };
}



export const updateAdultKneeScores = (scores) => {
  return {
    type: UPDATE_ADULT_KNEE_DATA,
    data: scores,
  };
};

export const updateAdultKneePainFieldScore = (score) => {
  return {
    type: UPDATE_ADULT_KNEE_PAIN_FIELD_DATA,
    data: score,
  };
};
export const updateAdultKneePainScores = (scores) => {
  return {
    type: UPDATE_ADULT_KNEE_PAIN_DATA,
    data: scores,
  };
};

export const updateAdultKneeSymptomsScoreField = (score) => {
  return {
    type: UPDATE_ADULT_KNEE_SYMPTOMS_DATA_SINGLE,
    data: score,
  };
};
export const updateAdultKneeSymptomsScore = (scores) => {
  return {
    type: UPDATE_ADULT_KNEE_SYMPTOMS_DATA,
    data: scores,
  };
};

export const updateAdultKneeFunctionFieldScore = (score) => {
  return {
    type: UPDATE_ADULT_KNEE_FUNCTION_FIELD_DATA,
    data: score,
  };
};
export const updateAdultKneeFunctionScore = (scores) => {
  return {
    type: UPDATE_ADULT_KNEE_FUNCTION_DATA,
    data: scores,
  };
};

export const updateAdultKneeQolFieldScore = (score) => {
  return {
    type: UPDATE_ADULT_KNEE_QOL_FIELD_DATA,
    data: score,
  };
};
export const updateAdultKneeQolScore = (scores) => {
  return {
    type: UPDATE_ADULT_KNEE_QOL_DATA,
    data: scores,
  };
};

export const updateAdultKneeSatisfactionFieldScore = (score) => {
  return {
    type: UPDATE_ADULT_KNEE_SATISFACTION_FIELD_DATA,
    data: score,
  };
};
export const updateAdultKneeSatisfactionScore = (scores) => {
  return {
    type: UPDATE_ADULT_KNEE_SATISFACTION_DATA,
    data: scores,
  };
};

export const updateAdultKneeTotalScores = (scores) => {
  return {
    type: UPDATE_ADULT_KNEE_TOTAL_SCORES,
    data: scores,
  };
};

export const updateAdultKneeNorwichInstaFieldScore = (score) => {
  return {
    type: UPDATE_ADULT_KNEE_NORWICHINSTA_FIELD_DATA,
    data: score,
  };
};

export const updateAdultKneeNorwichInstaScore = (scores) => {
  return {
    type: UPDATE_ADULT_KNEE_NORWICHINSTA_DATA,
    data: scores,
  };
};

export const updateAdultKneeFieldFeedback = (scores) => {
  return {
    type: UPDATE_FOOT_FEEDBACK_FIELD_DATA,
    data: scores,
  };
};
export const updateAdultKneeFeedback = (scores) => {
  return {
    type: UPDATE_ADULT_KNEE_FEEDBACK,
    data: scores,
  };
};
