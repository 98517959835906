import React, { useEffect, useRef, useState } from "react";
import InputField from "../../custom/inputfield/InputField";
import settingsStyle from "../Settings.module.css";
import useAxios from "../../../axiosinstance";
import Button from "../../custom/button/Button";
import Checkbox from "../../custom/checkbox/Checkbox";
import Dropdown from "../../custom/dropdown/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import Message from "../../custom/toster/Message";
import { Validators } from "../../../utilities/Validator";
import { loginData } from "../../../redux/actions";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import GoogleAddress from "../../custom/google-api-address/GoogleAddress";
export default function Preference() {
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const axiosInstance = useAxios();
  const [email, setEmail] = useState("");
  const [Proms, setProms] = useState(false);
  const [Clinical, setClinical] = useState(false);
  const [schedular, setSchedular] = useState(false);
  const [Akunah, setAkunah] = useState(false);
  const [Radiology, setRadiology] = useState(false);
  const [Pathology, setPathology] = useState(false);
  const [Prescription, setPrescription] = useState(false);
  const [Insurance, setInsurance] = useState(false);
  const [forms, setForms] = useState(false);
  const [note, setNote] = useState(false);
  const [Documents, setDocuments] = useState(false);
  const [order, setorder] = useState("");
  const [dataRef, setDataRef] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState();
  const [emailErr, setEmailErr] = useState(false);
  const [update2, setHandleUpdate12] = useState(null);
  const emailRef = useRef();
  const dispatch = useDispatch();
  let akToken = localStorage.getItem("AkTOKEN");

  const handleImplantInput = (e, err) => {
    setEmail(e);
    setEmailErr(err);
  };
  const handleProms = (e) => {
    setProms(e);

    setHandleUpdate12(!update2);
  };
  const handleClinical = (e) => {
    setClinical(e);

    setHandleUpdate12(!update2);
  };
  const handleSchedular = (e) => {
    setSchedular(e);

    setHandleUpdate12(!update2);
  };
  const handleAkunah = (e) => {
    setAkunah(e);

    setHandleUpdate12(!update2);
  };
  const handleRadiology = (e) => {
    setRadiology(e);

    setHandleUpdate12(!update2);
  };
  const handlePathology = (e) => {
    setPathology(e);

    setHandleUpdate12(!update2);
  };
  const handlePrescription = (e) => {
    setPrescription(e);

    setHandleUpdate12(!update2);
  };
  const handleInsurance = (e) => {
    setInsurance(e);

    setHandleUpdate12(!update2);
  };
  const handleForms = (e) => {
    setForms(e);

    setHandleUpdate12(!update2);
  };
  const handleNote = (e) => {
    setNote(e);

    setHandleUpdate12(!update2);
  };
  const handleDocuments = (e) => {
    setDocuments(e);

    setHandleUpdate12(!update2);
  };
  useEffect(() => {
    if (update2 !== null) handleUpdate1(true);
  }, [update2]);
  const handleOrder = (e) => {
    setorder(e);

    if (e.length > 0 && e !== order) {
      handleUpdate1(false, e);
    }
  };

  const handleUpdate = () => {
    const json = JSON.stringify({
      official_email: email,
    });
    axiosInstance.current
      .put(`extapp/admins/update_mail?ak_id=${login.ing_ak_id}`, json, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 201) {
          handleConnectMe();
          setMessage("updated");
          setMessageType("success");
        }
      })
      .catch((err) => {
        setMessage("Something went wrong");
        setMessageType("error");
      });
  };

  useEffect(() => {
    if (login.official_email && login.ing_email !== login.official_email) {
      setEmail(login.official_email);
    } else if (login.ing_email) {
      setEmail(login.ing_email); 
    }
  }, [login.ing_email, login.official_email]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extapp/admins/get_widgets?ak_id=${login.ing_ak_id}`
        );
        if (response.status === 200) {
          const selectedOrderFromApi = response.data.data.default_sorting;
          // const selectedOrderFromApi1 = response.data.data.user_widget
          setProms(response.data.data.user_widget.proms);
          setClinical(response.data.data.user_widget.clinical_exam);
          setAkunah(response.data.data.user_widget.akunah_connect);
          setSchedular(response.data.data.user_widget.proms_scheduler);

          setRadiology(response.data.data.user_widget.radiology);
          setPathology(response.data.data.user_widget.pathology);
          setPrescription(response.data.data.user_widget.prescription);
          setInsurance(response.data.data.user_widget.insurance);
          setForms(response.data.data.user_widget.overdue_form);
          setNote(response.data.data.user_widget.treatment_note);
          setDocuments(response.data.data.user_widget.documents);
          setorder(selectedOrderFromApi);

          console.log(
            selectedOrderFromApi,
            "selectedOrderFromApi",
            response.data
          );
        }
      } catch (err) {
        console.log("Error");
      }
    };
    getData();
  }, [login.ing_ak_id, dataRef]);

  const handleUpdate1 = (type, order) => {
   
    const existingData = {
      proms: Proms,
      clinical_exam: Clinical,
      akunah_connect: Akunah,
      proms_scheduler: schedular,
      radiology: Radiology,
      pathology: Pathology,
      prescription: Prescription,
      insurance: Insurance,
      overdue_form: forms,
      treatment_note: note,
      documents: Documents,
    };
    let json;
    if (type) {
      json = JSON.stringify({
        user_widget: existingData,
      });
    } else {
      json = JSON.stringify({
        default_sorting: order,
      });
    }
    console.log("Existing Data:", existingData);
    console.log(json, "jsonw");
    axiosInstance.current
      .put(`extapp/admins/update_widgets?ak_id=${login.ing_ak_id}`, json, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 201) {
          setDataRef(new Date().getMilliseconds());
          handleConnectMe()
          setMessage("updated");
          setMessageType("success");
          setTimeout(() => {
            setMessage(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setDataRef(new Date().getMilliseconds());
        setMessage("Something went wrong");
        setMessageType("error");
      });
  };

  const handleConnectMe = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/doctors/myprofile?ak_id=${akToken}`
      );
      if (result.status == "200") {
        // if(result.message)

        if (result.data.data.ing_acc_status == 1) {
          dispatch(loginData(result.data.data));
        }
      }
    } catch (err) {
      console.log(err.message || "Unexpected Error!");
    } finally {
      console.log("api response finally");
    }
  };

  const handleSave = () => {
    if (!email) {
      emailRef.current.errUpdate();
    }
    if (!email || emailErr) {
    } else {
      handleUpdate();
      // handleUpdate1();
    }
  };

  const inputRef = useRef();
  const postCodeRef = useRef();
  const suburbRef = useRef();
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [postCode, setPostCode] = useState("");
  const [suburb, setSuburb] = useState("");
  const [postCodeErr, setPostCodeErr] = useState(false);
  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setAddress(place.formatted_address);
      setLocation(place.formatted_address);
      // console.log(place.formatted_address);
      console.log(place.address_components, "placesss");

      place.address_components.map((ele) => {
        if (ele.types.includes("postal_code")) {
          // console.log(ele.long_name);
          setPostCode(ele.long_name);
        }
      });
      place.address_components.map((ele) => {
        if (ele.types.includes("locality")) {
          // console.log(ele.long_name);
          setSuburb(ele.long_name);
        }
      });
      // console.log(place.geometry.location.lng());

      // console.log(place, "placesss");
    }
  };
  const handleLocation = (e) => {
    setAddress(e);
    setLocation(e);
  };
  const handleSuburb = (e) => {
    setSuburb(e);
  };
  const handlePostCode = (e, err) => {
    setPostCode(e);
    setPostCodeErr(err)
  };

useEffect(()=>{
  if(login.ing_ak_id){
    setLocation(login.treatAdd.address)
    setSuburb(login.treatAdd.suburb)
    setPostCode(login.treatAdd.postCode)
  }
},[login])
let treatment_note_address = {
  treatment_note_address : {
    address,
    suburb,
    postCode
  }
}
  const handleAddressSave=async()=>{
    const result = await axiosInstance.current.put(`extapp/admins/treat_address/${login.ing_ak_id}`, treatment_note_address, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    if (result.status === 201) {
      setMessage("updated");
          setMessageType("success");
          setTimeout(() => {
            setMessage(false);
          }, 3000);
    }
  }
  return (
    <div>
      <h4 class={`${settingsStyle.content_main_title}`}>
        {"My official email"}
      </h4>
      <div className="row">
        <div className="col-sm-6">
          <InputField
            label=""
            value={email}
            type="text"
            placeholder="Email"
            onChange={handleImplantInput}
            styleClass="br_10 mxw_100 mb_20"
            ref={emailRef}
            validators={[
              {
                check: Validators.required,
                message: "This field is required",
              },
              {
                check: Validators.email,
                message: "Only contain Email",
              },
            ]}
          />
        </div>
        <div className="col-sm-6">
          <Button
            onClick={() => handleSave()}
            value="Update"
            buttonStyle="btn_fill mr_20"
          />

          {message && <Message message={message} type={messageType} />}
        </div>
      </div>
      <hr />
      <h4 class={`${settingsStyle.content_main_title}`}>
        {"Treatment note address"}
      </h4>
     
      <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  <label>Address</label>
                  <InputField
                    label=""
                    value={location}
                    // onChange={handleAddress}
                    onChange={(e) => {
                      handleLocation(e);
                    }}
                    type="text"
                    placeholder="address"
                    styleClass="br_10 "
                    />
                  {/* <GoogleAddress
                      onAddressChange={handleLocation}
                      // valid={addressError}
                      value={address}
                      styleClass={"mxw_100 "}
                      handleCity={handleSuburb}
                      handlePostCode={handlePostCode}
                    /> */}
                  {/* <LoadScript
                    googleMapsApiKey={"AIzaSyAb4s2rhkg_dDms-SKZx16Ka5zDcU6aEIs"}
                    libraries={["places"]}
                  >
                    <StandaloneSearchBox
                      onLoad={(ref) => (inputRef.current = ref)}
                      // searchBoxRef={searchBoxRef}
                      onPlacesChanged={handlePlaceChanged}
                      // placeholder="Search for a place..."
                    >
                      <input
                        type="text"
                        className="form-control  br_10"
                        placeholder="address"
                        value={location}
                        // onChange={handleAddress}
                        onChange={(e) => {
                          handleLocation(e.target.value);
                        }}
                      />
                    </StandaloneSearchBox>
                  </LoadScript> */}
                </div>
                <div className="col-sm-5 col-md-5 mb_20">
                  <InputField
                    label="Postcode"
                    value={postCode}
                    ref={postCodeRef}
                    type="text"
                    placeholder="_ _ _ _ _ _ _ _ _ _"
                    onChange={handlePostCode}
                    validators={[
                      // {
                      //   check: Validators.required,
                      //   message: translation.This_field_is_required,
                      // },
                      {
                        check: Validators.spacial,
                        message:"Caractère invalide",
                      },
                      {
                        check: Validators.postcode,
                        message: "Field only contains 10 characters"
                      },
                    ]}
                    styleClass="br_10 "
                  />
                </div>
              </div>
              <div className="row">  
                <div className="col-sm-5 col-md-5 mb_20">
                  <InputField
                    label="Suburb"
                    value={suburb}
                    ref={suburbRef}
                    type="text"
                    placeholder="Suburb"
                    onChange={handleSuburb}
                   
                    styleClass="br_10 "
                  />
                </div>
                <div className="col-sm-5 col-md-5 mb_20 mt-4 text-end">
                <Button
            onClick={() => handleAddressSave()}
            value="Update"
            buttonStyle="btn_fill mr_20 mt_20"
          />

                </div>
              </div>

      <hr/>
      <br />
      <h4 class={`${settingsStyle.content_main_title}`}>
        {"Patient dashboard visible widgets"}
      </h4>

      <div className="row">
        <div className="col-md-6 col-sm-6">
          <Checkbox
            label="PROMs"
            onChange={handleProms}
            selected={Proms}
            styleClass="custom_check_box tick f_400 large mb_10"
            id="Proms"
          />
        </div>
        <div className="col-md-6 col-sm-6">
          <Checkbox
            label="Clinical exams"
            onChange={handleClinical}
            selected={Clinical}
            styleClass="custom_check_box tick f_400 large mb_10"
            id="Clinical"
          />
        </div>
        <div className="col-md-6 col-sm-6">
          <Checkbox
            label="Akunah connect"
            onChange={handleAkunah}
            selected={Akunah}
            styleClass="custom_check_box tick f_400 large mb_10"
            id="Akunah"
          />
        </div>
        <div className="col-md-6 col-sm-6">
          <Checkbox
            label="PROMs schedular"
            onChange={handleSchedular}
            selected={schedular}
            styleClass="custom_check_box tick f_400 large mb_10"
            id="schedular"
          />
        </div>
        <div className="col-md-6 col-sm-6">
          <Checkbox
            label="Radiology"
            onChange={handleRadiology}
            selected={Radiology}
            styleClass="custom_check_box tick f_400 large mb_10"
            id="Radiology"
          />
        </div>

        <div className="col-md-6 col-sm-6">
          <Checkbox
            label="Pathology"
            onChange={handlePathology}
            selected={Pathology}
            styleClass="custom_check_box tick f_400 large mb_10"
            id="Pathology"
          />
        </div>
        <div className="col-md-6 col-sm-6">
          <Checkbox
            label="Prescription"
            onChange={handlePrescription}
            selected={Prescription}
            styleClass="custom_check_box tick f_400 large mb_10"
            id="Prescription"
          />
        </div>
        <div className="col-md-6 col-sm-6">
          <Checkbox
            label="Insurance"
            onChange={handleInsurance}
            selected={Insurance}
            styleClass="custom_check_box tick f_400 large mb_10"
            id="Insurance"
          />
        </div>
        <div className="col-md-6 col-sm-6">
          <Checkbox
            label="Overdue forms"
            onChange={handleForms}
            selected={forms}
            styleClass="custom_check_box tick f_400 large mb_10"
            id="forms"
          />
        </div>
        <div className="col-md-6 col-sm-6">
          <Checkbox
            label="Treatment note"
            onChange={handleNote}
            selected={note}
            styleClass="custom_check_box tick f_400 large mb_10"
            id="note"
          />
        </div>
        <div className="col-md-6 col-sm-6">
          <Checkbox
            label="Documents"
            onChange={handleDocuments}
            selected={Documents}
            styleClass="custom_check_box tick f_400 large mb_10"
            id="Documents"
          />
        </div>
      </div>
    
      <hr />
      <h4 class={`${settingsStyle.content_main_title}`}>{"My Patient lists default sorting order"}</h4>
      <div className="row ">
        <div className="col-sm-6">
          <Dropdown
            data={[
              { value: "last_created", label: "Last modified" },
              { value: "frequently_used", label: "Frequently used" },
              { value: "first_name", label: "By first name" },
              { value: "last_name", label: "By last name" },
            ]}
            label={""}
            styleClass="br_10 mxw_100 mb_20"
            value={order}
            onChange={handleOrder}
            placeholder={"Please select"}
          />
        </div>
      </div>
    </div>
  );
}
