import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
// import { BrowserRouter as Router, useLocation } from "react-router-dom";
import "./TopHeader.css";
import leftlogo from "../../images/left-logo.svg";
import backArrow from "../../images/head-back-arr.svg";
import chatIcon from "../../images/chat-icon.svg";
import searchGreyIcon from "../../images/search-grey.svg";
import searchBlueIcon from "../../images/search-blue.svg";
import searchCloseIcon from "../../images/search-close.svg";
import searchCloseIconGrey from "../../images/search-close-grey.svg";
import signOutIcon from "../../images/Sign out.svg";
import switchImg from "../../images/sidebar/transfer.png";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "../custom/dropdown/Dropdown";
import { Validators } from "../../utilities/Validator";
import Modal from "react-bootstrap/Modal";
import useAxios from "../../axiosinstance";
import useAxiosUpload from "../../axiosinstanceUpload";
import Message from "../custom/toster/Message";
import { timeZone } from "../../redux/actions/index";
import { chatRefresh } from "../../redux/actions";
import { loginData } from "../../redux/actions/index";
// import LanguageHandler from "../LanguageHandler";
import useTranslation from "../customHooks/translations";
// import TimeClock from "../custom/TimeClock";
import { useSearchParams } from "react-router-dom";
import { s3UrlLink } from "../../utilities/S3Url";
import { spacialCharReplace } from "../../utilities/Validator";
// import Search from "./Search";
import Clock from "react-live-clock";
import "../custom/delete-confirmation/DeleteConfirmation.css";
import DeleteIcon from "../../images/delete-red.svg";
import icon_info from "../../images/info-icon.svg";
import platform from "platform";
const TopHeader = () => {
  const dispatch = useDispatch();
  const translation = useTranslation();
  const axiosInstance = useAxios();
  const axiosInstanceUpload = useAxiosUpload();
  // const location = useLocation();
  // const { pathname } = location;
  // const splitLocation = pathname.split("/");
  const { keycloak } = useKeycloak();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const navigate = useNavigate();
  //create initial menuCollapse state using useState hook
  const [isOpened, setIsOpened] = useState(false);
  // const [chatPopup, setChatPopup] = useState(false);
  const [editProfileShow, setEditProfileShow] = useState(false);
  // const [needHelpShow, setNeedHelpShow] = useState(false);
  const [firstName, setFirstName] = useState(login.ing_firstname);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastName, setLastName] = useState(login.ing_lastname);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [phone, setPhone] = useState(login.ing_mobile);
  const [phoneErr, setPhoneErr] = useState(false);
  const [email, setEmail] = useState(login.ing_email);
  const [timeZones, setTimeZone] = useState(login.ing_timezone);
  const [noteSearch, setNoteSearch] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const handleClose = () => setEditProfileShow(false);
  const [provideNumber, setProvideNumber] = useState(login.ing_provider_number);
  const [provideNumberErr, setProvideNumberErr] = useState(false);
  const provideNumberRef = useRef();
  const handleShow = () => {
    setIsOpened(false);
    setEditProfileShow(true);
  };
  // const handleNeedHelpClose = () => setNeedHelpShow(false);
  // const handleNeedHelpShow = () => setNeedHelpShow(true);
  const [doctor] = useState(login.ing_ak_id);
  const [message, setMessage] = useState(false);
  let akToken = localStorage.getItem("AkTOKEN");
  const [messageType, setMessageType] = useState();
  // const [image, setImage] = useState("");
  const [selectedImage, setSelectedImage] = useState(login.ing_image);
  // const [localization, setLocalization] = useState("");
  const [searchParams, setSearchParams] = useSearchParams({});
  const logout_url = window.location.origin;
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const phoneRef = useRef();
  const timeZoneRef = useRef();
  const myButtonRef = useRef(null);
  let dd = searchParams.get("q");
  const [modalOpenSmall, setModalOpenSmall] = useState(false);
  const [modalOpenSmallDelete, setModalOpenSmallDelete] = useState(false);
  const [countryCode, setCountryCode] = useState(login.country_code);
  const [country, setCountry] = useState([]);

  const closeModalSmall = () => {
    setModalOpenSmall(false);
  };
  const closeModalSmallDelete = () => {
    setModalOpenSmallDelete(false);
  };
  const handleDelte = () => {
    axiosInstance.current
      .get(`extapp/forms/delete_account?ak_id=${doctor}`)
      .then((res) => {
        setMessage(res.data.data);
        setMessage(res.data.data);
        setMessageType("success");
        setEditProfileShow(false);
        setModalOpenSmallDelete(false);
        setTimeout(() => {
          setMessage(false);
        }, [3000]);
      });
  };
  const getCountryData = async () => {
    try {
      const result = await axiosInstance.current.get(`extapp/countrylist`);
      if (result.status == "200") {
        setCountry(result.data.data);
      }
    } catch (err) {
      // setMessage(err.message || "Unexpected Error!");
      console.log(err.Messages);
      // setMessageType("error");
    } finally {
      console.log("api response finally");
    }
  };

  useEffect(() => {
    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 1000);
    getCountryData();
  }, []);

  useEffect(() => {
    if (dd) {
      setNoteSearch(searchParams.get("q"));
    } else {
      setNoteSearch("");
    }
  }, [dd]);
  const handleFirstName = (e, err) => {
    setFirstNameErr(err);
    setFirstName(spacialCharReplace(e));
  };
  const handleLastName = (e, err) => {
    setLastNameErr(err);
    setLastName(spacialCharReplace(e));
  };
  const handlePhone = (e, err) => {
    setPhone(e);
    setPhoneErr(err);
  };
  const handleEmail = (e) => {
    setEmail(e);
  };
  const handleTimeZone = (e) => {
    setTimeZone(e);
  };
  const userIconClick = () => {
    isOpened ? setIsOpened(false) : setIsOpened(true);
    closeModalSmall();
  };
  // const handleChatPopup = () => {
  //   setNeedHelpShow(false);
  //   setChatPopup(!chatPopup);
  // };
  const noteSearchChange = (e) => {
    setNoteSearch(e.target.value);
  };
  const handleProvide = (e, err) => {
    setProvideNumber(e);
    setProvideNumberErr(err);
  };
  const handleClick = () => {
    navigate(-1, { replace: false });
  };
  useEffect(() => {
    const getData = async () => {
      const response = await axiosInstance.current.get(`extapp/alltimezones`);
      dispatch(timeZone(response.data.data));
    };
    getData();
  }, []);

  const handleCountryCode = (e) => {
    setCountryCode(e);
  };
  const countryCodeRef = useRef();

  const time = useSelector((state) => state.mainReducer.timeZone.arr);
  const data1 = time.map((item) => {
    return { value: item.time, label: item.utctime };
  });
  const handleSave = () => {
    if (!firstName) {
      firstNameRef.current.errUpdate();
    }
    if (!lastName) {
      lastNameRef.current.errUpdate();
    }
    if (!phone) {
      phoneRef.current.errUpdate();
    }

    if (!timeZones) {
      timeZoneRef.current.errUpdate();
    }
    if (!provideNumber) {
      provideNumberRef.current.errUpdate();
    }
    if (!countryCode) {
      countryCodeRef.current.errUpdate();
    }

    if (
      !firstName ||
      !lastName ||
      !phone ||
      !timeZone ||
      !provideNumber ||
      phoneErr ||
      firstNameErr ||
      lastNameErr ||
      !countryCode ||
      provideNumberErr
    ) {
      console.log("error");
    } else {
      // setDoctor(login.ing_ak_id)
      const json = JSON.stringify({
        firstname: firstName,
        lastname: lastName,
        mobile: phone,
        timezone: timeZones,
        provide_number: provideNumber,
        country_code: countryCode,
      });
      axiosInstance.current
        .put(`extapp/doctors/doctor_update_profile?ak_id=${doctor}`, json, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          handleConnectMe();
          setMessage(res.data.data.message);
          setMessageType("success");
          setEditProfileShow(false);
        });
    }
  };

  const Remove = () => {
    setSelectedImage(null);
    const json = JSON.stringify({
      profile_image: "",
    });
    axiosInstanceUpload.current
      .put(
        `extglobal/remove_profile_pic?resource_id=${login.ing_resource_id}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setEditProfileShow(false);
        setMessage(res.data.data.message);
        setMessageType("success");
      });
  };
  const handleConnectMe = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/doctors/myprofile?ak_id=${akToken}`
      );
      if (result.status === 200) {
        // if(result.message)
        if (result.data.data.ing_acc_status === 1) {
          dispatch(loginData(result.data.data));
        }
      }
    } catch (err) {
      // console.log(err.message || "Unexpected Error!");
    } finally {
      console.log("api response finally");
    }
  };
  function onFileUpload(e) {
    const formData = new FormData();
    formData.append("profile_pic", selectedImage);
    // console.log(Object.keys(formData).length);
    // if (Object.keys(formData).length !== 0)
    axiosInstanceUpload.current
      .post(`extglobal/updateprofile?ak_id=${doctor}`, formData)
      .then((response) => {
        if (response) {
          setSelectedImage(response.data.url);
          handleConnectMe();
        }
      })
      .catch((err) => {
        // setMessage(err.response.data.message);
        // setMessageType("error");
      });
  }
  useEffect(() => {
    if (typeof selectedImage === "object") {
      setMessage(false);

      onFileUpload();
    }
  }, [selectedImage]);

  // const handleSubmit = (data) => {
  //   data.preventDefault();
  //   alert(1);
  // };
  // const UpdateLogin = async () => {
  //   try {
  //     const result = await axiosInstance.current.put(
  //       `extapp/Logout?ak_id=${doctor}&browser=${browser}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //   } catch (err) {
  //     // setMessage(err.message);
  //     // setMessageType("error");
  //   } finally {
  //     console.log("api response finally");
  //   }
  // };

  const [browser, setBrowser] = useState("");
  useEffect(() => {
    const deviceInfo = platform;
    setBrowser(deviceInfo.description.toLowerCase().replace(/\s/g, ""));
    console.log(
      platform.description.toLowerCase().replace(/\s/g, ""),
      "platform.description"
    );
  }, []);
  let logdata = localStorage.getItem("AkTOKEN");
  const handleLogout = async () => {
    if (logdata) {
      try {
        const result = await axiosInstance.current.put(
          `extapp/Logout?ak_id=${doctor}&browser=${browser}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (result.data.status) {
          localStorage.removeItem("AkTOKEN");
          sessionStorage.removeItem("AkTOKEN");
          keycloak.logout({ redirectUri: logout_url });
        }
      } catch (err) {
        // setMessage(err.message);
        // setMessageType("error");
      } finally {
        console.log("api response finally");
      }
    } else {
      localStorage.removeItem("AkTOKEN");
      sessionStorage.removeItem("AkTOKEN");
      keycloak.logout({ redirectUri: logout_url });
    }
  };

  // const handleLocalization = (e) => {
  //   setLocalization(e);
  // };

  // const onChange = (e) => {
  //    setSearchParams({ query: e.target.value });
  //   setNoteSearch(e.target.value);
  // }
  const handelClick = () => {
    // navigate("/patient-list");
  };
  const searchClose = () => {
    // alert(1);
    setNoteSearch("");
    setSearchParams();
  };
  const handleFormSubmit = (event) => {
    event.preventDefault();
    // setSearchParams({ q: noteSearch, search: "Y" });
    navigate(`/patient-list?q=${noteSearch}&search=Y`);
  };

  const handleDeletePop = () => {
    setModalOpenSmallDelete(true);
    setEditProfileShow(false);
  };

  const handlerSwitch = () => {
    localStorage.removeItem("AkTOKEN");
    dispatch(loginData(""));
    navigate("/doctor-map");
  };
  const [chatActive, setChatActive] = useState(false);

  const Chart = () => {
    // Remove the dot from the class name

    let elements = document.getElementsByClassName(
      "zammad-chat zammad-chat-is-loaded zammad-chat-is-shown"
    );
    if (chatActive) {
      setChatActive(!chatActive);
      // getElementsByClassName returns a collection, so you need to loop through the elements
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = "";
      }
    } else {
      setChatActive(!chatActive);
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = "none";
      }
    }
  };
  useEffect(() => {
    if (!chatActive) {
      let elements = document.getElementsByClassName(
        "zammad-chat zammad-chat-is-loaded zammad-chat-is-shown"
      );
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = "none";
      }
    }
  }, []);
  const handleClickChat = () => {
    Chart();
    console.log(myButtonRef, "myButtonRef");
    myButtonRef.current.click();
    dispatch(chatRefresh(myButtonRef));
  };

  const json = JSON.stringify({
    ak_id: doctor,
    status: "login",
    browser: browser,
  });
  const RegisterLogin = async () => {
    try {
      const result = await axiosInstance.current.post(
        `extapp/Registerlogin`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err) {
      // setMessage(err.message || "Unexpected Error!");
      console.log(err.Messages);
      // setMessageType("error");
    } finally {
      console.log("api response finally");
    }
  };
  useEffect(() => {
    if (browser) {
      RegisterLogin();
    }
  }, [browser]);

  const getLogin = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/fetchsessions?ak_id=${doctor}&browser=${browser}&resource_id`
      );
      if (result) {
        // console.log(result.data.data, "mydats")
      }
    } catch (err) {
      if (err.response.status === 400) {
        window.location.reload();
        keycloak.logout({ redirectUri: logout_url });
      }
    }
  };

  useEffect(() => {
    if (browser) {
      const intervalId = setInterval(() => {
        getLogin();
      }, 30000);
      return () => clearInterval(intervalId);
    }
  }, [browser]);

  return (
    <>
      <div className="top__header">
        {message && <Message message={message} type={messageType} />}
        <div className="nav_inner_left">
          <Link to="/" className="leftLogo">
            <img src={leftlogo} className="left-logo" alt="logo" />
          </Link>
          <div className="head_left_arrows">
            <span className="head_back_icon " onClick={handleClick}>
              <img src={backArrow} title="Back" alt="" />
            </span>
          </div>
        </div>
        <div className="nav_inner_search">
          {/* <Search></Search> */}
          <form onSubmit={handleFormSubmit}>
            <div className="search-box">
              <div
                className={`input-group ${
                  searchActive === true ? "search_active" : ""
                }`}
              >
                <span className="input-group-addon first">
                  <img
                    className={`search_grey_icon`}
                    src={searchGreyIcon}
                    alt=""
                  />
                  <img
                    className={`search_blue_icon`}
                    src={searchBlueIcon}
                    alt=""
                  />
                </span>

                <input
                  value={noteSearch}
                  type="text"
                  placeholder="Search Patient"
                  onFocus={(e) => {
                    setSearchActive(true);
                  }}
                  onBlur={(e) => {
                    setSearchActive(false);
                  }}
                  onChange={noteSearchChange}
                  className="form-control input_search"
                  onClick={() => handelClick()}
                />
                <span
                  className="input-group-addon first"
                  onClick={() => searchClose()}
                >
                  <span onClick={() => searchClose()} className="c_pointer">
                    <img
                      className={``} //search_close_icon
                      src={searchCloseIconGrey}
                      alt=""
                    />
                    <img
                      className={``} //search_close_icon
                      src={searchCloseIcon}
                      alt=""
                    />
                  </span>
                </span>
              </div>
            </div>
          </form>
        </div>
        <div className="nav_inner_right">
          <div className="right_user_menu">
            <ul className="list-inline">
              <li className="chat-menu list-inline-item">
                {/* <LanguageHandler></LanguageHandler> */}
              </li>
              <li className="chat-menu list-inline-item" ref={myButtonRef}>
                <img
                  src={chatIcon}
                  title="Chat"
                  alt=""
                  onClick={handleClickChat}
                />
              </li>
              <li className="list-inline-item use_lett">
                <label
                  onClick={() => {
                    userIconClick();
                  }}
                  className="user-letter"
                >
                  {login.ing_image === "" ? (
                    login.ing_firstname.slice(0, 1) +
                    login.ing_lastname.slice(0, 1)
                  ) : (
                    <img
                      src={s3UrlLink(login.ing_image)}
                      alt={login.ing_firstname}
                    />
                  )}
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {isOpened && (
        <div className="sidenav_right">
          <div className="rightnavInner">
            <div className="row">
              <div className="col-sm-6">
                <h6 className="left_title">{translation.profile}</h6>
              </div>
              <div className="col-sm-6">
                <span
                  onClick={() => {
                    userIconClick();
                  }}
                  className="close_btn"
                >
                  &#10006;
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="pf_details">
                  <label className="user-letter large">
                    {login.ing_image === "" ? (
                      login.ing_firstname.slice(0, 1) +
                      login.ing_lastname.slice(0, 1)
                    ) : (
                      <img
                        src={s3UrlLink(login.ing_image)}
                        alt={login.ing_firstname}
                      />
                    )}
                    {/* { login.ing_firstname.slice(0, 1)} */}
                  </label>
                  <h4 className="text-capitalize">{login.ing_fullname}</h4>
                  <button
                    type="button"
                    className="btn_outline"
                    onClick={handleShow}
                  >
                    {translation.Edit}
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <p className="pf_left_btext">
                  <strong>{translation.name}:</strong>
                </p>
              </div>
              <div className="col-sm-8">
                <p>{login.ing_fullname}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <p className="pf_left_btext">
                  <strong>{translation.akunah}:</strong>
                </p>
              </div>
              <div className="col-sm-8">
                <p>{login.ing_ak_id}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <p className="pf_left_btext">
                  <strong>{translation.phone}:</strong>
                </p>
              </div>
              <div className="col-sm-8">
                <p>{login.ing_mobile}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <p className="pf_left_btext">
                  <strong>{translation.email}:</strong>
                </p>
              </div>
              <div className="col-sm-8">
                <p>{login.ing_email}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <p className="pf_left_btext">
                  <strong>{translation.localTime}:</strong>
                </p>
              </div>
              <div className="col-sm-8">
                {login.ing_timeformat === "2" && login.ing_timezone !== "" && (
                  <p>
                    <Clock
                      format={"HH:mm:ss"}
                      ticking={true}
                      timezone={login.ing_timezone}
                    />
                    {/* <TimeClock offset={+5}></TimeClock> */}
                  </p>
                )}
                {login.ing_timeformat === "1" && login.ing_timezone !== "" && (
                  <p>
                    <Clock
                      format={"h:mm A"}
                      ticking={true}
                      timezone={login.ing_timezone}
                    />
                    {/* <TimeClock offset={+5}></TimeClock> */}
                  </p>
                )}
              </div>
            </div>

            <span className="signout_btn" onClick={() => handleLogout()}>
              <img src={signOutIcon} alt="icon" />
              {translation.logOut}
            </span>

            <span className="switch_btn" onClick={() => handlerSwitch()}>
              <img src={switchImg} alt="icon" />
              Switch profile
            </span>

            <div>
              <p
                className="text-end c_pointer"
                style={{ color: "#3f2783" }}
                onClick={() => setModalOpenSmall(true)}
              >
                {" "}
                <b>Reset Password </b>
              </p>
            </div>
          </div>
        </div>
      )}
      {/* {chatPopup && (
        <>
          <div
            className="chat__popup--main"
            id="chatPopup"
            style={{ display: "block" }}
          >
            <div className="chat_main">
              <div className="chat_head">
                <div className="row">
                  <div className="col-md-9">
                    <img
                      src={chatIcon}
                      title="Chat"
                      alt=""
                      className="chat_left_img"
                    />
                    <span className="chat_title">{translation.chat}</span>
                  </div>
                  <div className="col-md-3 text-end">
                    <button
                      className="circle__btn"
                      id="chatClose"
                      data-animation="simpleRotate"
                      data-remove="200"
                      data-lastmsgid="3792"
                      onClick={() => {
                        setChatPopup(!chatPopup);
                      }}
                    >
                      ✖
                    </button>
                  </div>
                </div>
              </div>
              <div className="chat_body">
                <div className="message">
                  <div className="prof">
                    <p>L</p>
                  </div>
                  <div className="messArea">
                    <p id="sdate">Marine • 8:41 am</p>
                    <div className="textM">Hi! How can I help you today?</div>
                  </div>
                </div>
                <div className="message mMess">
                  <div className="messArea">
                    <p id="sdate">Lorem • 8:42 am</p>
                    <div className="textM">
                      Just a quick question, when is my model arriving?{" "}
                    </div>
                  </div>
                  <div className="prof" style={{ backgroundColor: "#ddb06c" }}>
                    <p>J</p>
                  </div>
                </div>
                <div className="message">
                  <div className="prof">
                    <p>L</p>
                  </div>
                  <div className="messArea">
                    <p id="sdate">Marine • 8:42 am</p>
                    <div className="textM">
                      I’ll just check that for you, one moment. 😊
                    </div>
                  </div>
                </div>
              </div>
              <div className="chat_footer"></div>
            </div>
          </div>
        </>
      )}
      <Modal
        show={needHelpShow}
        onHide={handleNeedHelpClose}
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        className="need_help_popup"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src={chatIcon}
              title="Chat"
              alt=""
              className="need_help_left_img"
            />
            Need help?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="needhelp_ppup_bdy">
          <div className="chat-cont-p">
            <p className="start_cht_btm-txt">
              Start a secured chat with one of our team members here at Akunah.
              <br />
              <span id="chatlnkspan">
                <span className="chatlnk" onClick={handleChatPopup}>
                  Click here
                </span>{" "}
                to start a chat with one of our engineers. The chat window will
                open in a new tab. <br />
                Your unique password: <span id="chatlnkPass"></span>
              </span>
            </p>
          </div>
        </Modal.Body>
      </Modal> */}

      <Modal
        show={editProfileShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        className="dr_edit_pf_popup"
      >
        {/* <form onSubmit={handleSubmit}> */}
        <Modal.Header closeButton>
          <Modal.Title>{translation.Edit}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="edit_ppup_bdy">
          <div className="pf_details">
            <label className="user-letter large">
              {selectedImage && (
                <div>
                  <img src={s3UrlLink(selectedImage)} alt="" />
                </div>
              )}

              {selectedImage === "" && (
                <div>
                  {login.ing_firstname.slice(0, 1)}
                  {login.ing_lastname.slice(0, 1)}
                </div>
              )}
            </label>
            <input
              type="file"
              name="profileImage"
              id="profileImage"
              hidden
              style={{ display: "none" }}
              onChange={(event) => {
                setSelectedImage(event.target.files[0]);
              }}
              accept=".jpg,.jpeg,.png"
            />
            <label
              for="profileImage"
              className="btn_outline upload_pf_btn c_pointer"
              onClick={""}
            >
              {translation.uploadimg}
            </label>
            <p className="remove_pf_txt c_pointer" onClick={() => Remove()}>
              {translation.removephoto}
            </p>
          </div>
          <div className="pf_form">
            <div>
              <InputField
                label="First name:"
                value={firstName}
                ref={firstNameRef}
                type="text"
                placeholder="First Name"
                onChange={handleFirstName}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                  {
                    check: Validators.spacialName,
                    message: "No special characters are allowed",
                  },
                ]}
                styleClass="mxw_100 br_10"
              />
            </div>
            <div>
              <InputField
                label="Last name:"
                value={lastName}
                ref={lastNameRef}
                type="text"
                placeholder="Last Name"
                onChange={handleLastName}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                  {
                    check: Validators.spacialName,
                    message: "No special characters are allowed",
                  },
                ]}
                styleClass="mxw_100 br_10"
              />
            </div>
            <div>
              <Dropdown
                data={country}
                label="Country Code*"
                styleClass="mxw_100 br_10"
                value={countryCode}
                ref={countryCodeRef}
                placeholder="Country Code *"
                onChange={handleCountryCode}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
              />
            </div>
            <div>
              <InputField
                label="Phone:"
                value={phone}
                ref={phoneRef}
                type="text"
                placeholder="Phone"
                max="10"
                onChange={handlePhone}
                validators={[
                  {
                    check: Validators.number,
                    message: "Only Numbers are allowed",
                  },
                  {
                    check: Validators.maxmobile,
                    message: "Maximum 10 Numbers are allowed",
                  },
                  // {
                  //   check: Validators.minmobile,
                  //   message: "Maximum 10 Numbers are allowed",
                  // },
                ]}
                styleClass="mxw_100 br_10"
              />
            </div>
            <div className="position-relative">
              <InputField
                label="Provider Number"
                value={provideNumber}
                type="text"
                placeholder="Provider Number"
                onChange={handleProvide}
                ref={provideNumberRef}
                styleClass="br_10 mxw_100"
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                  {
                    check: Validators.spacial,
                    message: "Fields only contain Alphanumeric",
                  },
                ]}
              />
              <img
                className="c_pointer ms-2  icn_set"
                src={icon_info}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="doctors/surgeon country specific registration number"
                alt="icon"
              />
            </div>
            <div>
              <InputField
                label="Email:"
                value={email}
                type="text"
                placeholder="Email"
                onChange={handleEmail}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
                disabled
                styleClass="mxw_100 br_10"
              />
            </div>
            <div>
              <Dropdown
                data={data1}
                label="Time Zone:"
                value={timeZones}
                ref={timeZoneRef}
                placeholder="Select Timezone"
                onChange={handleTimeZone}
                validators={[
                  {
                    check: Validators.required,

                    message: "This field is required",
                  },
                ]}
                styleClass="mxw_100 br_10"
              />
            </div>
            <div>
              <p
                className="text-end c_pointer"
                style={{ color: "#3f2783" }}
                onClick={() => setModalOpenSmall(true)}
              >
                {" "}
                <b>Reset Password </b>
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "space-between" }}>
          <Button
            value="Delete Account"
            buttonStyle="btn_danger btn btn-danger w-auto mr_20"
            onClick={() => handleDeletePop()}
          />
          <div>
            <Button
              onClick={handleClose}
              value="Cancel"
              buttonStyle="btn_outline me-1"
              width="154px"
            />
            <Button
              value="Save Changes"
              buttonStyle="btn_fill"
              width="154px"
              onClick={handleSave}
            />
          </div>

          {/* <button type="submit"> save</button> */}
        </Modal.Footer>
        {/* </form> */}
      </Modal>
      <Modal
        show={modalOpenSmall}
        onHide={closeModalSmall}
        dialogClassName="modal-dialog modal-dialog-centered "
      >
        <Modal.Header closeButton>
          <Modal.Title>{"Reset Password"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="row">
            <div className="col-12">
              <label>
                We are logging out. Please reset your password from the login
                page
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            onClick={closeModalSmall}
            value="Cancel"
            buttonStyle="btn_outline mr_20"
            width="154px"
          />
          <Button
            value="Logout"
            buttonStyle="btn_outline mr_20"
            width="154px"
            onClick={handleLogout}
          />
          {/* <button type="submit"> save</button> */}
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalOpenSmallDelete}
        onHide={closeModalSmallDelete}
        dialogClassName="modal-dialog modal-dialog-centered "
        className={`delete_modal_main`}
      >
        <Modal.Body className="">
          <div className="text-center">
            <img src={DeleteIcon} className="delete_pp_icon" alt="icon" />

            <h4 className="center_title">
              Are you sure you want to delete your account ?
            </h4>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            onClick={closeModalSmallDelete}
            value="Cancel"
            buttonStyle="btn_outline mr_20"
            width="154px"
          />
          <Button
            value="Delete"
            buttonStyle="btn_danger btn btn-danger mr_20"
            width="154px"
            onClick={handleDelte}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TopHeader;
