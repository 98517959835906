import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTable } from "react-table";
import ScheuleAction from "./ScheuleActions";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
export default function SchedulerTable({ updatePagination, data, datas }) {

  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const { akid } = useParams();
  const { akId } = useParams();
  const [doctorID] = useState(login.ing_ak_id);
  const columns = React.useMemo(
    () => [
    
      {
        Header: "Title",
        accessor: "title",
      },

      {
        Header: "Specialty",
        accessor: "speciality",
        Cell: (row) => {
          if (row.value === "4") {
            return <span style={{}}>{"Shoulder"}</span>;
          } 
          if (row.value === "6") {
            return <span style={{}}>{"Hip"}</span>;
          }
          if (row.value === "21") {
            return <span style={{}}>{"Foot and Ankle"}</span>;
          }
          if (row.value === "22") {
            return <span style={{}}>{"Elbow"}</span>;
          }
          if (row.value === "5") {
            return <span style={{}}>{"Knee"}</span>;
          }
          if (row.value === "23") {
            return <span style={{}}>{"Hand and Wrist"}</span>;
          }
          else {
            return <span style={{}}>{"General"}</span>;          
          }
        },
      
      },

      {
        Header: "Organization",
        accessor: "organization",
      },
      {
        Header: "Action",
        headerClassName: "col-action",
        accessor: "action",
        className: "col-action",
        Cell: (row) => (
          <span>
          
            <ScheuleAction
              // specility={row.row.original.specility}
              title1={row.row.original.title}
              resource_id={row.row.original.resource_id}           
              viewAction="true"
              editAction="true"
              feedbackAction="false"
              deleteAction="true"
              arrowAction="true"
              rowName={doctorID}
              rowId={row.row.ea_akpt_id}
              // rowData={actionData}
              Status={row.row.original.status}
              rowType="patients"          
              patientId={akId === undefined ? akid : akId}
              notetitle={row.row.original.ea_name}
              rowOrgId={row.row.original.ea_akpt_id}
              orgId={row.row.original.ea_org_id}
            />
          </span>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      
    });

  return (
    <div className="tableContainer dasbord_height" id="rpScrollableDiv">
      <InfiniteScroll
        dataLength={rows.length}
        next={updatePagination}
        hasMore={true}
        scrollableTarget="rpScrollableDiv"
        // loader={<TableSpinner />}
      >
        <table {...getTableProps()} className="custom-tbl">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      { className: column.headerClassName },
                    ])}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps([
                          { className: cell.column.className },
                        ])}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
}
