import React, { useState } from "react";
import settingsStyle from "../Settings.module.css";
import Checkbox from "../../custom/checkbox/Checkbox";
import useTranslation from "../../customHooks/translations";
function Notifications() {
  const [sendEmail, setSendEmail] = useState("");
  const [muteNotification, setMuteNotification] = useState("");
  const [browseNotification, setBrowseNotification] = useState("");
  const translation = useTranslation()

  const handleSendmail = (event) => {
    setSendEmail(event);
  };
  const handleNotification = (event) => {
    setMuteNotification(event);
  };
  const handleBrowseNotification = (event) => {
    setBrowseNotification(event);
  };
  return (
    <div className="">
      <h4 class={`${settingsStyle.content_main_title}`}> {translation.notifications}</h4>
      <div className="mb_15" >
        <Checkbox
          label="Send email for all notifications"
          selected={sendEmail}
          onChange={handleSendmail}
          styleClass="custom_check_box tick"
          id="send_email"
        />
      </div>
      <hr />
      <div className="mb_15" >
        <Checkbox
          label="Mute notifications"
          selected={muteNotification}
          onChange={handleNotification}
          styleClass="custom_check_box tick"
          id="smute_notification"
        />
      </div>
      <div className="mb_15" >
        <Checkbox
          label="Browser notifications"
          selected={browseNotification}
          onChange={handleBrowseNotification}
          styleClass="custom_check_box tick"
          id="browse_notificaion"
        />
      </div>

    </div>
  );
}

export default Notifications;
