import React from "react";
import "./TableSpinner.css";
function TableSpinner() {

  return (
    <>
      <div class="spinner-border spin" role="status" style={{ color: "#3f2783", display:'flex',}}>
        <span class="visually-hidden">Loading...</span>
      </div>
    </>
  );
}

export default TableSpinner;
