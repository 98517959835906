import React, { useState, useEffect } from "react";
import settingsStyle from "../Settings.module.css";
import { useSelector } from "react-redux";
import Message from "../../custom/toster/Message";
import useAxios from "../../../axiosinstance";
import Dropdown from "../../custom/dropdown/Dropdown";
export default function DefaultTem() {
    const axiosInstance = useAxios();
    const login = useSelector((state) => state.mainReducer.loginData.arr);
    // const [firstName, setFirstName] = useState(login.ing_firstname);
    // const [lastName, setLastName] = useState(login.ing_lastname);
    // const [doctor, setDoctor] = useState(login.ing_ak_id);
    const [message, setMessage] = useState(false);
    const [messageType, setMessageType] = useState();
    // const firstNameRef = useRef();
    // const lastNameRef = useRef();
    // const translation = useTranslation();
  
    // const handleSubmit = () => {
    //   // if (!firstName) {
    //   //   firstNameRef.current.errUpdate();
    //   // }
    //   // if (!lastName) {
    //   //   lastNameRef.current.errUpdate();
    //   // }
    //   // if (!firstName || !lastName) {
    //   //       setMessage("Field Required");
    //   //       setMessageType("warning");
    //   // } else {
    //   //   const json = JSON.stringify({
    //   //     firstname: firstName,
    //   //     lastname: lastName,
    //   //   });
    //   //   axiosInstance.current
    //   //     .put(`extapp/doctors/doctor_update_profile?ak_id=${doctor}`, json, {
    //   //       headers: {
    //   //         "Content-Type": "application/json",
    //   //       },
    //   //     })
    //   //     .then((res) => {
    //   //       setMessage(res.data.data.message);
    //   //       setMessageType("success");
    //   //     })
    //   //     .catch((err) => {
    //   //       setMessage(err.message);
    //   //       setMessageType("error");
    //   //     });
    //   // }
    // };
  
    // const handleOnChange = async (index, id) => {
    //   setMessage(false);
    // };
  
    const [form, setItems2] = useState([]);
    const [formType, setformType] = useState("2");
    const [scoredata, setScoreData] = useState([]);
    const [status, setStatus] = useState("");
    const getForm = async () => {
      try {
        const response = await axiosInstance.current.put(
          `/extapp/forms/insertTemplateDefault?ak_dc=${login.ing_ak_id}&type=9&auto_id=${formType}`
        );
        if (response) {
       
          setMessage("Treatment note uppdate");
          setMessageType("success");
        } 
        setTimeout(() => {
          setMessage(false);
        }, 2000);
      }catch(err){console.log(err);}}
      
      useEffect(()=>{
        if(status){
          getForm()
        }
      },[status])
    const handleFormType = async(e) => {
      setformType(e);
      setStatus(e)
    
    
    };
    useEffect(() => {
  
      const getForm = async () => {
        try {
          const response = await axiosInstance.current.get(
            `/extapp/forms/getDefaultTemplateTrue?ak_dc=${login.ing_ak_id}&type=9`
          );
          setformType(response.data.data[0].auto_id);
        } catch (error) {
          setformType("2")
        }
  
      };
      getForm();
    }, []);
  
    console.log(scoredata, ".................");
  
    useEffect(() => {
      const getForm = async () => {
        const response = await axiosInstance.current.get(
          `/extapp/forms/getDoctorTemplateType?ak_dc=${login.ing_ak_id}&type=9`
    
        );
        setItems2(response.data.data);
      };
      getForm();
    }, [login]);
  
    const options = form.map((item) => {
      return { value: item.auto_id, label: item.title };
    });
    options.unshift({value:"2",label:"Akunah default"});

    return (
      <div className="">
        <h4 class={`${settingsStyle.content_main_title}`}>{"Treatment note default template"}</h4>
  
        {message && <Message message={message} type={messageType} />}
        <div className="row">
          <div className="col-sm-6">
            <Dropdown
              data={options}
              label=""
              value={formType}
              onChange={(e) => handleFormType(e)}
              styleClass="br_10 mxw_100 mb_20"
              placeholder="Select "
            />
          </div>
        </div>
  
        {/* <div className="row mb_15">
          {
            // scoredata.length === 0 ? ( <div className="no_data">No data Found</div>) : (
              scoredata.map((ele) => {
                return (
                  <div className="col-md-4">
                    <div className="form-group custom_check_box tick">
                      <input
                        className="mr-2"
                        type="checkbox"
                        checked={ele.default}
                        name={ele.title}
                        value={ele.default ? 0 : 1}
                        onClick={(e) =>
                          handleOnChange(ele.title, ele.auto_id)
                        }
                        defaultValue=""
                        id={ele.type_id}
                        defaultChecked=""
                      />
                      <label className="form-check-label" For={ele.type_id}>
                        {ele.title}
                      </label>
                    </div>
                  </div>
                );
              })
            // )
          }
        </div>
   */}
  
        {/* <Button
          onClick={handleSubmit}
          value="Edit Profile"
          buttonStyle="btn_outline"
          width="140px"
        /> */}
      </div>
    )
}
