import React, { useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import ReactDOMServer from "react-dom/server";

import Html from "react-pdf-html";

export default function PostOppdf({ Personaldata, item, login }) {
  const styles = StyleSheet.create({
    body: {
      padding: 10,
      backgroundColor: "#f3f5f7",
      fontSize: "1px",
      paddingTop: 10,
      paddingBottom: 150,
      paddingHorizontal: 35,
      fontWeight: 700,
      fontFamily: "Roboto",
      flexDirection: "column",
    },
    footerFixed: {
      position: "absolute",
      fontSize: 12,
      // paddingHorizontal: 15,
      bottom: 5,
      left: 0,
      right: 0,
      marginTop: 5,
      height: 90,
    },
    pageNumber: {
      position: "absolute",
      fontSize: 10,
      fontWeight: 700,
      fontFamily: "Roboto",
      bottom: 10,
      left: 0,
      right: 20,
      textAlign: "right",
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },
    row: {
      // backgroundColor:"red",
      flexDirection: "row",
    },
    head_row: {
      flexDirection: "row",
      display: "flex",
    },
    image: {
      marginTop: 5,
      width: 100,
    },
    txt1: {
      // lineHeight:"10px",
    },
    title_header: {
      paddingTop: 10,
      fontSize: 12,
      fontWeight: 700,
    },
    titles: {
      textAlign: "center",
      fontSize: 16,
      margin: 0,
      paddingTop: 5,
      marginBottom: 5,
      marginTop: 5,
    },
    header_title: {
      textAlign: "right",
      fontSize: 12,
      fontWeight: 400,
      marginTop: 5,
      marginLeft: "auto",
      fontFamily: "Roboto",
    },
    header_para: {
      textAlign: "right",
      fontSize: 9,
      fontWeight: 400,
      marginLeft: "auto",
      padding: 0,
      fontFamily: "Roboto",
    },
    footer_para: {
      position: "absolute",
      left: 20,
      top: "18",
      fontSize: 11,
      fontWeight: 400,
      padding: 0,
      fontFamily: "Roboto",
    },
    footer_para_mid: {
      textAlign: "center",
      fontSize: 11,
      fontWeight: 400,
      marginTop: 18,
      padding: 0,
      fontFamily: "Roboto",
    },
    footer_para_last: {
      position: "absolute",
      right: "80",
      top: "18",
      fontSize: 11,
      fontWeight: 400,
      padding: 0,
      fontFamily: "Roboto",
    },
    panelbox: {
      WebkitPrintColorAdjust: "exact",
      backgroundColor: "#fff",
      borderRadius: 20,
      padding: 8,
      paddingBottom: 8,
      marginBottom: 3,
      marginTop: 9,
    },
    panelheading: {
      fontSize: 10,
      fontWeight: "bold",
      marginBottom: 7,
      marginTop: 7,
      marginLeft: 3,
    },
    panelbdy_txt: {
      fontSize: 10,
      marginBottom: 4,
      marginTop: 4,
      fontWeight: "400",
      wordWrap: "break-word",
      wordBreak: "keep-all",
      textTransform: "capitalize",
      marginLeft: 3,
    },
    breakBefore: {
      pageBreakBefore: "always",
    },
    hr: {
      margin: "20px -10px",
      marginTop: 0,
      marginBottom: 2,
      background: "#e5e5e5",
      color: "#e5e5e5",
      border: "0px solid #e5e5e5",
      height: 1,
    },
    p: {
      margin: 0,
      padding: 0,
      fontFamily: "Roboto",
    },
    b: {
      fontFamily: "Roboto",
      fontWeight: "bold",
    },
    strong: {
      fontFamily: "Roboto",
      fontWeight: "bold",
    },

    page: {
      flexDirection: "column",
      backgroundColor: "#E4E4E4",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    text: {
      color: "red",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
  });
  const stylesheet = {
    p: {
      marginBottom: 5,
      fontSize: 12,
      fontFamily: "Roboto",
      fontWeight: "light",
    },
    b: {
      fontFamily: "Roboto",
      fontWeight: "bold",
    },

    ".cont": {
      color: "#000",
    },
  };
  // const footerStyles = {
  //   h5: {
  //     margin: 0,
  //     padding: 0,
  //   },
  //   h6: {
  //     margin: 0,
  //     padding: 0,
  //   },
  //   p: {
  //     margin: 0,
  //     padding: 0,
  //   },
  // };

  Font.register({
    family: "Roboto",
    fonts: [
      {
        src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
        fontWeight: "light",
      },
      {
        src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf",
        fontWeight: "regular",
      },
      {
        src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
        fontWeight: "medium",
      },
      {
        src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf",
        fontWeight: "bold",
      },
    ],
  });

  const PostNotes = (
    <div className="page" style={{ pageBreakAfter: "always", marginBottom: 0 }}>
      <div className="panel box" style={styles.panelbox}>
        <div className="panel-heading">
          <div style={styles.panelheading}>Post Treatment Notes</div>
        </div>
        <div style={styles.hr} />

        <div className="panel-body" style={{}}>
          <table
            className=""
            width="100%"
            border={0}
            cellPadding={6}
            cellSpacing={0}
          >
            <tbody>
              <>
                <tr>
                  <td style={styles.panelbdy_txt}>
                    <div
                      style={{
                        width: "",
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        margin: 5,
                      }}
                    >
                      <strong>Operative Notes: </strong>
                      <pre className="p_description1">
                        {item.operative_note}
                      </pre>
                    </div>
                  </td>
                </tr>
                <div style={styles.hr} />
                <tr>
                  <td style={styles.panelbdy_txt}>
                    <div
                      style={{
                        width: "",
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        margin: 5,
                      }}
                    >
                      <strong> Implant Information: </strong>
                      <pre className="p_description1">{item.prostheses}</pre>
                    </div>
                  </td>
                </tr>
                <div style={styles.hr} />
                <tr>
                  <td style={styles.panelbdy_txt}>
                    <div
                      style={{
                        width: "",
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        margin: 5,
                      }}
                    >
                      <strong>Post Treatment Instructions: </strong>
                      <pre className="p_description1">{item.instructions}</pre>
                    </div>
                  </td>
                </tr>
              </>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
  const header1 = (
    <div className="page" style={{ pageBreakAfter: "always", marginBottom: 0 }}>
      <div style={styles.head_row}>
        <div style={styles.image}>
          {/* <img
                src={
                  headerimage
                }
              /> */}
        </div>
        <div
          style={{
            textAlign: "right",
            fontSize: 10,
            fontWeight: 400,
            marginTop: 5,
            marginLeft: "auto",
            fontFamily: "Roboto",
          }}
        >
          {Personaldata?.ig_fname} {Personaldata?.ig_lname}
          <br />
          {new Date(Personaldata?.ig_dob).toLocaleDateString()}
          <br />
          {Personaldata?.ig_gender === "1"
            ? "Male"
            : Personaldata?.ig_gender === "2"
            ? "Female"
            : "Do not want to disclose"}
          <br />
          {Personaldata?.ig_phone}
          <br />
          {Personaldata?.ig_medicare_no}
        </div>
      </div>

      <div className="panel-heading">
        <div style={styles.titles}>Treatment Note Results</div>
      </div>
    </div>
  );

  const footer1 = (
    <div className="page" style={{ pageBreakAfter: "always", marginBottom: 0 }}>
      <div className="panel-heading">
        <div style={styles.footer_para}>
          {login?.treatAdd.address} <br />
          {login?.treatAdd.suburb}
          <br />
          {login?.treatAdd.postCode} <br />
          {/* Private Hospital, QLD, 4120 Australia */}
        </div>
        <div style={styles.footer_para_mid}>
          {login?.official_email}
          <br />
          P: {login?.country_code} {login?.ing_mobile}
          <br />
          {/* F: +61 7 3445 1592 */}
        </div>
        <div style={styles.footer_para_last}>
          <img
            style={{ width: "50px" }}
            src={
              "https://images.squarespace-cdn.com/content/v1/600a2734b232b75f58bb48f9/1611709064100-FZNI09YCZABNS97CXS1T/Logos-concepts-Updates-2-02.png?format=1500w"
            }
          />
        </div>
      </div>
    </div>
  );
  const PostNoteshtml = ReactDOMServer.renderToStaticMarkup(PostNotes);
  const header11 = ReactDOMServer.renderToStaticMarkup(header1);
  const footer11 = ReactDOMServer.renderToStaticMarkup(footer1);
  return (
    <Page style={styles.body}>
      <View fixed>
        <Html>{header11}</Html>
      </View>
      <View style={{ marginTop: 10 }}>
        <Html stylesheet={stylesheet}>{PostNoteshtml}</Html>
      </View>

      <View style={styles.footerFixed} fixed>
        {/* <Html style={styles.title}>{text1}</Html> */}
        <Html>{footer11}</Html>
      </View>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) =>
          `Page  ${pageNumber} of ${totalPages}`
        }
        fixed
      />
    </Page>
  );
}
