import React, { useState, useEffect, useRef, useMemo } from "react";
import settingsStyle from "../Settings.module.css";
import InputField from "../../custom/inputfield/InputField";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
// import nextWhiteIcon from "../../images/next-white.png";
import Button from "../../custom/button/Button";
// import FileUpload from "./File-upload/FileUpload";
import Message from "../../custom/toster/Message";
import OrgListTable from "./OrgListTable";
import { Validators } from "../../../utilities/Validator";
import useAxios from "../../../axiosinstance";
import { useSelector } from "react-redux";
import useTranslation from "../../customHooks/translations";
// import Checkbox from "../../custom/checkbox/Checkbox";
// import editIcon from "../../../images/edit-icon.svg";
import Modal from "react-bootstrap/Modal";
import FileUpload from "./FileUpload";
// import FileUpload1 from "./FileUpload1";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import OrgListTableOrg from "./OrgListTableOrg";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import GoogleAddress from "../../custom/google-api-address/GoogleAddress";
function MyClinics() {
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const axiosInstance = useAxios();
  // const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [showOrganization, setShowOrganization] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [streetAdd, setStreetAdd] = useState("");
  const [city, setCity] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [postCode, setPostCode] = useState("");
  const [postCodeErr, setPostCodeErr] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const [editEnable, seteditEnable] = useState(false);
  const [editId, seteditId] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [iddata, setIddata] = useState("");
  const [key, setKey] = useState("allcases");
  const [keyID, setKeyID] = useState(1);
  const [blockID, setblockID] = useState("");
  const [addressError, setaddressError] = useState(false);
  const [approved, setApproved] = useState([]);
  const orgNameRef = useRef();
  const streetAddRef = useRef();
  const cityRef = useRef();
  const stateValueRef = useRef();
  const postCodeRef = useRef();
  const phoneRef = useRef();
  const translation = useTranslation();
  const [show, setShow] = useState(false);
  const [listorg, setListOrg] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState({});
  const [mailbutton, setMailButton] = useState(false);
  const [type, setType] = useState("1");
  const [orgId, setOrgId] = useState("");
  const currentUrl = window.location.hostname;
  const [itemss, setItemss] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
  const [orgAddress, setOrgAddress] = useState([]);
  const [speChange, setSpeChange] = useState([]);
  const changeOrgName = (event) => {
    setOrgName(event);
  };
  const changeStreetAdd = (event) => {
    setStreetAdd(event);
    setaddressError(false);
  };

  // const handleGpAddress = (e) => {
  //   setGpAddress(e);
  //   setaddressError(false);
  // };
  const changeCity = (event) => {
    setCity(event);
  };
  const changeStateValue = (event) => {
    setStateValue(event);
  };
  const changePostCode = (event, err) => {
    setPostCode(event);
    setPostCodeErr(err);
  };
  const changePhone = (event, err) => {
    setPhone(event);
    setPhoneErr(err);
  };
  // const fileData = (e) => {
  //   // console.log(e);
  //   setfileError(e);
  // };
  // const [selectedOption, setSelectedOption] = useState(null);
  const [checkedItems, setCheckedItems] = useState({});
  // console.log(checkedItems, "checkedItems");
  // const handleChange = (selectedOption) => {
  //   setSelectedOption(selectedOption);
  // };
  const json = JSON.stringify({
    org_name: inputValue,
    address: streetAdd,
    phone_no: phone,
    postal_code: postCode,
    city: city,
    state: stateValue,
    approved_status: 0,
    resource_type: [checkedItems],
  });

  const [uploadCheck, setUploadCheck] = useState(false);
  const [uploadData, setUploadData] = useState([]);
  const [uploadDataAutoId, setUploadDataAutoId] = useState("");

  

  const handleSubmit = (e) => {
    e.preventDefault();
    const numberOfCheckedItems =
      Object.values(checkedItems).filter(Boolean).length;
    if (numberOfCheckedItems === 0) {
      setErrorMessage("Please select at least one option");
      return;
      // alert(44)
      // handleEditSave();
    } else {
      setErrorMessage("");
    
    }
    if (!inputValue) {
      // console.log("orgName", orgName);
      orgNameRef.current.errUpdate();
    }
    // if (!streetAdd) {
    //   setaddressError(true);
    // }
    // if (!city) {
    //   cityRef.current.errUpdate();
    // }
    // if (!stateValue) {
    //   stateValueRef.current.errUpdate();
    // }
    // if (!postCode) {
    //   postCodeRef.current.errUpdate();
    // }
    // if (!phone) {
    //   phoneRef.current.errUpdate();
    // }
    if (!inputValue) {
      // alert(1);
    } else {
      // alert(3);
      if (type !== "1") 
      handleSave();
    }
  };



  const handleSave = async () => {
    try {
      const result = await axiosInstance.current.post(
        `extapp/doctors/create_Organization_by_doctor?ak_id=${login.ing_ak_id}&type=${type}&akou_id=${orgId}&uri=${currentUrl}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 201) {
        if (type === "1") {
          setMessageType("success");
          setMessage("Organization created successfully");
        } else {
          setMessageType("success");
          // setMessage("Join Organization successfully");
          setMessage("Request to join organization sent");
        }
        setUploadData(result.data.data.arr);
        setUploadDataAutoId(result.data.data.auto_id);

        setOrgName("");
        setStreetAdd("");
        setCity("");
        setStateValue("");
        setPostCode("");
        setPhone("");
        setCheckedItems({});
        setUploadCheck(true);
        setShowOrganization(false);
        setOffset(10);
        // setTimeout(() => {
        //   setMessage(false);
        // }, 3000);
      }
    } catch (err) {
      setMessage(err.response.data.data);
      setMessageType("error");
      setTimeout(() => {
        setMessage(false);
      }, 3000);
    } finally {
      console.log("api response finally");
    }
  };
  const DataIdget = (e, i, org_id) => {
    setIddata(e);
    setblockID(i);
    setOrgId(org_id);
  };
  const [offset, setOffset] = useState(25);
  const [limit] = useState(25);
  const [show1, setshow1] = useState(false);
  const [refs, setRefs] = useState(false);
  useEffect(() => {
    // setshow1(false);
    const getData = async () => {
      const response = await axiosInstance.current.get(
        `extapp/doctors/list?doctor_id=${login.ing_ak_id}&limit=${limit}&offset=0&aprvl_type=${keyID}`
      );
      if (response.status === 200) {
        setshow1(true);
      }
      setItems(response.data.data);
    };
    getData();
  }, [showOrganization, keyID, uploadCheck, refs]);

  const addMoreData = async () => {
    setOffset(offset + 25);
    const response = await axiosInstance.current.get(
      `extapp/doctors/list?doctor_id=${login.ing_ak_id}&limit=${limit}&offset=${offset}&aprvl_type=${keyID}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setItems(items.concat(resdata));
    }, 500);
  };
  const fetchMoreData = () => {
    addMoreData();
  };
  const [form, setItems2] = useState([]);
  const [enable, setEnable] = useState(false);

  const getForm = async (e) => {
    setEnable(false);
    const response = await axiosInstance.current.get(
      `extapp/doctors/get_checked_organization?id=${e}&ak_dc=${login.ing_ak_id}&akou_id=${orgId}`
    );
    if (response.status === 200) {
      setItems2(response.data.data);
      setEnable(true);
    }

    // response.data.data.data.map((ite) => {
    //   return setCheckedItems({
    //     ...checkedItems,
    //     [ite.value]: ite.locked,
    //   });
    // });

    // if (response.status === 200) {
    // }
  };

  useMemo(() => {
    let e = "";
    if (orgId.trim()) {
      getForm(e);
    }
  }, [orgId]);

  const Location = async () => {
    const response = await axiosInstance.current.get(
      `/extapp/doctors/organization_details?akou_id=${orgId}&ak_id=${login.ing_ak_id}`
    );
    if (response.status) {
      setOrgAddress(response.data.data.org_address);
      setIddata(response.data.auto_id);
    }
  };

  useEffect(() => {
    if (orgId) {
      Location();
    }
  }, [orgId]);

  let a =
    form !== undefined && form?.length !== 0
      ? form?.filter((items) => items.value !== "24")
      : [];

  const options = a.map((item) => {
    return {
      id: item.value,
      label: item.label,
      value: item.value,
      locked: item.locked,
      active: item.active,
    };
  });
  // console.log(options)
  const handleChange1 = (e) => {
    setCheckedItems({
      ...checkedItems,
      [e.target.value]: e.target.checked,
    });
  };
  const handleEdit = (e) => {
    if (orgId) {
      getForm(e);
    }
    seteditId(e);
    axiosInstance.current
      .get(`extapp/doctors/get_particular_org?id=${e}`)
      .then((res) => {
        if (res.status === 200) {
          seteditEnable(true);
          setShowOrganization(false);
          setOrgName(res.data.data.data.org_name);
          setStreetAdd(res.data.data.data.address);
          setCity(res.data.data.data.city);
          setStateValue(res.data.data.data.state);
          setPostCode(res.data.data.data.postal_code);
          setPhone(res.data.data.data.phone_no);
        }
      });
  };
  useEffect(() => {
    if (iddata !== "") {
      handleEdit(iddata);
    }
  }, [iddata]);
  // console.log(checkedItems);
  const handleShow = () => {
    setOrgName("");
    setStreetAdd("");
    setCity("");
    setStateValue("");
    setPostCode("");
    setPhone("");
    setCheckedItems({});
    setShowOrganization(true);
    seteditEnable(false);
  };


  const handleSubmitEdit = (e) => {
    e.preventDefault();
  
    const lockedTrueCount = form.reduce((count, form) => {
      return form.locked ? count + 1 : count;
    }, 0);
  
    const countFalse = Object.values(checkedItems).filter(
      (val) => val === false
    ).length;
  
    const hasTrueValue = Object.values(checkedItems).includes(true);
  
    if (Object.keys(checkedItems).length === 0) {
      setErrorMessage("Please select at least one option");
      // handleEditSave();
    } 
    else {
      if (hasTrueValue) {
        setErrorMessage(""); // Clear the error message
        handleEditSave();
      } else {
        if (lockedTrueCount <= countFalse) {
          setErrorMessage("Please select at least one option");
        } else {
          setErrorMessage("Please select at least one option"); // Set the error message here
          handleEditSave();
        }
      }
    }
  };
  const handleEditSave = async () => {
    try {
      const result = await axiosInstance.current.put(
        `extapp/doctors/update_se_organization?id=${editId}`,
        checkedItems,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) {
        setMessage(result.data.message);
        setMessageType("success");
        setOrgName("");
        setStreetAdd("");
        setCity("");
        setStateValue("");
        setPostCode("");
        setPhone("");
        setCheckedItems({});
        setShowOrganization(false);
        seteditEnable(false);
        setUploadData(result.data.data.arr);
        setUploadDataAutoId(result.data.data.auto_id);
        setUploadCheck(true);
        setOffset(25);
        setApproved(result.data.data.approved);
      }
    } catch (err) {
      setMessage(err.message);
      setMessageType("error");
    } finally {
      console.log("api response finally");
    }
  };

  // setTimeout(() => {
  //   setMessage(false)
  // }, 3000);

  // const handleSubmitEdit = (e) => {
  //   e.preventDefault();

  //   // console.log(form);
  //   // console.log(checkedItems , "check")
  //   // // console.log(result , "result")
  //   // const myState = {4: false, 5: false, 6: true};

  //   const lockedTrueCount = form.reduce((count, form) => {
  //     return form.locked ? count + 1 : count;
  //   }, 0);

  //   const countFalse = Object.values(checkedItems).filter(
  //     (val) => val === false
  //   ).length;
  //   // console.log(lockedTrueCount);

  //   const hasTrueValue = Object.values(checkedItems).includes(true);
  //   if (Object.keys(checkedItems).length === 0) {
  //     // alert("save");
  //     setErrorMessage(true);
  //     handleEditSave();
  //   } else {
  //     if (hasTrueValue) {
  //       // alert("save");
  //       setErrorMessage(false);
  //       handleEditSave();
  //     } else {
  //       if (lockedTrueCount <= countFalse) {
  //         setErrorMessage("Please select at least one option");
  //       } else {
  //         setErrorMessage(true);
  //         handleEditSave();
  //       }
  //     }
  //   }
  //   // const numberOfCheckedItems =
  //   //   Object.values(checkedItems).filter(Boolean).length;

  //   // if (numberOfCheckedItems === 0) {
  //   //   setErrorMessage("Please select at least one option");
  //   // } else {
  //   //   alert(1);
  //   //   // setErrorMessage("");
  //   //   // handleEditSave();
  //   // }
  // };

  
  
  

  const handleCloseShow = () => {
    setShow(false);
  };
  // const handleUpload = () => {
  //   setShow(true);
  // };
  // console.log(uploadData);
  // console.log(uploadDataAutoId);
  const closeAllTab = () => {
    setShowOrganization(false);
    seteditEnable(false);
    setUploadCheck(false);
    setOrgName("");
    setStreetAdd("");
    setCity("");
    setStateValue("");
    setPostCode("");
    setPhone("");
    setCheckedItems({});
    setIddata("");
    setType("1");
  };

  const handleSelect = (key) => {
    if (key === "allcases") {
      setshow1(false);
      setKey(key);
      setOffset(25);
      setKeyID(1);
      setRefs(!refs);
      // getTabData("");
    } else if (key === "submitted") {
      setshow1(false);
      setKey(key);
      setOffset(25);
      setKeyID(2);
      setRefs(!refs);

      // getTabData("");
    }
  };
  // console.log(blockID)

  // const handleOnSearchorg = async (string, results) => {

  //   setInputValue(string)
  //   setOrgName(string)
  //   if (string.length > 1) {
  //     console.log("calling api")
  //     setTimeout(async () => {
  //       const orgdata = await axiosInstance.current.get(`extapp/doctors/orgnamelisting?key=${string}`)
  //       setListOrg(orgdata.data.data)
  //     }, 2000);
  //   }
  //   setEnable(true)
  //   setSelected({})
  //   // setListOrg([])
  //   setCity("")
  //   setType("1")
  //   setStreetAdd("")
  //   setStateValue("")
  //   setPostCode("")
  //   setPhone("")
  //   setOrgId("")
  //   setMailButton(false)
  // };

  // const handleOnSelectorg = (item) => {
  //   if (item) {
  //     setSelected(item);
  //     setType("2")
  //     setInputValue("")
  //     setOrgName(item.lebel)
  //     setOrgId(item.id)
  //     setCity(item.city)
  //     setStreetAdAitem.org_adorgAddress)
  //     setStateValue(item.state)
  //     setPostCode(item.postCode)
  //     setPhone(item.phone)
  //     setMailButton(true)
  //     setEnable(false)
  //   }

  // };

  // const handleOnClear = () => {
  //   setEnable(true)
  //   setSelected({})
  //   setListOrg([])
  //   setCity("")
  //   setStreetAdd("")
  //   setStateValue("")
  //   setPostCode("")
  //   setPhone("")
  //   setOrgId("")
  //   setMailButton(false)
  // };

  // const handleKeyDown = (e) => {

  //   if (e.keyCode === 8 || e.key === 'Backspace') {
  //     // Check if the input field is empty
  //     setMailButton(true)
  //   }
  // };
  const [lastTypingTime, setLastTypingTime] = useState(null);
  // const [inputValuee, setInputValuee] = useState('');

  const getAllOrg = async (value) => {
    try {
      const response = await axiosInstance.current.get(
        `extapp/doctors/orgnamelisting?key=${value}&ak_id=${login.ing_ak_id}`
      );
      if (response.status === 200) {
        console.log(response.data.data[0].org_address, "org Address");
        const options = response.data.data.map((item) => {
          return {
            value: item.id,
            label: item.org_name,
            // akDc_id: item.akdc_id,
            aKou: item.lebel,
          };
        });

        setItemss(options);
        // setOrgAddress(response.data.data[0].org_address)
        setSpeChange(response.data.data[0].specility);
      }

      // setItemss(response.data.data)
    } catch (error) {
      setItems([]);
    }
  };
  console.log(speChange, "speChange");

  const handleChange = (event, newValue) => {
    // setorg(newValue === null ? "" : newValue.aKou);
    setOrgName(newValue === null ? "" : newValue.aKou);
    if (newValue.aKou) {
      setReadOnly(true);
      setOrgId(newValue.value);
      setInputValue(newValue.aKou);
      setCity(newValue.city);
      setStreetAdd(newValue.address);
      setPhone(newValue.phone);
      setStateValue(newValue.state);
      setPostCode(newValue.post);
      setMailButton(true);
      setType("2");
    } else {
      setMailButton(false);
    }
    // setDoctor(newValue === null ? "" : newValue.akDc_id);

    // setOrgErr(false);
    // setScoringForm("");
  };

  const handleOnSearch = (value, results) => {
    console.log(value, "myne");
    setLastTypingTime(new Date().getTime());
    setItemss([]);
    setInputValue(value);
    setMailButton(false);
    setReadOnly(false);
    setType("1");
    setCity("");
    setStreetAdd("");
    setStateValue("");
    setPostCode("");
    setPhone("");
    setOrgId("");
    setCheckedItems({});
    setOrgAddress([]);
  };
  console.log(orgId, "inputValueinputValue");

  useEffect(() => {
    if (lastTypingTime) {
      const timer = setTimeout(() => {
        // Make your API call here using the inputValue
        if (inputValue.length > 1 || inputValue.length === 0) {
          getAllOrg(inputValue);
        } else {
          setItems([]);
        }
      }, 2000); // Adjust the delay (in milliseconds) as needed

      return () => clearTimeout(timer); // Cleanup the timer on component unmount or when lastTypingTime changes
    }
  }, [lastTypingTime, inputValue]);

  useEffect(() => {
    if (orgName === "") {
      setType("1");
      setCity("");
      setStreetAdd("");
      setStateValue("");
      setPostCode("");
      setPhone("");
      setOrgId("");
      setReadOnly(false);
      setCheckedItems({});
      setMailButton(false);
      setItemss([]);
    }
  }, [orgName]);

 

  return (
    <div className="mr_15">
      <div className="mb_15">
        <div className="row">
          <div className="col-md-8 col-sm-8">
            <h4 class={`${settingsStyle.content_main_title}`}>
              {uploadCheck
                ? "Upload your speciality proof related documents"
                : editEnable
                ? "Edit Organization "
                : showOrganization
                ? "Join Organization"
                : translation.myClinics}
            </h4>
          </div>
          {/* <Button
            onClick={(e) => setShow(true)}
            value="upload"
            buttonStyle="btn_fill mr_15"
            width="160px"
          /> */}
          <div className="col-md-4 col-sm-4 text-end">
            {message && <Message message={message} type={messageType} />}
            {!showOrganization && !editEnable && !uploadCheck && (
              <Button
                onClick={(e) => handleShow()}
                value="Join Organization"
                buttonStyle="btn_fill mr_15"
                width="auto"
              />
            )}
          </div>
        </div>
        {showOrganization && (
          <div className="mr_15 mt_30">
            <form autoComplete="off">
              <div className="row">
                <div className="col-md-12 col-sm-12 mb_10  organization-drop">
                  <div className="dropdown_main_1">
                    <span className="white_shade"></span>

                    <Autocomplete
                      freeSolo
                      id="free-solo-2-demo"
                      // disableClearable={true}
                      options={itemss}
                      value={orgName}
                      className="w-100"
                      onChange={handleChange}
                      // disableClearable={disable}
                      renderInput={(params) => (
                        <TextField
                          placeholder={"Organization Name *"}
                          className=""
                          style={
                            {
                              // height: "52px",
                              // border: "2px solid #7867a8",
                              // borderColor: "#7867a8",
                              // borderRadius: "7px",
                              // backgroundColor: "white",
                              // boxShadow: "none",
                              // // hoverBackgroundColor: "#3f2783",
                              // hoverColor: "#fff",
                              // color: "black",
                              // fontSize: "12px",
                              // fontFamily: "Courier",
                              // iconColor: "#7867a8",
                              // // lineColor: "black",
                              // placeholderColor: "black",
                              // clearIconMargin: "3px 8px 0 0",
                            }
                          }
                          {...params}
                          // onChange={(e) => handleOnSearch(e.target.value)}
                          onKeyUp={(e) => handleOnSearch(e.target.value)}
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </div>
                  <p>
                    <span
                      className="fst-italic fw-light"
                      style={{ color: "rgb(63, 39, 131)" }}
                    >
                      Search with minimum of three characters
                    </span>
                  </p>

                  {orgId !== "" && (
                    <div className="mt-4">
                      <label>Locations </label>
                      <div className="">
                        <div className="padding-right: 39px;">
                          <div className="row">
                            {orgAddress?.map((row) => (
                              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="multiaddress">
                                  <p>{`${row.street_address}`}</p>
                                  <p>{` ${row.city_suburb}, ${row.state}, ${row.postal_code}`}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className="row">
                <div className="col-md-12 col-sm-12 mb_10">
                  <GoogleAddress
                
                    onAddressChange={changeStreetAdd}
                    valid={addressError}
                    value={streetAdd}
                    readOnly={readOnly}
                    styleClass={"mxw_100 br_10 mh_50"}
                  ></GoogleAddress>

            
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 mb_10">
                  <InputField
                    label=""
                    value={city}
                    ref={cityRef}
                    type="text"
                    placeholder="City/Subrub *"
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                    ]}
                    onChange={changeCity}
                    styleClass="mxw_100 br_10 mh_50"
                    readOnly={readOnly}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <InputField
                    label=""
                    value={stateValue}
                    ref={stateValueRef}
                    type="text"
                    placeholder="State *"
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                    ]}
                    onChange={changeStateValue}
                    styleClass="mxw_100 br_10 mh_50"
                    readOnly={readOnly}
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <InputField
                    label=""
                    value={postCode}
                    ref={postCodeRef}
                    type="text"
                    placeholder="Post Code *"
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                      {
                        check: Validators.spacial,
                        message: "Invalid character",
                      },
                      {
                        check: Validators.postcode,
                        message: "Fields only contain 10 character",
                      },
                    ]}
                    onChange={changePostCode}
                    readOnly={readOnly}
                    styleClass="mxw_100 br_10 mh_50"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 mb_10">
                  <InputField
                    label=""
                    value={phone}
                    ref={phoneRef}
                    type="text"
                    placeholder="Phone *"
                    readOnly={readOnly}
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                      {
                        check: Validators.number,
                        message: "This field can only contain numbers.",
                      },
                      {
                        check: Validators.maxmobile,
                        message: "Maximum 10 Numbers are allowed.",
                      },
                      // {
                      //   check: Validators.minmobile,
                      //   message: "Maximum 10 Numbers are allowed.",
                      // },
                    ]}
                    onChange={changePhone}
                    styleClass="mxw_100 br_10 mh_50"
                  />
                </div>
              </div> */}

              {orgId !== "" && (
                <div className="row">
                  <div className="col-12">
                    <div className="mb_15">
                      <label>{"Speciality *"} </label>

                      <div className="row">
                        {options.map((option) => (
                          <div className="col-6">
                            <div
                              className="form-group custom_check_box tick"
                              key={option.id}
                            >
                              <input
                                className="me-2"
                                type="checkbox"
                                value={option.value}
                                // checked={checkedItems[option.value]}
                                defaultChecked={option.locked}
                                disabled={option.locked}
                                onChange={handleChange1}
                                id={option.id}
                              />
                              <label className="me-4" for={option.id}>
                                {" "}
                                {option.label}
                              </label>
                            </div>
                          </div>
                        ))}
                        {/* <br /> */}
                        {/* <pre>{JSON.stringify(checkedItems, null, 2)}</pre> */}
                        
                        {errorMessage && (
                          <p style={{ color: "red" }}>{errorMessage}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-12 col-sm-12 mb_10">
                  {/* <FileUpload fileData={fileData} error1={error1} /> */}
                </div>
                <div className="">
                  <div className="col-sm-12 col-md-12">
                    <Button
                      onClick={() => closeAllTab()}
                      value="Cancel"
                      buttonStyle="btn_fill "
                      style={{ padding: "0 40px" }}
                    />

                    {!mailbutton ? (
                      <Button
                        value="Joining Request"
                        buttonStyle="next_btn btn_disabled ms-3"
                      />
                    ) : (
                      <Button
                        onClick={handleSubmit}
                        value="Joining Request"
                        buttonStyle="btn_fill ms-4 join_button"
                      />
                    )}
                  </div>
                </div>
              </div>
            </form>
            <br />
          </div>
        )}
        {editEnable && (
          <div className="mr_15 mt_30">
            <form>
              <div className="row">
                <div className="col-md-12 col-sm-12 mb_10">
                  <InputField
                    label=""
                    value={orgName}
                    ref={orgNameRef}
                    type="text"
                    disabled
                    placeholder="*Organization Name"
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                    ]}
                    onChange={changeOrgName}
                    styleClass="mxw_100 br_10 mh_50"
                  />
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-12 col-sm-12 mb_10">
                  <InputField
                    label=""
                    value={streetAdd}
                    ref={streetAddRef}
                    disabled
                    type="text"
                    placeholder="*Street Address"
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                    ]}
                    onChange={changeStreetAdd}
                    styleClass="mxw_100 br_10 mh_50"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 mb_10">
                  <InputField
                    label=""
                    value={city}
                    disabled
                    ref={cityRef}
                    type="text"
                    placeholder="*City/Subrub"
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                    ]}
                    onChange={changeCity}
                    styleClass="mxw_100 br_10 mh_50"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <InputField
                    label=""
                    value={stateValue}
                    disabled
                    ref={stateValueRef}
                    type="text"
                    placeholder="*State"
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                    ]}
                    onChange={changeStateValue}
                    styleClass="mxw_100 br_10 mh_50"
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <InputField
                    label=""
                    value={postCode}
                    disabled
                    ref={postCodeRef}
                    type="text"
                    placeholder="*Post Code"
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                      {
                        check: Validators.spacial,
                        message: "Invalid character",
                      },
                      {
                        check: Validators.postcode,
                        message: "Fields only contain 10 character",
                      },
                    ]}
                    onChange={changePostCode}
                    styleClass="mxw_100 br_10 mh_50"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-sm-12 mb_10">
                  <InputField
                    label=""
                    value={phone}
                    ref={phoneRef}
                    disabled
                    type="text"
                    placeholder="*Phone"
                    validators={[
                      {
                        check: Validators.required,
                        message: "This field is required",
                      },
                      {
                        check: Validators.number,
                        message: "This field can only contain numbers.",
                      },
                    ]}
                    onChange={changePhone}
                    styleClass="mxw_100 br_10 mh_50"
                  />
                </div>
              </div> */}
              <div className="row">
                <div className="col-12">
                  <div className="mb_15">
                    <label>{"Speciality *"} </label>

                    <div className="row">
                      {enable === true ? (
                        <>
                          {options.map((option) => {
                            return option.active ? (
                              <div className="col-6">
                                <div
                                  className="form-group custom_check_box disables "
                                  style={{ position: "relative" }}
                                >
                                  <input
                                    className="me-2"
                                    type="checkbox"
                                    value={option.value}
                                    checked={checkedItems[option.value]}
                                    defaultChecked={option.locked}
                                    disabled={true}
                                    onChange={handleChange1}
                                    id={option.id}
                                  />
                                  <label
                                    key={option.id}
                                    className="me-4"
                                    for={option.id}
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              </div>
                            ) : (
                              <div className="col-6">
                                <div
                                  className={`form-group custom_check_box ${
                                    option.active ? "disables" : "tick"
                                  } text-black`}
                                >
                                  <input
                                    className="me-2"
                                    type="checkbox"
                                    value={option.value}
                                    checked={checkedItems[option.value]}
                                    defaultChecked={option.locked}
                                    disabled={
                                      blockID === 1 ? option.active : ""
                                    }
                                    onChange={handleChange1}
                                    id={option.id}
                                  />
                                  <label
                                    key={option.id}
                                    className="me-4"
                                    id={option.id}
                                    for={option.id}
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                          {errorMessage && (
                            <p style={{ color: "red" }}>{errorMessage}</p>
                          )}
                        </>
                      ) : (
                        "loading..."
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-md-12 col-sm-12 mb_10">
                  <FileUpload fileData={fileData} error1={error1} />
                </div> */}
                <div className="">
                  <div className="col-sm-12 col-md-12">
                    <Button
                      onClick={() => closeAllTab()}
                      value="Cancel"
                      buttonStyle="btn_fill"
                    />
                    <Button
                      onClick={handleSubmitEdit}
                      value="Update"
                      buttonStyle="btn_fill ms-4"
                    />
                  </div>
                </div>
              </div>
            </form>
            <br />
          </div>
        )}
        <div className="mr_15 mt_30">
          {uploadCheck && (
            <>
              {uploadData.map((row) => (
                <div>
                  <FileUpload
                    auto_id={uploadDataAutoId}
                    id={row.type}
                    name={row.name}
                  />
                </div>
              ))}
              {uploadData.length === 0 && (
                <div className="">
                  <p>
                    Below are the already approved speciality for this
                    organization. You have not chosen other specialty to
                    approve.
                  </p>
                  <hr></hr>
                  {approved.map((item) => (
                    <p>
                      {" "}
                      <label style={{ margin: 0 }}>{item}</label>
                    </p>
                  ))}
                </div>
              )}

              <div className="row">
                <div className="p_25">
                  <div className="row text-center">
                    <div className="col-sm-12 col-md-12">
                      <Button
                        onClick={() => closeAllTab()}
                        value="Close"
                        buttonStyle=" btn_outline  mr_20"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="mt_30 org_tab">
          {showOrganization === true ||
            (editEnable === false && !uploadCheck && (
              <>
                <Tabs
                  id="case-list-tabs"
                  activeKey={key}
                  onSelect={handleSelect}
                  // onSelect={(k) => setKey(k)}
                  className="border-0"
                >
                  <Tab eventKey="allcases" title="Organization">
                    {!show1 ? (
                      <span>
                        <LoadingSpinner />{" "}
                      </span>
                    ) : items.length > 0 ? (
                      <OrgListTableOrg
                        data={items}
                        updatePagination={fetchMoreData}
                        DataIdget={DataIdget}
                      />
                    ) : (
                      <div className="no_data">{translation.No_data_found}</div>
                    )}
                  </Tab>
                  <Tab eventKey="submitted" title="Speciality">
                    {!show1 ? (
                      <span>
                        {" "}
                        <LoadingSpinner />
                      </span>
                    ) : (
                      keyID === 2 &&
                      (items.length > 0 ? (
                        <OrgListTable
                          data={items}
                          updatePagination={fetchMoreData}
                          DataIdget={DataIdget}
                        />
                      ) : (
                        <div className="no_data">
                          {translation.No_data_found}
                        </div>
                      ))
                    )}
                  </Tab>
                </Tabs>
              </>
            ))}
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleCloseShow}
        dialogClassName="modal-lg patient_notes_popup "
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">{"Upload Files"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <FileUpload hideModel={setShow} />
        </Modal.Body>
        {/* <Modal.Footer style={{ justifyContent: "flex-start" }}>
          <Button
            disabled={""}
            onClick={""}
            value="Continue"
            buttonStyle="btn_fill mr_20 "
          />
        </Modal.Footer> */}
      </Modal>
    </div>
  );
}

export default MyClinics;
